/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { TableLoading } from "components/standart";

import { limit } from "variables";

import { apiUrls } from "../init";
// import AddLogin from "./AddLogin";
import LoginPassword from "./LoginPassword";
const { api } = adminAPI.actions;

const LoginsTable = (props) => {
  const params = useParams();

  const [userLogins, setUserLogins] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setUserLogins(body);
    };
    const onRequest = () => {
      setUserLogins({ total: -1 });
    };

    query.limit && props.getLogins({ params }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Logins.value" />
          </th>
          <th>
            <IntlMessages id="Logins.type" />
          </th>
          <th className="text-right"></th>
        </tr>
      </thead>
      {userLogins.total < 0 && <TableLoading />}
      <tbody>
        {_.map(userLogins.data, (login, key) => {
          return (
            <tr key={key} className={!login.enabled ? "text-strike" : ""}>
              <td>{login.value}</td>
              <td>
                <IntlMessages id={`Logins.type.${login.type}`} />
              </td>
              <td className="text-right">
                {login.type === "plain" ? <LoginPassword login={login} /> : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default connect(null, {
  getLogins: api.get(apiUrls.logins),
})(LoginsTable);

import { path as parentPath } from "../init";

export const path = "completions";

export const apiUrls = {
  list: "/api/openai/completions",
  positions: "/api/openai/completions/positions",
  info: "/api/openai/completions/completion_{completionId}",
};

export const infoLink = (completion) => {
  return `${parentPath}/${path}/completion_${completion._id}`;
};

export const formLink = (completion) => {
  return `${infoLink(completion)}/form`;
};

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";

import { Uploader, AudioPreview, Text2Mp3Button } from "components/standart";
import { setOnChange } from "components/special";

const IntroData = (props) => {
  const { content, setContent } = props;
  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="text" />
        </label>
        <div className="col-7">
          <input
            type="textarea"
            rows={3}
            name="text"
            value={content.text || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
        <div className="col-1">
          <Text2Mp3Button
            value={content.text}
            onSuccess={(file) => setContent({ ...content, fileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="file" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setContent({ ...content, fileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col-6">
          {content.fileId ? (
            <AudioPreview
              file={{ _id: content.fileId }}
              deleteHandler={() => {
                setContent({ ...content, fileId: null });
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  //
});

export default connect(mapStateToProps, {})(IntroData);

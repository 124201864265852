import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import Layout from "layouts/DashboardLayout.jsx";
import { adminAPI } from "redux/api";

import { Dashboard } from "./dashboardRoutes";

import DashboardStats from "views/admin/Dashboard";
import UserSettings from "views/admin/Settings";
import Users from "views/admin/Users";
// import Partners from "views/admin/Items";
// import Funds from "views/admin/Funds";
import Contents from "views/admin/Contents";
import Www from "views/admin/Www";
// import Orders from "views/admin/Orders";
import Payments from "views/admin/Payments";
import Sounds from "views/admin/Sounds";
import Channels from "views/admin/Channels";
import Advertisers from "views/admin/Advertisers";
import Listeners from "views/admin/Listeners";
import OpenAI from "views/admin/OpenAI";
import Specials from "views/admin/Specials";
/*
import Products from "views/admin/Products";
import Catalogs from "views/admin/Catalogs";
import Customers from "views/admin/Customers";
import Supplies from "views/admin/Supplies";
import Access from "views/admin/Access";
import Feedbacks from "views/admin/Feedbacks";
*/

const applicationRoutes = [
  Users,
  Channels,
  Contents,
  Www,
  Advertisers,
  Payments,
  Listeners,
  Specials,
  Sounds,
  OpenAI,
  { path: "/settings", component: UserSettings },
  { path: "/dashboard", component: DashboardStats },

  /*
  Supplies,
  Orders,
  Products,
  Feedbacks,
  Access,
  Catalogs,
  Customers,
  Partners,
  */
];

export default applicationRoutes;

const { env } = adminAPI.actions;

export const Admin = (props) => {
  const dispatch = useDispatch();
  // список доступных окружений, данные из которых используются для построения визуалов

  const envList = _.keys(env.actions);

  const [envReady, setEnvReady] = useState(false);
  const [envSuccess, setEnvSuccess] = useState([]);

  useEffect(() => {
    setEnvReady(envSuccess.length === envList.length);
  }, [envSuccess]);

  useEffect(() => {
    envList.map((type) => {
      dispatch(
        env.actions[type](
          {},
          {
            onSuccess({ body }) {
              setEnvSuccess(envSuccess.concat([body]));
            },
          }
        )
      );
    });
  }, []);

  return (
    envReady && (
      <>
        <Route path="/" component={Layout} />
        <Dashboard {...props} />
      </>
    )
  );
};

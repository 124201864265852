import _ from "lodash";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";
import { Uploader, ImagePreview } from "components/standart";
import { setOnChange } from "../index";

const SubscribeData = (props) => {
  const { attrName, data, setData } = props;
  const [contacts, setContacts] = useState({ ...data[attrName] });

  useEffect(() => {
    setData({ ...data, [attrName]: contacts });
  }, [contacts]);

  const changeContacts = setOnChange(contacts, setContacts);
  const height = 100;
  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="subscribeData.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`title`}
            value={_.toString(contacts.title)}
            onChange={changeContacts}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`subscribeData.content`} />
        </label>
        <div className="col">
          <textarea name="content" rows={3} onChange={changeContacts} className="form-control">
            {contacts.content || ""}
          </textarea>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="subscribeData.button" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`button`}
            value={_.toString(contacts.button)}
            onChange={changeContacts}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4 col-form-label">
          <IntlMessages id={`subscribeData.image`} />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setContacts({ ...contacts, fileId: file._id })}
            imagesOnly="true"
            multiple={false}
          />
        </div>
        <div className="col">
          {contacts.fileId && (
            <ImagePreview
              file={{ _id: contacts.fileId }}
              deleteHandler={() => {
                setContacts({ ...contacts, fileId: null });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SubscribeData;

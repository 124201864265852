import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import Select from "react-select";

export const WeekdayArrayValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  const indexes = [1, 2, 3, 4, 5, 6, 0];
  const labels = [
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
    "Воскресенье",
  ];
  const options = indexes.map((value, index) => ({ value, label: labels[index] }));

  return (
    <div>
      {_.map(values, (value, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <Select
                isMulti={false}
                options={options}
                value={
                  _.isNumber(value)
                    ? {
                        value,
                        label: labels[indexes.indexOf(value)],
                      }
                    : null
                }
                onChange={(selectedOptions) => {
                  values[index] = +selectedOptions.value;
                  changeValues(values);
                }}
              />
            </div>

            <div className="col text-right">
              <WeekdayArrayValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

WeekdayArrayValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([""]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

WeekdayArrayValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default WeekdayArrayValues;

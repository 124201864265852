import _ from "lodash";
import React, { useState, useEffect } from "react";
import { setOnChange } from "../index";
import IntlMessages from "util/intlMessages";
import { Uploader, ImagePreview, AudioPreview, Text2Mp3Button } from "components/standart";

const SoundMessage = (props) => {
  const { attrName, data, setData } = props;
  const [message, setMessage] = useState({ ...data[attrName] });

  useEffect(() => {
    setData({ ...data, [attrName]: message });
  }, [message]);

  const changeMessage = setOnChange(message, setMessage);

  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="soundMessage.button" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`button`}
            value={_.toString(message.button)}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="soundMessage.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`title`}
            value={_.toString(message.title)}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`soundMessage.content`} />
        </label>
        <div className="col">
          <textarea
            rows={5}
            name={`content`}
            className="form-control react-form-input"
            onChange={changeMessage}
          >
            {message.content || ""}
          </textarea>
        </div>
        <div className="col-1">
          <Text2Mp3Button
            value={message?.content}
            onSuccess={(file) => setMessage({ ...message, fileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="soundMessage.mp3" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setMessage({ ...message, fileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col-8">
          {message.fileId && (
            <AudioPreview
              file={{ _id: message.fileId }}
              deleteHandler={() => {
                setMessage({ ...message, fileId: null });
              }}
            />
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="soundMessage.image" />

          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setMessage({ ...message, imageId: file._id })}
            imagesOnly="true"
            multiple={false}
          />
        </label>
        <div className="col-8">
          {message.imageId && (
            <ImagePreview
              file={{ _id: message.imageId }}
              deleteHandler={() => {
                setMessage({ ...message, imageId: null });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SoundMessage;

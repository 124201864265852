/** управление пользователями
 */
import _ from "lodash";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages/index";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";
import IntlMessages from "util/intlMessages";
import { path } from "./init";
import PartnersTable from "./Table";
import PartnerInfo from "./Info";
import AddPartner from "./Add";

const Partners = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Partners" />,
    url,
    actions: [<AddPartner />],
  });

  return (
    <div>
      {/* <h1>partners</h1> */}

      <Switch>
        <Route exact path={`${path}`}>
          <PageTitle {...breadCrumbs} />

          <PartnersTable />
        </Route>

        <Route path={`${path}/partner_:partnerId`}>
          <PartnerInfo {...{ breadCrumbs }} />
        </Route>

        <Route component={Error400} />
      </Switch>
    </div>
  );
};

Partners.path = path;

// export default { path, component: Partners };
export default Partners;

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsBlockValues from "components/stats/AttrsBlockValues";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const BlocksSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <AttrsBlockValues queryHandler={api.get("/users/sessions_count")} />
      <AttrsBlockValues
        queryHandler={api.get("/messages")}
        attrsOnly={["sessionsCount", "statsCount"]}
        query={{ minDateTime: moment().subtract(1, "month").format() }}
      />

      <AttrsBlockValues queryHandler={api.get("/channels/total")} attrsOnly={["value"]} />
      <AttrsBlockValues queryHandler={api.get("/users/registered_count")} />

      {/* <StatsComponent> */}
      <AttrsBlockValues
        queryHandler={api.get("/messages/activity/percent")}
        query={{ minDateTime: moment().subtract(1, "month").format() }}
      />

      <AttrsBlockValues
        queryHandler={api.get("/messages/avg")}
        attrsOnly={["ERR", "progressListening"]}
        query={{ minDateTime: moment().subtract(1, "month").format() }}
      />
      
      <AttrsBlockValues
        queryHandler={api.get("/messages/avg/sessions")}
        query={{ minDateTime: moment().subtract(1, "month").format() }}
      />
      
      <AttrsBlockValues queryHandler={api.get("/channels/inFavorites")} attrsOnly={["value"]} />

      {/* 
      <AttrsBlockValues
        queryHandler={api.get("/adverts")}
        query={{ minDateTime: moment().subtract(1, "month") }}
      />
       */}

      {/* <AttrsBlockValues queryHandler={api.get("/users/premiums_count")} /> */}
      <AttrsBlockValues queryHandler={api.get("/chars")} attrsOnly={["charsCost", "lastCost"]} />
      <AttrsBlockValues queryHandler={api.get("/chars/avg")} attrsOnly={["charsCost"]} />
      {/* </StatsComponent> */}
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(BlocksSummaryStats);

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import qs from "query-string";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import IntlMessages from "util/intlMessages";
import SpecialsMessageData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const SpecialsMessageForm = (props) => {
  const params = useParams();
  const location = useLocation();
  const [message, setMessage] = useState(props.message);
  const [paramsValue, setParamsValue] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    const query = qs.parse(location.search);
    message && setParamsValue({ ...params, ...query, messageId: message._id });
  }, [message]);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.saveData({ ...req, params: { ...paramsValue } }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setMessage(props.message);
  }, [openModal]);

  const removeHandler = () => {
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить сообщение?");
    if (isConfirmed) {
      props.removeData({ params: { ...paramsValue } }, res);
    }
  };

  const saveMessage = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      setError(false);
    };
    saveHandler({ body: { ...params, ...message } }, { onFailure, onRequest });
  };

  return (
    <div className="text-nowrap">
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-edit" />
      </button>

      <button className="btn btn-sm btn-danger ml-1" onClick={removeHandler}>
        <i className="fa fa-times" />
      </button>
      {/* <GetMessagesButton {...channel} /> */}
      {/* <GetInfoButton {...channel} /> */}

      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"edit"} />
          </ModalHeader>
          <ModalBody>
            <SpecialsMessageData {...{ message, setMessage, error, setError }} />

            <button
              type="button"
              onClick={saveMessage}
              className="btn c-primary form-button"
            >
              <IntlMessages id={"save"} />
            </button>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  saveData: api.put(apiUrls.messageInfo),
  removeData: api.delete(apiUrls.messageInfo),
})(SpecialsMessageForm);

/** форма клиента
 */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import IntlMessages from "util/intlMessages";
import moment from "moment";
import { useIntl } from "react-intl";
import { adminAPI } from "redux/api";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;
const getLogins = api.get(apiUrls.logins);

const Info = (user) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [logins, setLogins] = useState({ data: [] });

  useEffect(() => {
    const params = { userId: user._id };
    dispatch(
      getLogins(
        { params },
        {
          onSuccess(body) {
            setLogins(body);
          },
        }
      )
    );
  }, []);

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Users.Data" />}</div>
            <div className="roe-card-body">
              {_.map(logins.data, (login, key) => {
                return (
                  <div className="form-group row" key={key}>
                    <div className="col-4 ">
                      <IntlMessages id={`Logins.type.${login.type}`} />
                    </div>
                    <div className="col-8">{login.value}</div>
                  </div>
                );
              })}
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id={`tables.created`} />
                </div>
                <div className="col-8">{moment(user.createdAt).format(dateTimeFormat)}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Logins" />}</div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
          </div>
        </div>
            */}
      </div>
    </> ///
  );
};

export default connect(null, {})(Info);

/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatCountriesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Countries" />}
      {...props}
      head={(searchCountry, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Contents.name" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(country, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!country.enabled ? "text-strike" : ""}>
            {country.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatCountries = (props) => {
  return <FloatCountriesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/references/countries"),
})(FloatCountries);

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import UserData from "./UserData";
import { apiUrls, formLink } from "./init";


const { api } = adminAPI.actions;

const AddUser = (props) => {
  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState({});
  // const changeUser = setOnChange(newUser, setNewUser);

  const saveUserHandler = (req, res) => {
    res.onSuccess = (body) => {
      dispatch(push(formLink(body)));
    };
    props.addUser(req, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setNewUser({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-user-plus" onClick={toggleModal} />
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Users.Add"} />
          </ModalHeader>
          <ModalBody>
            <UserData user={newUser} setUser={setNewUser} saveUserHandler={saveUserHandler} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addUser: api.post(apiUrls.list),
})(AddUser);

import _ from "lodash";
import React from "react";
import { fileUrl } from "redux/api";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { useToggle } from "react-use";

const ImagePreview = (props) => {
  const { file = {} } = props;

  const previewImg = (
    <>
      <img
        alt={file.name}
        src={fileUrl(file._id, "preview")}
        height="80"
        {..._.omit(props, ["deleteHandler"])}
      />
      {file.name && <div>{_.truncate(file.name, { length: 12 })}</div>}
    </>
  );
  const [lightboxOpen, toggleLightbox] = useToggle(false);

  return file._id ? (
    <span
      id={file._id}
      className="d-inline-block"
      style={{ position: "relative" }}
      title={file.name}
    >
      {props.deleteHandler && (
        <button
          className="btn btn-sm btn-danger"
          type="button"
          style={{ position: "absolute", right: "-5px", top: "-5px" }}
          onClick={props.deleteHandler}
        >
          <i className="fa fa-times"></i>
        </button>
      )}

      {props.noPreview ? (
        <img alt={file.name} src={fileUrl(file._id)} style={{ maxWidth: "100%", ...props.style }} />
      ) : (
        <>
          {props.url ? (
            <Link to={props.url}>{previewImg}</Link>
          ) : (
            <>
              <span onClick={toggleLightbox}>
                {previewImg}
              </span>
              {lightboxOpen && (
                <Lightbox
                  mainSrc={fileUrl(file._id)}
                  onCloseRequest={toggleLightbox}
                  reactModalStyle={{ overlay: { zIndex: 1099 } }}
                />
              )}
            </>
          )}
        </>
      )}
    </span>
  ) : null;
};

export default ImagePreview;

export default  [
    {
        id    : 0,
        title : 'Project Setup and discussion',
        allDay: true,
        start : new Date(2019, 3, 0),
        end   : new Date(2019, 3, 1)
    },
    {
        id    : 1,
        title : 'Client Call',
        allDay: false,
        start : new Date(2019, 3, 7),
        end   : new Date(2019, 3, 10)
    },
    {
        id    : 2,
        title : 'Design Changes',
        allDay: false,
        start : new Date(2019, 8, 13, 0, 0, 0),
        end   : new Date(2019, 8, 20, 0, 0, 0)
    },
    {
        id    : 3,
        title : 'Bug Solving Meeting',
        allDay: false,
        start : new Date(2019, 10, 6, 0, 0, 0),
        end   : new Date(2019, 10, 13, 0, 0, 0)
    },
    {
        id    : 4,
        title : 'Conference Meeting',
        allDay: false,
        start : new Date(2019, 3, 9, 0, 0, 0),
        end   : new Date(2019, 3, 9, 0, 0, 0),
        desc  : 'Big conference for important people'
    },
    {
        id    : 5,
        title : 'User Interface Updating',
        allDay: false,
        start : new Date(2019, 3, 11),
        end   : new Date(2019, 3, 13),
    },
    {
        id    : 6,
        title : 'Face to Face interview',
        allDay: false,
        start : new Date(2019, 3, 12, 10, 30, 0, 0),
        end   : new Date(2019, 3, 12, 12, 30, 0, 0),
        desc  : 'Pre-meeting meeting, to prepare for the interview'
    },
    {
        id    : 7,
        title : 'Lunch',
        allDay: false,
        start : new Date(2019, 3, 12, 12, 0, 0, 0),
        end   : new Date(2019, 3, 12, 13, 0, 0, 0),
        desc  : 'Power lunch'
    },
    {
        id    : 8,
        title : 'Meeting',
        allDay: false,
        start : new Date(2019, 3, 12, 14, 0, 0, 0),
        end   : new Date(2019, 3, 12, 15, 0, 0, 0)
    },
    {
        id    : 9,
        title : 'Happy Hour',
        allDay: false,
        start : new Date(2019, 3, 12, 17, 0, 0, 0),
        end   : new Date(2019, 3, 12, 17, 30, 0, 0),
        desc  : 'Most important meal of the day'
    },
    {
        id    : 10,
        title : 'Dinner Party',
        allDay: false,
        start : new Date(2019, 3, 12, 20, 0, 0, 0),
        end   : new Date(2019, 3, 12, 21, 0, 0, 0)
    },
    {
        id    : 11,
        title : 'Birthday Party',
        allDay: false,
        start : new Date(2019, 3, 13, 7, 0, 0),
        end   : new Date(2019, 3, 13, 10, 30, 0)
    },
    {
        id    : 12,
        title : 'Late Night Party',
        allDay: false,
        start : new Date(2019, 3, 17, 19, 30, 0),
        end   : new Date(2019, 3, 18, 2, 0, 0)
    },
    {
        id    : 13,
        title : 'Multi-day Event',
        allDay: false,
        start : new Date(2019, 3, 20, 19, 30, 0),
        end   : new Date(2019, 3, 22, 2, 0, 0)
    },
    {
        id    : 14,
        title : 'Project Setup and discussion',
        allDay: true,
        start : new Date(2019, 6, 0),
        end   : new Date(2019, 6, 1)
    },
    {
        id    : 15,
        title : 'Client Call',
        allDay: false,
        start : new Date(2019, 6, 7),
        end   : new Date(2019, 6, 10)
    },
    {
        id    : 16,
        title : 'Design Changes',
        allDay: false,
        start : new Date(2019, 2, 13, 0, 0, 0),
        end   : new Date(2019, 2, 20, 0, 0, 0)
    },
    {
        id    : 17,
        title : 'Parents Metting',
        allDay: false,
        start : new Date(2019, 10, 6, 0, 0, 0),
        end   : new Date(2019, 10, 13, 0, 0, 0)
    },
    {
        id    : 18,
        title : 'Team Metting',
        allDay: false,
        start : new Date(2019, 6, 9, 0, 0, 0),
        end   : new Date(2019, 6, 9, 0, 0, 0),
        desc  : 'Big conference for important people'
    },
    {
        id    : 19,
        title : 'API Changes',
        allDay: false,
        start : new Date(2019, 6, 11),
        end   : new Date(2019, 6, 13),
    },
    {
        id    : 20,
        title : 'Project Scope Discussion',
        allDay: false,
        start : new Date(2019, 3, 12, 10, 30, 0, 0),
        end   : new Date(2019, 3, 12, 12, 30, 0, 0),
        desc  : 'Pre-meeting meeting, to prepare for the Project Scope'
    },
    {
        id    : 21,
        title : 'Client Discussion',
        allDay: false,
        start : new Date(2019, 5, 12, 12, 0, 0, 0),
        end   : new Date(2019, 5, 12, 13, 0, 0, 0),
        desc  : 'Power lunch'
    },
    {
        id    : 22,
        title : 'Developing new feature',
        allDay: false,
        start : new Date(2019, 4, 12, 14, 0, 0, 0),
        end   : new Date(2019, 4, 12, 15, 0, 0, 0)
    },
    {
        id    : 23,
        title : 'Aniversary Celebration',
        allDay: false,
        start : new Date(2019, 2, 12, 17, 0, 0, 0),
        end   : new Date(2019, 2, 12, 17, 30, 0, 0),
        desc  : 'Most important meal of the day'
    },
    {
        id    : 24,
        title : 'Celebration',
        allDay: false,
        start : new Date(2019, 1, 12, 20, 0, 0, 0),
        end   : new Date(2019, 1, 12, 21, 0, 0, 0)
    },
    {
        id    : 25,
        title : 'Project Management',
        allDay: false,
        start : new Date(2019, 5, 13, 7, 0, 0),
        end   : new Date(2019, 5, 13, 10, 30, 0)
    },
    {
        id    : 26,
        title : 'Design Customization',
        allDay: false,
        start : new Date(2019, 4, 17, 19, 30, 0),
        end   : new Date(2019, 4, 18, 2, 0, 0)
    },
    {
        id    : 27,
        title : 'QA',
        allDay: false,
        start : new Date(2019, 4, 20, 19, 30, 0),
        end   : new Date(2019, 4, 22, 2, 0, 0)
    }
]
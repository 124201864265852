/** управление каналами
 */
import _ from "lodash";
import React from "react";

import AdvertsTable from "./Table";

const AdvertiserAdverts = (props) => {
  return (
    <div>
      <AdvertsTable />
    </div>
  );
};

export default AdvertiserAdverts;

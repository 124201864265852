import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { setOnChange } from "components/special";
import { FloatAccounts } from "components/special/contents";

import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const AddChannel = (props) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState({});
  const changeChannel = setOnChange(body, setBody);
  const [error, setError] = useState(false);

  const saveHandler = (req, res) => {
    const onSuccess = (body) => {
      dispatch(push(formLink(body)));
    };
    const onFailure = ({ message }) => {
      setError(message);
    };
    props.addChannel({ body }, { onSuccess, onFailure });
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setBody({});
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm -btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Channels.Add"} />
          </ModalHeader>
          <ModalBody>
            {error && (
              <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                <IntlMessages id={error} />
              </Alert>
            )}
            <div className="form-group">
              <IntlMessages id="Channels.alias">
                {(title) => (
                  <input
                    type="text"
                    value={body.alias || ""}
                    name="alias"
                    onChange={changeChannel}
                    required
                    className="form-control"
                    placeholder={title}
                    autoComplete="off"
                  />
                )}
              </IntlMessages>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="Telegram.Accounts" />
              </label>
              <div className="col-8 my-auto">
                <FloatAccounts
                  _id={body.accountId}
                  showTitle={true}
                  showCount={false}
                  multi={false}
                  successHandler={([accountId]) => {
                    setBody({ ...body, accountId });
                  }}
                />
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={!(body.alias && body.accountId)}
                onClick={saveHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
            {/* <ChannelData {...{ channel, setChannel, saveHandler }} /> */}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addChannel: api.post(apiUrls.fastAdd),
})(AddChannel);

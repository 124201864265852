/** управление данными для сайта
 */
import _ from "lodash";
import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import Transactions from "./Transactions";
import Receipts from "./Receipts";
import ReceiptsServices from "./ReceiptsServices";
import PaymentsServices from "./PaymentsServices";
import PaymentsCurrencies from "./PaymentsCurrencies";
import PaymentsSettings from "./Settings";

import { path } from "./init";
import { BreadCrumbs } from "components/standart/PageTitle";

const Payments = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Payments" />,
    url,
  });

  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/receipts`}>
          <Receipts {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/services`}>
          <PaymentsServices {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/currencies`}>
          <PaymentsCurrencies {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/settings`}>
          <PaymentsSettings {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/receipts_services`}>
          <ReceiptsServices {...{ breadCrumbs }} />
        </Route>

        {/* 
        <Route path={`${path}/withdraws`}>
          <Withdraws {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/donations`}>
          <Donations {...{ breadCrumbs }} />
        </Route>
         */}

        <Route path={`${path}/${Transactions.path}`}>
          <Transactions {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${path}/${Transactions.path}`} />} />
        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Payments };

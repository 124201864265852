import { LoadingIcon } from "components/special";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { filesAPI, setSuccess, fileUrl } from "redux/api";
import IntlMessages from "util/intlMessages";

const { api } = filesAPI.actions;

const FileIDInfo = (props) => {
  const { fileId } = props;
  const [fileInfo, setFileInfo] = useState({});

  useEffect(() => {
    const params = { fileId };

    props.getFileInfo({ params }, setSuccess(setFileInfo));
  }, [fileId]);

  return fileInfo._id ? (
    <div className={`${props.className}`}>
      <a href={fileUrl(fileId, "download")} title="Скачать" className="btn-sm btn">
        {fileInfo.name}
        <i className="fa fa-download ml-2" />
      </a>
    </div>
  ) : null;
};

export default connect(null, {
  getFileInfo: api.get("/file_{fileId}"),
})(FileIDInfo);

import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import IntlMessages from "util/intlMessages";
import Select from "react-select";

const GendersSelector = (props) => {
  const { genders } = props.envStore;
  const { name = "gender", setValue } = props;

  const options = () =>
    _.map(genders, (value) => {
      return { value, label: <IntlMessages id={`Genders.${value}`} /> };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    const target = { name, value };
    setValue({ target });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      isDisabled={true}
      value={
        props.value ? { value: props.value, label: <IntlMessages id={`Genders.${props.value}`} /> } : null
      }
      options={options()}
      placeholder={<IntlMessages id={"Genders"} />}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(GendersSelector);

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";

const AccountData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Telegram.Accounts.isBot" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={content.isBot} name="isBot" onChange={changeContent} />
        </div>
      </div>

      <>
        <div className="form-group row">
          <label className="col-4 col-form-label">
            <IntlMessages id="Telegram.Accounts.apiId" />
          </label>
          <div className="col-8">
            <input
              type="text"
              name="apiId"
              value={content.apiId || ""}
              onChange={changeContent}
              className="form-control react-form-input"
              autoComplete="off"
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-4 col-form-label">
            <IntlMessages id="Telegram.Accounts.apiHash" />
          </label>
          <div className="col-8">
            <input
              type="text"
              name="apiHash"
              value={content.apiHash || ""}
              onChange={changeContent}
              className="form-control react-form-input"
              autoComplete="off"
              required
            />
          </div>
        </div>
      </>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Telegram.Accounts.token" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="token"
            value={content.token || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="description" />
        </label>
        <div className="col-8">
          <textarea
            name="description"
            value={content.description || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(AccountData);

/** страница типа транспорта
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useRouteMatch, Route, Redirect, Switch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";

import { adminAPI } from "redux/api";
import AdvertiserForm, { FormButton } from "./Form";
import AdvertiserInfoData from "./Advertiser";
import { apiUrls } from "./init";

const { api } = adminAPI.actions;

const AdvertiserInfo = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();

  const { breadCrumbs } = props;
  const [advertiser, setAdvertiser] = useState(null);

  const [notFound, setNotFound] = useState(false);

  if (advertiser) {
    breadCrumbs.push({
      title: [advertiser.name].filter(_.size).join(" / "),
      url,
      actions: [<FormButton {...advertiser} />],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setAdvertiser(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getAdvertiser({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {advertiser && (
        <Switch>
          <Route exact path={`${path}/form`}>
            <AdvertiserForm {...{ advertiser, setAdvertiser, breadCrumbs }} />
          </Route>

          <Route path={path}>
            <PageTitle {...breadCrumbs} />
            <AdvertiserInfoData {...{ advertiser }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getAdvertiser: api.get(apiUrls.info),
})(AdvertiserInfo);

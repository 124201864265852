/** загрузчик файлов, который связан с фотографией профиля пользователя
 * в общем случае вызывается при создании нового пользователя, а также
 * при его собственном редактировании своего профиля
 *
 * функционал компоненты следующий
 * клик по кнопке upload вызывает поиск и отправку файла на сервер
 * который попадает в api/files/upload и возвращает типовую структуру файла
 * при получении данной структуры показывается модальное окно с картинкой и кроппером
 * подтверждение действие в котором отправляет запрос о применении координат
 * к файлу и возвращает информацию о фотографии, которая визуализируется как фото профиля
 *
 * используется хранилище информации, соответствующее общим настройкам
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Formik, Field, Form, useField } from "formik";
import { adminAPI, fileUrl, setSuccess } from "redux/api";
import { Modal, ModalBody, Tooltip } from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { LoadingIcon } from "components/special";

const { api, session } = adminAPI.actions;

const PhotoUploader = (props) => {
  const { upload, fileInfo, changePhotoHandler, deletePhotoHandler } = props;
  const initialValues = {};

  const { settings } = useSelector((state) => state.api);

  // const userPhoto = _.get(props.userSettings, "photo") || {};
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(false);

  useEffect(() => {
    setUploadedPhoto(null);
  }, []);

  const FormElement = (props) => {
    const { handleReset } = props;

    const submitHandler = (event) => {
      event.preventDefault();
    };

    const uploadHandler = (event) => {
      event.preventDefault();
      const body = new FormData();

      const onSuccess = (result) => {
        setUploadProgress(false);
        setUploadedPhoto(_.first(result));
      };
      const onRequest = (result) => {
        setUploadProgress(true);
      };

      _.map(event.target.files, (file) => {
        body.append("file", file);
      });
      upload({ body }, { onSuccess, onRequest });
    };

    const editPhotoHandler = () => {
      const params = { file_id: settings.fileId };
      fileInfo({ params }, setSuccess(setUploadedPhoto));
    };

    const removePhotoHandler = async (event) => {
      setUploadedPhoto(null);
      deletePhotoHandler({});
    };

    const ProfilePhoto = ({ file_id }) => {
      return <img style={{ maxWidth: "100%" }} src={fileUrl(file_id)} />;
    };

    const buttonStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      fontSize: "200px",
      opacity: 0,
      direction: "ltr",
    };

    const PhotoEditor = (props) => {
      const [croppedCanvas, setCroppedCanvas] = useState(null);
      const cropPhoto = (event) => {
        setCroppedCanvas(event.target.cropper.getCroppedCanvas());
      };

      const saveCropPhoto = async () => {
        const onSuccess = async (result) => {
          setUploadProgress(false);
          const croppedFile = _.first(result);
          const body = {
            fileId: uploadedPhoto._id,
            croppedFileId: croppedFile._id,
          };

          changePhotoHandler({ body });
        };

        const onRequest = (result) => {
          setUploadProgress(true);
        };

        const onFailure = () => {
          setUploadProgress(false);
        };

        croppedCanvas.toBlob((blob) => {
          const body = new FormData();
          body.append("file", blob, `cropped_${uploadedPhoto.name}`);
          upload({ body }, { onSuccess, onRequest, onFailure });
        });

        setUploadProgress(false);
        setUploadedPhoto(null);
      };

      const cancelCrop = (event) => {
        setUploadedPhoto(null);
      };

      return (
        <Modal centered isOpen={!!uploadedPhoto} className="modal-lg">
          <ModalBody>
            <div className="row p-1">
              <div className="col-12 col-md-8">
                {uploadedPhoto && (
                  <Cropper
                    src={fileUrl(uploadedPhoto._id)}
                    aspectRatio={1}
                    style={{ height: 400, width: "100%" }}
                    crop={cropPhoto}
                  />
                )}
              </div>
              <div className="col-12 col-md-4">
                {croppedCanvas && (
                  <img style={{ maxWidth: "100%" }} src={croppedCanvas.toDataURL()} />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <button type="button" className="btn c-primary" onClick={saveCropPhoto}>
                  <IntlMessages id="save" />
                </button>
              </div>

              <div className="col-6 text-right">
                <button type="button" className="btn btn-secondary" onClick={cancelCrop}>
                  <IntlMessages id="cancel" />
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      );
    };

    return (
      <Form className="pa-24" onSubmit={submitHandler}>
        <div className="text-center p-2">
          {uploadProgress ? (
            <LoadingIcon />
          ) : (
            <>
              {settings.croppedFileId ? (
                <ProfilePhoto file_id={settings.croppedFileId} />
              ) : (
                <span></span>
              )}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <span className="btn c-primary" style={{ position: "relative", overflow: "hidden" }}>
              <input
                type="file"
                name="file"
                onChange={uploadHandler}
                accept=".jpg,.png,.jpeg"
                multiple={false}
                style={buttonStyle}
              />
              <IntlMessages id="settings.photo.upload" />
            </span>
          </div>
          {settings.croppedFileId && (
            <>
              <div className="col-4">
                <button type="button" className="btn btn-secondary" onClick={editPhotoHandler}>
                  <IntlMessages id="edit" />
                </button>
              </div>

              <div className="col-2">
                <button type="button" className="btn btn-danger" onClick={removePhotoHandler}>
                  <i className="fa fa-times" />
                </button>
              </div>
            </>
          )}
        </div>

        {uploadedPhoto && <PhotoEditor />}
      </Form>
    );
  };

  return <Formik enableReinitialize={true} initialValues={initialValues} component={FormElement} />;
};

export default connect(null, {
  upload: api.upload("/api/files"),
  fileInfo: api.get("/api/files/file_{file_id}"),
  ...session.actions,
})(PhotoUploader);

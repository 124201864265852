import apiClass from "./init";

const env = new apiClass();

// окружение для поставок
env.actions = {
  index: env.get("/env", {
    success(state, body) {
      return { ...state, ...body };
    },
  }),
  /*
	transports: env.auto("Catalogs.Transports.Env", {
		success(state, { body }) {
			return { ...state, transports: body };
		},
	}),
	addresses: env.auto("Addresses.Env", {
		success(state, { body }) {
			return { ...state, addresses: body };
		},
	}),
	supplies: env.auto("Supplies.Env", {
		success(state, { body }) {
			return { ...state, supplies: body };
		},
	}),
	sizes: env.auto("Catalogs.Sizes.Env", {
		success(state, { body }) {
			return { ...state, sizes: body };
		},
	}),
	products: env.auto("Products.Env", {
		success(state, { body }) {
			return { ...state, products: body };
		},
	}),
	orders: env.auto("Orders.Env", {
		success(state, { body }) {
			return { ...state, orders: body };
		},
	}),
	payments: env.auto("Payments.Env", {
		success(state, { body }) {
			return { ...state, payments: body };
		},
	}),
	receipts: env.auto("Payments.Receipts.Env", {
		success(state, { body }) {
			return { ...state, receipts: body };
		},
	}),
	categories: env.auto("Catalogs.Categories.Env", {
		success(state, { body }) {
			return { ...state, categories: body };
		},
	}),
	brands: env.auto("Catalogs.Brands.Env", {
		success(state, { body }) {
			return { ...state, brands: body };
		},
	}),
	customers: env.auto("Customers.Env", {
		success(state, { body }) {
			return { ...state, customers: body };
		},
	}),
	partners: env.auto("Catalogs.Partners.Env", {
		success(state, { body }) {
			return { ...state, partners: body };
		},
	}),
	promo: env.auto("Catalogs.PromoPartners.Env", {
		success(state, { body }) {
			return { ...state, promo: body };
		},
	}),
	sales: env.auto("Catalogs.Sales.Env", {
		success(state, { body }) {
			return { ...state, sales: body };
		},
	}),
	*/
};

export default env;

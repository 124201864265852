import _ from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import IntlMessages from "util/intlMessages";

import AttrsValuesContainer from "./AttrsValuesContainer";

const AttrsBlockValues = (props) => {
  return (
    <AttrsValuesContainer {...props}>
      {({ datasets }) => {
        return (
          <>
            {_.map(datasets, (dataset, index) => {
              if (_.size(props.attrsOnly) && !_.includes(props.attrsOnly, dataset.dataKey)) {
                return null;
              }
              const color = dataset.color; // colors[index % colors.length];
              return (
                <div className="card col-md-3 col-12 roe-shadow-2 fill-height m-2" key={index}>
                  <div className="card-body">
                    <div className="h4 card-title text-nowrap">
                      {dataset.label}
                      {/* <IntlMessages id={dataset.label} /> */}
                    </div>

                    {_.map(dataset.dataPoints, (data, index) => {
                      return data ? (
                        <div className="h4 fs-20 font-weight-bold" key={index}>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={" "}
                            value={data.value}
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </>
        );
      }}
    </AttrsValuesContainer>
  );
};

export default AttrsBlockValues;

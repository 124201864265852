import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FloatCategories } from "components/special/contents";

export const AssistantTimerRuns = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <div className="row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-6 col-form-label">
                      {intl.formatMessage({ id: "OpenAI.Assistants.TimerRuns.categories" })}
                    </label>
                    <div className="col-6">
                      <FloatCategories
                        _id={elem.categoriesId}
                        multi={true}
                        showTitle={false}
                        showCount={true}
                        className="btn-sm"
                        successHandler={(categoriesId) => {
                          Object.assign(values[index], { categoriesId });
                          changeValues(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <input
                    type="string"
                    name="runtime"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "OpenAI.Assistants.TimerRuns.runtime",
                    })}
                    value={elem.runtime || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col text-right">
              <AssistantTimerRuns.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

AssistantTimerRuns.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{}]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

AssistantTimerRuns.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default AssistantTimerRuns;

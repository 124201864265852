/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { RuntimeArrayValues, NumberArrayValues, WeekdayArrayValues } from "components/standart";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { adminAPI } from "redux/api";
import { SwitchValue } from "components/special/forms";

import { apiUrls, formLink } from "./init";
import ContentData from "./Data";
const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current && successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      {/* <PageTitle {...breadCrumbs} /> */}

      <div ref={successAlertRef}>
        <Alert className="alert-success" isOpen={dataSaved} toggle={() => setDataSaved(false)}>
          <IntlMessages id="saved" />
        </Alert>
      </div>
      <div className="row">
        <div className="col">
          <ContentData {...{ content, setContent }} />

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.currentDayMessagesOnly" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue name="currentDayMessagesOnly" value={content.currentDayMessagesOnly} onChange={changeContent} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.dailySum" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue name="dailySum" value={content.dailySum} onChange={changeContent} />
            </div>
          </div>

          {/* 
          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.CastsConcat" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue
                name="castsConcat"
                value={content.castsConcat}
                onChange={changeContent}
              />
            </div>
          </div>
          */}

          {content.castsConcat ? (
            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="OpenAI.Schedules.minScore" />
              </label>
              <div className="col-8">
                <input
                  type="number"
                  name="minScore"
                  value={content.minScore}
                  onChange={changeContent}
                  className="form-control react-form-input"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
          ) : null}

          <div className="form-group row">
            <div className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.runtime" />
              <RuntimeArrayValues.AddButton
                className="btn-sm float-right"
                values={content.runtimes}
                changeValues={(runtimes) => setContent({ ...content, runtimes })}
              />
            </div>
            <div className="col-8">
              <RuntimeArrayValues
                values={content.runtimes}
                changeValues={(runtimes) => setContent({ ...content, runtimes })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.everyDay" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue name="everyDay" value={content.everyDay} onChange={changeContent} />
            </div>
          </div>

          {!content.everyDay ? (
            <>
              <div className="form-group row">
                <div className="col-4 col-form-label">
                  <IntlMessages id="OpenAI.Schedules.specificWeekDays" />
                  <WeekdayArrayValues.AddButton
                    className="btn-sm float-right"
                    values={content.specificWeekDays}
                    changeValues={(specificWeekDays) =>
                      setContent({ ...content, specificWeekDays })
                    }
                  />
                </div>
                <div className="col-8">
                  <WeekdayArrayValues
                    values={content.specificWeekDays}
                    changeValues={(specificWeekDays) =>
                      setContent({ ...content, specificWeekDays })
                    }
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="OpenAI.Schedules.specificMonthDays" />
                  <NumberArrayValues.AddButton
                    className="btn-sm float-right"
                    values={content.specificMonthDays}
                    changeValues={(specificMonthDays) =>
                      setContent({ ...content, specificMonthDays })
                    }
                  />
                </label>
                <div className="col-8">
                  <NumberArrayValues
                    values={content.specificMonthDays}
                    changeValues={(specificMonthDays) =>
                      setContent({ ...content, specificMonthDays })
                    }
                  />
                </div>
              </div>
            </>
          ) : null}

          {/*
          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.defaultTitle" />
            </label>
            <div className="col-8">
              <input
                type="string"
                name="defaultTitle"
                value={content.defaultTitle || ""}
                onChange={changeContent}
                className="form-control react-form-input"
                autoComplete="off"
                required
              />
            </div>
          </div>
            */}

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.restartsLimit" />
            </label>
            <div className="col-8">
              <input
                type="number"
                name="restartsLimit"
                value={+content.restartsLimit || "1"}
                onChange={changeContent}
                className="form-control react-form-input"
                autoComplete="off"
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.publicationsMinCount" />
            </label>
            <div className="col-8">
              <input
                type="number"
                name="publicationsMinCount"
                value={content.publicationsMinCount}
                onChange={changeContent}
                className="form-control react-form-input"
                autoComplete="off"
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.imageGeneration" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue
                name="imageGeneration"
                value={content.imageGeneration}
                onChange={changeContent}
              />
            </div>
          </div>

          {content.imageGeneration ? (
            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="OpenAI.Schedules.imageGenerationInstructions" />
              </label>
              <div className="col">
                <textarea
                  name="imageGenerationInstructions"
                  rows="10"
                  value={content.imageGenerationInstructions || ""}
                  onChange={changeContent}
                  className="form-control react-form-input"
                  autoComplete="off"
                />
              </div>
            </div>
          ) : null}

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.autoPublish" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue
                value={content.autoPublish}
                name="autoPublish"
                onChange={changeContent}
              />
            </div>
          </div>

          {/* 

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Schedules.telegramPublish" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue
                value={content.telegramPublish}
                name="telegramPublish"
                onChange={changeContent}
              />
            </div>
          </div>

          {content.telegramPublish ? (
            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="OpenAI.Schedules.telegramCollapse" />
              </label>
              <div className="col-8 my-auto">
                <SwitchValue
                  value={content.telegramCollapse}
                  name="telegramCollapse"
                  onChange={changeContent}
                />
              </div>
            </div>
          ) : null}
           */}

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="tables.enabled" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue value={content.enabled} onChange={changeContent} />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-auto">
              <button className="form-control c-primary" type="button" onClick={saveHandler}>
                <IntlMessages id="save" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const startSchedule = api.post(apiUrls.start);
export const StartButton = (schedule) => {
  const dispatch = useDispatch();
  const [isRunned, setRunned] = useState(false);
  const clickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const confirmed = window.confirm("Запустить выполнение?");
    if (confirmed) {
      const params = { scheduleId: schedule._id };
      dispatch(
        startSchedule(
          { params },
          {
            onSuccess() {
              schedule.onSuccessHandler();
              setRunned(false);
            },
            onFailure() {
              setRunned(false);
            },
            onRequest() {
              setRunned(true);
            },
          }
        )
      );
    }
  };
  return (
    <button className="btn btn-info btn-sm ml-2" onClick={clickHandler}>
      <i className={`fa ${isRunned ? "fa-spinner-third fa-spin" : "fa-play"}`} />
    </button>
  );
};

export const FormButton = (props) => {
  return (
    <Link to={formLink(props)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.info),
})(ContentForm);

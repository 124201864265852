/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { StringArrayValues } from "components/standart";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { adminAPI } from "redux/api";
import { SwitchValue } from "components/special/forms";

import { apiUrls, formLink } from "./init";
import ContentData from "./Data";
const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current && successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      {/* <PageTitle {...breadCrumbs} /> */}

      <div ref={successAlertRef}>
        <Alert className="alert-success" isOpen={dataSaved} toggle={() => setDataSaved(false)}>
          <IntlMessages id="saved" />
        </Alert>
      </div>
      <div className="row">
        <div className="col">
          <ContentData {...{ content, setContent }} />

          <div className="form-group row">
            <span className="col-4 col-form-label">
              <IntlMessages id="OpenAI.Completions.attrs" />
              <StringArrayValues.AddButton
                className="btn-sm pull-right"
                values={content.attrs}
                changeValues={(attrs) => setContent({ ...content, attrs })}
              />
            </span>
            <div className="col-8">
              <StringArrayValues
                values={content.attrs}
                changeValues={(attrs) => setContent({ ...content, attrs })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="tables.enabled" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue value={content.enabled} onChange={changeContent} />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-auto">
              <button className="form-control c-primary" type="button" onClick={saveHandler}>
                <IntlMessages id="save" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  return (
    <Link to={formLink(props)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.info),
})(ContentForm);

import { friend1, friend2, friend4, friend3, friend5 } from "helper/constant";

export const taskData = [
  {
    profile: friend1,
    task: 'Sign contract for "What are conference organizers afraid of?"'
  },
  {
    profile: friend2,
    task: "Whats curious??"
  },
  {
    profile: friend3,
    task:
      "Programming today is a race between software engineers striving to build bigger"
  },
  {
    profile: friend4,
    task: "I was just trying to fish for alternative apps"
  },
  {
    profile: friend5,
    task: "creating the package with ADT?"
  }
];

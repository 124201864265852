import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";

import ContentTable from "./Table";
import ContentInfo from "./Info";
import { path } from "./init";

const GeneratedCasts = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="OpenAI.Casts" />,
    url,
    actions: [],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

GeneratedCasts.path = path;

export default GeneratedCasts;

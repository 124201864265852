import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import Select from "react-select";

const RewardsTypesSelector = (props) => {
  const { rewardsTypes } = props.envStore;
  const { name = "type", setValue } = props;
  const nameKey = "Rewards.type";
  const options = () =>
    _.map(rewardsTypes, (value) => {
      // return { value, label: <IntlMessages id={`${nameKey}.${value}`} /> };
      return { value, label: value };
    });

  const onChangeHandler = (selectedOptions) => {
    const { value } = selectedOptions;
    // const value = _.map(selectedOptions, ({ value }) => value);
    // const target = { name, value };
    console.log("selected value is", selectedOptions, value);
    setValue(value);
  };

  const defaultValues = () => {
    const values = _.split(props.value, ",");
    return _.filter(options(), (option) => _.indexOf(values, option.value) >= 0);
  };

  return (
    <Select
      isMulti={false}
      name={name}
      defaultValue={defaultValues()}
      /*
      value={
        props.value
          ? {
              value: props.value,
              label: <IntlMessages id={`${nameKey}.${props.value}`} />,
            }
          : null
      }
      */
      options={options()}
      placeholder={<IntlMessages id={`${nameKey}`} />}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(RewardsTypesSelector);

/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Uploader, VideoPreview, RTMPSDataValues } from "components/standart";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { adminAPI } from "redux/api";
import { SwitchValue } from "components/special/forms";

import { apiUrls, formLink } from "./init";
import ContentData from "./Data";
const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="CastsTranslations.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current && successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      {/* <PageTitle {...breadCrumbs} /> */}

      <div ref={successAlertRef}>
        <Alert className="alert-success" isOpen={dataSaved} toggle={() => setDataSaved(false)}>
          <IntlMessages id="saved" />
        </Alert>
      </div>
      <div className="row">
        <div className="col">
          <ContentData {...{ content, setContent }} />

          <div className="form-group row">
            <div className="col-4 col-form-label">
              <IntlMessages id="CastsTranslations.rtmps" />
              <RTMPSDataValues.AddButton
                className="btn-sm float-right"
                values={content.rtmps}
                changeValues={(rtmps) => setContent({ ...content, rtmps })}
              />
            </div>
            <div className="col-8">
              <RTMPSDataValues
                values={content.rtmps}
                changeValues={(rtmps) => setContent({ ...content, rtmps })}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-4 col-form-label">
              <IntlMessages id="CastsTranslations.videoFileId" />
              <Uploader
                className="btn-success btn-sm pull-right"
                successHandler={([file]) => setContent({ ...content, videoFileId: file._id })}
                multiple={false}
              />
            </div>
            <div className="col-8 my-auto">
              {content.videoFileId && (
                <VideoPreview
                  file={{ _id: content.videoFileId }}
                  deleteHandler={() => {
                    setContent({ ...content, videoFileId: null });
                  }}
                />
              )}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4 col-form-label">
              <IntlMessages id="tables.enabled" />
            </label>
            <div className="col-8 my-auto">
              <SwitchValue value={content.enabled} onChange={changeContent} />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-auto">
              <button className="form-control c-primary" type="button" onClick={saveHandler}>
                <IntlMessages id="save" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  return (
    <Link to={formLink(props)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

const reloadTranslation = api.post(apiUrls.reload);

export const ReloadButton = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const reloadHandler = () => {
    const confirm = window.confirm("Перезапустить трансляцию?");
    if (confirm) {
      setLoading(true);
      dispatch(
        reloadTranslation(
          {},
          {
            onSuccess() {
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };
  return (
    <span className="btn btn-sm" onClick={reloadHandler}>
      <i className={`fa fa-redo ${loading ? "fa-spin" : ""}`} />
    </span>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.info),
})(ContentForm);

/** модальное окно для выбора локализаций в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";
import FloatCategories from "./FloatCategories";

const { api } = adminAPI.actions;

const FloatPublishChannelsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="PublishChannels" />}
      {...props}
      head={(search, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="PublishChannels.name" />
            </th>
            <th>
              <IntlMessages id="PublishChannels.alias" />
            </th>
            
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(channel, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!channel.enabled ? "text-strike" : ""}>
            {channel.name}
          </td>

          <td onClick={clickHandler} className={!channel.enabled ? "text-strike" : ""}>
            {channel.alias}
          </td>

          <td></td>
        </>
      )}
    />
  );
};

const FloatPublishChannels = (props) => {
  return <FloatPublishChannelsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/references/publishChannels"),
})(FloatPublishChannels);

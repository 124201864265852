/** модальное окно для прав доступа */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal, onEnter, setOnChange, AccessControl } from "components/special";

const { api } = adminAPI.actions;

const ident = "Accesses";
const FloatAccess = (props) => {
  return (
    <AccessControl id={ident}>
      <FloatModal
        header={<IntlMessages id={ident} />}
        {...props}
        head={(searchAccesses, searchQuery, setSearchQuery) => {
          return (
            <>
              <th>
                <IntlMessages id="Accesses.title" />
              </th>
              <th style={{ width: "30px" }}></th>
            </>
          );
        }}
        row={(access, clickHandler) => {
          return (
            <>
              <td className={!access.enabled ? "text-strike" : ""} onClick={clickHandler}>
                {access.title}
              </td>
              <td></td>
            </>
          );
        }}
      />
    </AccessControl>
  );
};

export default connect(null, {
  getData: api.get("/api/access"),
})(FloatAccess);

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsPieChart from "components/stats/AttrsPieChart";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const PiesSummaryStats = (props) => {
  useEffect(() => {}, []);
  const params = useParams();

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/adverts/listeners")}
          title="Слушатели"
          showPercent={true}
          query={{ ...props.query, ...params }}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(PiesSummaryStats);

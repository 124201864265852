import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import IntlMessages from "util/intlMessages";
import AdvertData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../../init";

const { api } = adminAPI.actions;

const AddAdvert = (props) => {
  const params = useParams();

  const [advert, setAdvert] = useState({});
  // const changeUser = setOnChange(newUser, setNewUser);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.addAdvert({ ...req, params }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setAdvert({ enabled: false });
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Adverts.Add"} />
          </ModalHeader>
          <ModalBody>
            <AdvertData {...{ advert, setAdvert, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addAdvert: api.post(apiUrls.adverts),
})(AddAdvert);

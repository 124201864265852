import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import Select from "react-select";
import { SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";
import { FloatUsers } from "components/special/users";

const PartnerUserData = (props) => {
  const { envStore } = props;
  const { partnerUser, setPartnerUser, saveHandler, requestHandler = new Function() } = props;
  const changeData = setOnChange(partnerUser, setPartnerUser);

  const [error, setError] = useState(false);

  const saveData = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: partnerUser }, { onFailure, onRequest });
  };

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Partners.Users.account" />
        </label>
        <div className="col-8">
          <FloatUsers
            _id={partnerUser.userId}
            showTitle={true}
            showCount={false}
            multi={false}
            title={(user) => user.profile?.name}
            successHandler={([userId]) => {
              setPartnerUser({ ...partnerUser, userId });
            }}
          />
        </div>
      </div>

      <button type="button" onClick={saveData} className="btn c-primary form-button">
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(PartnerUserData);

/** управление каналами
 */
import _ from "lodash";
import React from "react";

import CompaniesTable from "./Table";

const AdvertiserCompanies = (props) => {
  return (
    <div>
      <CompaniesTable />
    </div>
  );
};

export default AdvertiserCompanies;

import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";

import adminAPI, { adminSockets } from "redux/api/admin";

const { api } = adminAPI.actions;

const NavStats = (props) => {
  const { stats, name, listname, statsKey = "stats" } = props;
  const intl = useIntl();

  const ident = listname || name;

  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    try {
      const onmessage = function (msg) {
        setLastUpdate(msg.data);
      };
      adminSockets.auto(ident, {}, { onmessage });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const statsRequest = props.statsUrl;
    const req = {
      query: { lastUpdate, [statsKey]: stats },
    };
    const res = {
      onSuccess(body) {
        // const data = _.keyBy(body.stats, "_id");
        // const result = [];
        // _.map(stats, (key) => _.get(data[`${key}`], "total") && result.push(data[key]));
        setResult(body);
      },
    };
    dispatch(statsRequest(req, res));
  }, [lastUpdate]);

  return (
    <div
      style={{
        position: "absolute",
        top: "-0.7em",
        right: "-0.3em",
        zIndex: 999,
      }}
    >
      {result.map(({ status, count }, index) => {
        if (!count) return null;

        const style_id = `${ident}.${statsKey}.${status}.style`;
        const style = intl.formatMessage({
          id: style_id,
          defaultMessage: style_id,
        });
        const title_id = `${ident}.${statsKey}.${status}`;
        const title = intl.formatMessage({
          id: title_id,
          defaultMessage: title_id,
        });
        const id = _.camelCase(`indicator_${ident}_${status}`);
        return (
          <Fragment key={index}>
            <span id={id} className={`btn btn-sm p-1 ml-1 btn-${style}`}>
              {count}
            </span>
            <UncontrolledTooltip placement="top" autohide={false} target={id}>
              {title}
            </UncontrolledTooltip>
          </Fragment>
        );
      })}
    </div>
  );
};

export default NavStats;

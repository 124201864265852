import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { push } from "react-router-redux";
import { Provider, useDispatch, useSelector, useStore } from "react-redux";
import { FormattedMessage } from "react-intl";

import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { authAPI, fileUrl } from "redux/api";

import _ from "lodash";

const { auth } = authAPI.actions;

const Header = (props) => {
  const dispatch = useDispatch();

  // const settingsStore = useSelector((state) => state.api.settings);
  const settingsStore = useSelector((state) => state.api.settings);

  const userPhotoId = _.get(settingsStore, "croppedFileId");
  // const autoAvatar = `https://avatar.tobi.sh/${settingsStore.user_id}`;
  const autoAvatar = `https://avatars.dicebear.com/v2/identicon/cloclo-${settingsStore._id}.svg?mood[]=happy`;

  const { drawerMiniMethod, mini, layoutSettingDrawerToggle } = props;

  const userSignout = () => {
    dispatch(
      auth.actions.logout(
        {},
        {
          onSuccess() {
            document.location.href = "/";
          },
        }
      )
    );
    // dispatch(push("/"));
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow  flex-1">
            {mini ? (
              <button className="top-header-icon" onClick={() => drawerMiniMethod()}>
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button className="top-header-icon" onClick={() => drawerMiniMethod()}>
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div className="mini-drawer-menu-icon flex-1" onClick={() => drawerMiniMethod()}>
            <i className="fas fa-bars" />
          </div>
          {/* 
                    <div className="pl-10">
                        <button id="mail" className="top-header-icon">
                            <i className="far fa-envelope"></i>
                            <div className="button-badge fs-11 demi-bold-text">3</div>
                        </button>
                        <UncontrolledPopover
                            placement="bottom-start"
                            target="mail"
                            className="header-popover"
                            trigger="focus"
                        >
                            <PopoverBody className="mail-popover-body">
                                <div className="fs-13 bold-text mb-10">You have 3 mails.</div>
                                <PopoverBlock
                                    people={friend1}
                                    name="Alex Woods"
                                    text="Hello, How are you ?"
                                    created="Just Now"
                                />
                                <PopoverBlock
                                    people={friend2}
                                    name="James Anderson"
                                    text="Please check your transaction"
                                    created="22nd July 2019"
                                />
                                <PopoverBlock
                                    people={friend3}
                                    name="Watson"
                                    text="You won price.."
                                    created="20th Jun 2019"
                                />
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                    <div className="pl-10 flex-1">
                        <button id="notification" className="top-header-icon">
                            <i className="far fa-bell"></i>
                            <div className="button-badge fs-11 demi-bold-text">3</div>
                        </button>
                        <UncontrolledPopover
                            placement="bottom-start"
                            target="notification"
                            className="header-popover"
                            trigger="focus"
                        >
                            <PopoverBody className="mail-popover-body">
                                <div className="fs-13 bold-text mb-10">
                                    You have 3 Notifications.
                                </div>
                                <PopoverBlock
                                    people={people1}
                                    name="Juli Hacks"
                                    text="Send You a message..."
                                    created="Just Now"
                                />
                                <PopoverBlock
                                    people={people2}
                                    name="Scarlet JohnSon"
                                    text="Like your status..."
                                    created="22nd July 2019"
                                />
                                <PopoverBlock
                                    people={people3}
                                    name="Andrew Hales"
                                    text="Tagged you in his status"
                                    created="20th Jun 2019"
                                />
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                     */}
          {/* строка поиска
                    <div className="pl-10">
                        <button
                            className="top-header-icon"
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                     */}
          <div className="pr-10">
            <div id="profile">
              <img
                className="top-header-profile-class"
                src={userPhotoId ? fileUrl(userPhotoId) : autoAvatar}
                alt={settingsStore.name}
              />
            </div>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div className="roy-menu-list" style={{ cursor: "default" }}>
                  {settingsStore.name}
                </div>

                {/* 
                <NavLink className="roy-menu-list d-block" to="/profile">
                  <FormattedMessage id="profile" />
                </NavLink>
                 */}

                <NavLink className="roy-menu-list d-block" to="/settings">
                  <FormattedMessage id="settings" />
                </NavLink>

                <NavLink to="/" className="roy-menu-list d-block" onClick={userSignout}>
                  <FormattedMessage id="logout" />
                </NavLink>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          {/* 
          <div className="pl-10">

            <button
              onClick={layoutSettingDrawerToggle}
              className="top-header-icon"
            >
              <i className="fas fa-th-large"></i>
            </button>

          </div>
           */}
        </div>
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  authStore: state.auth.auth,
});

export default compose(withRouter, connect(mapStateToProps, {}))(Header);

import _ from 'lodash';
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const PageTitle = (props) => {
    const { breadCrumbs = [], className } = props;
    const pageTitle = _.last(breadCrumbs);
    return (
        <Fragment>

            {pageTitle &&
                <div className="new-page-title">
                    <div className={classNames("Page-title", className)}>
                        <div className="title flex-1">
                            {pageTitle.title}
                        </div>

                        {pageTitle.actions && _.map(pageTitle.actions, (action, i) => {
                            return (
                                <div className={`roy-round-icon mr-2 ${i == 0 && "ml-auto"}`} key={i}>
                                    {action}
                                </div>
                            )
                        })}

                    </div>
                    {(_.size(breadCrumbs) - 1) > 0 && (
                        <div>
                            <Breadcrumb className="custom-breadcumb">
                                {_.map(breadCrumbs, (e, i) => {
                                    return e !== pageTitle && (
                                        <BreadcrumbItem key={i} active>
                                            <Link className="c-text-info" to={e.url}>
                                                {e.title}
                                            </Link>
                                        </BreadcrumbItem>
                                    );
                                })
                                }

                            </Breadcrumb>
                        </div>
                    )}

                </div>
            }
        </Fragment>
    );
};


const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(
    mapStateToProps,
    null
)(PageTitle);


export class BreadCrumbs {

    constructor(elements = []) {
        this.breadCrumbs = elements;
    }

    push(...elements) {
        this.breadCrumbs.push(...elements);
        return this;
    }

}
import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { Alert } from "reactstrap";
import { SwitchValue } from "components/special/forms";

import IntlMessages from "util/intlMessages";
import ChannelData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";
import { setOnChange } from "components/special";

const { api } = adminAPI.actions;

const ChannelForm = (props) => {
  const params = useParams();
  const { breadCrumbs, channel, setChannel } = props;

  const changeChannel = setOnChange(channel, setChannel);

  const [dataSaved, setDataSaved] = useState(false);
  const [error, setError] = useState(false);
  const successAlertRef = useRef(null);

  // useEffect(() => {
  //   channel && setChannelId(channel._id);
  // }, [channel]);

  const saveHandler = (req, res) => {
    const onSuccess = (body) => {
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
      // props.refreshHandler();
      // toggleModal();
    };
    const onFailure = ({ message }) => {
      setError(message);
    };
    props.saveData({ body: channel, params }, { onSuccess, onFailure });
  };

  /*
  const removeHandler = () => {
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить канал?");
    if (isConfirmed) {
      props.removeData({ params: { ...params, channelId } }, res);
    }
  };
  */

  const timeout = useRef(null);
  const [validChannel, setValidChannel] = useState(false);
  const [validation, setValidation] = useState(false);

  const deleteChannelHandler = () => {
    const confirm = window.confirm(
      "Данная операция удалит канал, все сообщения, файлы и выпуски с ним. Операцию нелья отменить. Процедура удаления займет 10-15 минут. Продолжить?"
    );
    if (confirm) {
      props.deleteChannel(
        { params },
        {
          onSuccess() {
            document.location.href = "/channels";
          },
        }
      );
    }
  };

  const rejectChannelCastsHandler = () => {
    const confirm = window.confirm(
      "Данная операция отменит публикацию всех выпусков по данному каналу. Продолжить?"
    );
    if (confirm) {
      props.rejectChannelCasts(
        { params },
        {
          onSuccess({ message }) {
            setDataSaved(message);
            successAlertRef.current.scrollIntoView();
          },
        }
      );
    }
  };

  const checkValidChannel = () => {
    props.checkChannel(
      { params, query: { ...channel }, body: { ...params, ...channel } },
      {
        onSuccess() {
          setError(false);
          setValidation(false);
          setValidChannel(true);
        },
        onFailure({ message }) {
          setValidation(false);
          setError(message);
        },
        onRequest() {
          setValidation(true);
          setValidChannel(false);
        },
      }
    );
  };

  // проверим, что канал валиден
  useEffect(() => {
    clearTimeout(timeout.current);
    setValidChannel(false);
    if (channel.name && channel.alias) {
      timeout.current = setTimeout(() => {
        checkValidChannel();
      }, 500);
    }
  }, [channel]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                {error && (
                  <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                    <IntlMessages id={error} />
                  </Alert>
                )}

                {dataSaved ? (
                  <Alert className="alert-success" isOpen={true} toggle={() => setDataSaved(false)}>
                    {_.isBoolean(dataSaved) ? <IntlMessages id="saved" /> : dataSaved}
                  </Alert>
                ) : null}
              </div>

              <ChannelData {...{ channel, setChannel }} />

              {/*
               */}
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={channel.enabled} onChange={changeChannel} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4">
                  <button
                    className="form-control c-primary"
                    type="button"
                    disabled={!validChannel}
                    onClick={saveHandler}
                  >
                    {validation ? (
                      <i className="fa fa-spin fa-spinner" />
                    ) : (
                      <>
                        {validChannel && <IntlMessages id="save" />}
                        {error && <IntlMessages id="error" />}
                      </>
                    )}
                  </button>
                </div>
                <div className="col"></div>
                <div className="col-2">
                  <button className="btn btn-sm btn-danger" onClick={deleteChannelHandler}>
                    <i className="fa fa-times mr-2" />
                    <IntlMessages id="delete" />
                  </button>
                </div>
                <div className="col-2">
                  <button className="btn btn-sm btn-warning" onClick={rejectChannelCastsHandler}>
                    <i className="fa fa-remove-format mr-2" />
                    <IntlMessages id="Channels.rejectCasts" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { channel } = props;
  return (
    <Link to={formLink(channel)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

const getChannelMessages = api.get(apiUrls.channelMessages);
const getChannelInfo = api.get(apiUrls.channelInfo);
const GetInfoButton = (channel) => {
  const dispatch = useDispatch();
  const params = useParams();
  const getInfo = () => {
    dispatch(
      getChannelInfo(
        { params: { ...params, channelId: channel._id } },
        {
          onSuccess(result) {
            console.log("info result", result);
          },
        }
      )
    );
  };
  return (
    <button className="btn btn-sm btn-warning ml-1" onClick={getInfo}>
      <i className="fa fa-info-circle" />
    </button>
  );
};

const GetMessagesButton = (channel) => {
  const dispatch = useDispatch();
  const params = useParams();
  const getInfo = () => {
    dispatch(
      getChannelMessages(
        { params: { ...params, channelId: channel._id } },
        {
          onSuccess(result) {
            console.log("messages result", result);
          },
        }
      )
    );
  };
  return (
    <button className="btn btn-sm btn-info ml-1" onClick={getInfo}>
      <i className="fa fa-comments" />
    </button>
  );
};

export default connect(null, {
  saveData: api.put(apiUrls.info),
  removeData: api.delete(apiUrls.info),
  checkChannel: api.post(apiUrls.checkChannel),
  deleteChannel: api.post(apiUrls.delete),
  rejectChannelCasts: api.post(apiUrls.rejectCasts),
})(ChannelForm);

import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { filesAPI } from "redux/api";
import IntlMessages from "util/intlMessages";

const { api } = filesAPI.actions;

const Uploader = (props) => {
  let { accept = "*.*", multiple = true, successHandler } = props;
  if (props.imagesOnly) {
    accept = ".jpg,.png,.jpeg,.gif";
  }
  const [uploadProgress, setUploadProgress] = useState(false);

  const uploadHandler = (event) => {
    event.preventDefault();
    const body = new FormData();

    const onSuccess = (result) => {
      setUploadProgress(false);
      successHandler && successHandler(result);
    };
    const onRequest = (result) => {
      setUploadProgress(true);
    };

    _.map(event.target.files, (file) => {
      body.append("file", file);
    });
    const upload = props.uploadHandler || props.uploadDefault;
    upload({ body }, { onSuccess, onRequest });
  };

  const uploadInputStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    fontSize: "200px",
    opacity: 0,
    direction: "ltr",
  };

  return (
    <span className={`btn ${props.className}`} style={{ position: "relative", overflow: "hidden" }}>
      <input
        type="file"
        name="file"
        onChange={uploadHandler}
        accept={accept}
        multiple={multiple}
        style={uploadInputStyle}
        ref={props.forwardRef}
      />

      {uploadProgress ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-upload" />}

      {props.title && <IntlMessages id={props.title} />}
    </span>
  );
};

export default connect(null, { uploadDefault: api.upload("/upload") })(Uploader);

/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";
import { Table } from "reactstrap";

import { Link, useRouteMatch, useParams } from "react-router-dom";
import moment from "moment";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { FloatCustomers } from "components/special/customers";
import { StatusStats } from "components/special/forms";
import { receiptInfo } from "./index";
import { Pager, Sorter, Finder, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { infoLink as userLink } from "views/admin/Users/init";
import { phoneNumber } from "components/special";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ReceiptsTable = (props) => {
  const intl = useIntl();
  const { url } = useRouteMatch();
  const params = useParams();
  const [receipts, setReceipts] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search), ...params });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setReceipts(body);
    };
    const onRequest = () => {
      setReceipts({ total: -1 });
    };

    query.limit && props.getReceipts({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      {/* 
      <StatusStats
        statuses={_.get(envStore, "receipts.statuses")}
        stats={receipts.stats}
        {...{ query, url }}
        ident="Receipts.status"
      />
       */}

      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>

            <th>
              <FloatCustomers
                _id={query.customerId}
                checkAll={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(customerId) => {
                  setQuery({ ...query, customerId });
                }}
              />
              <IntlMessages id="Receipts.customer" />
            </th>

            <th>
              {/* 
              <Finder className="mr-1 d-inline" isActive={query.status}>
                <Finder.select
                  name="status"
                  {...{ query, setQuery }}
                  source={_.get(envStore, "receipts.statuses")}
                  mask="Receipts.status"
                />
              </Finder>
              */}

              <IntlMessages id="tables.status" />
            </th>

            <th>
              <Finder.search {...{ query, url }} />
            </th>
          </tr>
        </thead>
        {receipts.total < 0 && <TableLoading />}
        <tbody>
          {_.map(receipts.data, (receipt, key) => {
            const { userId } = receipt;

            const { Link: receiptUrl } = receipt.kitResult || {};
            return (
              <tr key={key}>
                <td>
                  {moment(receipt.createdAt).format(intl.formatMessage({ id: "dateTimeFormat" }))}
                  {/* 
                  <Link to={receiptInfo(receipt)}>
                  </Link>
                  */}
                </td>

                {/* 
                <td>
                  <IntlMessages id={`Receipts.type.${receipt.type}`} />
                </td>
                */}

                <td>
                  <Link to={userLink({ _id: userId })}>
                    <div>{userId}</div>
                  </Link>
                </td>
                <td>
                  <IntlMessages id={`Receipts.status.${receipt.status}`} />
                  <div className="text-danger">{receipt.error}</div>
                </td>

                <td>
                  {receiptUrl && (
                    <a href={receiptUrl} target="_blank">
                      <i className="fa fa-receipt" />
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={receipts.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getReceipts: api.get(apiUrls.receipts),
})(ReceiptsTable);

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsSingleLineChart from "components/stats/AttrsSingleLineChart";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const CurrenciesSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      {/* 
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/currencies/alias")}
          dataAttr={"currencySum"}
          title="Доходы"
          query={defaultQuery()}
          showPercent={true}
        />
      </StatsComponent>
       */}

      {/* 
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/currencies/adverts")}
          dataAttr={"currencySum"}
          title="Сводка по рекламе"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/currencies/payments")}
          dataAttr={"currencySum"}
          title="Сводка по покупкам"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>
       */}

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/currencies/adverts")}
          dataAttr={"currencySum"}
          attrNameOnly={"currencyId"}
          title="Доходы реклама"
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/currencies/payments")}
          dataAttr={"currencySum"}
          attrNameOnly={"currencyId"}
          title="Доходы Premium"
          query={defaultQuery()}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {})(CurrenciesSummaryStats);

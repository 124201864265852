/** модальное окно для пользователей */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatUsers = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id={"Users"} />}
      {...props}
      head={(searchUsers, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Users.name" />
            </th>
            <th></th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(user, clickHandler) => {
        return (
          <>
            <td onClick={clickHandler} className={!user.enabled ? "text-strike" : ""}>
              {user.profile.name}
            </td>
            <td onClick={clickHandler} className={!user.enabled ? "text-strike" : ""}>
              {/* {user.name} */}
            </td>
            <td></td>
          </>
        );
      }}
    />
  );
};

export default connect(null, {
  getData: api.get("/api/users"),
})(FloatUsers);

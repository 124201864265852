import _ from "lodash";
import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";
import NumberFormat from "react-number-format";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";
import shortNumber from "short-number";
import AttrsValuesContainer from "./AttrsValuesContainer";

const AttrsPieChart = (props) => {
  const intl = useIntl();


  return (
    <AttrsValuesContainer {...props}>
      {({ plainData, datasets }) => {
        // console.log({ plainData, datasets });
        const data = [];
        let totalValue = 0;
        // из каждой datapoint извлечем значения поместим их список с соответствующей меткой
        datasets.forEach((dataset) => {
          const { value = 0 } = { ...dataset.dataPoints[0] };
          totalValue += +value;
          data.push({ value, key: dataset.dataKey, label: dataset.label });
          // data.push(...dataset.dataPoints.filter(_.size));
        });

        const colsCount = props.cols || 1; // количество колонок
        const columnsSplit = []; // разбитие по колонкам
        const styleColSize = _.ceil(12 / props.cols);
        const columnsSize = _.ceil(_.size(datasets) / colsCount); // максимальный размер колонки
        let columnsSplitIndex = 0; // текущий индекс
        _.map(_.range(0, colsCount), () => {
          let columnData = [];
          _.map(_.range(0, columnsSize), () => {
            if (datasets[columnsSplitIndex]) {
              columnData.push({ index: columnsSplitIndex });
              columnsSplitIndex++;
            }
          });
          columnsSplit.push(columnData);
        });
        // setColumnsData(columnsSplit);

        return (
          <div className="card roe-shadow-2 fill-height">
            <div className="card-body">
              <div className="card-title h4">
                <>{props.title}</>
                {/* {typeof props.title === "string" ? (
                  <IntlMessages id={props.title} />
                ) : (
                )} */}
              </div>
              {/* <small>{JSON.stringify(data)}</small> */}

              <div className="row">
                <div className="col-lg-6 col-12">
                  <PieChart width={250} height={250} data={plainData}>
                    <Pie
                      data={data}
                      // cx={160}
                      // cy={140}
                      innerRadius={50}
                      outerRadius={70}
                      paddingAngle={5}
                      dataKey={"value"}
                    >
                      {datasets.map((dataset, index) => (
                        <Cell key={`cell-${index}`} fill={dataset.color} />
                      ))}
                      {props.showPercent ? (
                        <Label
                          value={shortNumber(_.round(totalValue, 2))}
                          position="center"
                          className="-text-dark fs-20 font-weight-bold"
                          style={{ color: "#000" }}
                        />
                      ) : null}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    {/* <ResponsiveContainer width="100%" height="100%"> */}
                    //
                  </PieChart>
                </div>
                {/* </ResponsiveContainer> */}
                <div className="col-lg-6 col-12">
                    {/* <div className="-d-none">{JSON.stringify(columnsSplit)}</div> */}
                  <div className="row">
                    {_.map(columnsSplit, (column, columnIndex) => {
                      return (
                        <div className={`col-${styleColSize}`} key={columnIndex}>
                          {_.map(column, ({ index }) => {
                            const dataset = datasets[index];
                            const { value = 0 } = { ...dataset.dataPoints[0] };
                            const label = dataset.label;
                            const color = dataset.color;
                            const percent =
                              totalValue > 0 ? _.round((value / totalValue) * 100, 2) : 0;
                            return (
                              <div key={index} className="justify-content-center mr-5">
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-circle  mt-1 mr-2" style={{ color }} />
                                  <h4 className="mb-0 text-dark fs-20 font-weight-bold text-nowrap">
                                    <NumberFormat
                                      displayType="text"
                                      thousandSeparator={" "}
                                      value={value}
                                    />

                                    {props.showPercent && (
                                      <span className="ml-2 text-muted">({percent} %)</span>
                                    )}
                                  </h4>
                                </div>
                                <small className="text-muted text-nowrap -ml-3">{label}</small>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </AttrsValuesContainer>
  );
};

const CustomTooltip = ({ active, payload, label, fill }) => {
  if (active && payload && payload.length) {
    payload = payload[0].payload;
    return (
      <div className="p-1 bg-white border border-1 border-rounded">
        <p
          className="label"
          // style={{ color: payload.fill }}
        >{`${payload.label} : ${payload.value}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
      </div>
    );
  }

  return null;
};

export default AttrsPieChart;

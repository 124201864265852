import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { SwitchValue } from "components/special/forms";
import { Uploader, AudioPreview, ImagePreview, Text2Mp3Button } from "components/standart";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";

const { api } = adminAPI.actions;

const AdvertData = (props) => {
  const params = useParams();
  const { advert, setAdvert, saveHandler, requestHandler = new Function() } = props;
  const changeChannel = setOnChange(advert, setAdvert);

  const [error, setError] = useState(false);
  const [validAdvert, setValidAdvert] = useState(false);

  const saveChannel = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: { ...params, ...advert } }, { onFailure, onRequest });
  };

  const timeout = useRef(null);

  // проверим, что логин доступн
  useEffect(() => {
    clearTimeout(timeout.current);
    setValidAdvert(Boolean(advert.name && advert.fileId));
  }, [advert]);

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={advert.name || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.image" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setAdvert({ ...advert, imageId: file._id })}
            imagesOnly={true}
            multiple={false}
          />
        </label>
        <div className="col-8">
          {advert.imageId ? (
            <ImagePreview
              file={{ _id: advert.imageId }}
              deleteHandler={() => {
                setAdvert({ ...advert, imageId: null });
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.url" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="url"
            value={advert.url || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="title"
            value={advert.title || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row" style={{ height: 150 }}>
        <label className="col-4 col-form-label">
          <IntlMessages id={`Adverts.content`} />
        </label>
        <div className="col">
          <textarea
            rows={5}
            name="content"
            className="form-control react-form-input"
            onChange={changeChannel}
          >
            {advert.content || ""}
          </textarea>
        </div>
        <div className="col-1">
          <Text2Mp3Button
            value={advert.content}
            onSuccess={(file) => setAdvert({ ...advert, fileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.file" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setAdvert({ ...advert, fileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col-6">
          {advert.fileId ? (
            <AudioPreview
              file={{ _id: advert.fileId }}
              deleteHandler={() => {
                setAdvert({ ...advert, fileId: null });
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Adverts.legalName" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="legalName"
            value={advert.legalName || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">ERID</label>
        <div className="col-8">
          <input
            type="text"
            name="ERID"
            value={advert.ERID || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="tables.enabled" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={advert.enabled} onChange={changeChannel} />
        </div>
      </div>

      <button
        type="button"
        disabled={!validAdvert}
        onClick={saveChannel}
        className="btn c-primary form-button"
      >
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(AdvertData);

/** список логинов */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams, Link } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, FilterButton, Pager, Sorter, TableLoading } from "components/standart";
import { priceValue } from "components/special";
import { FloatCategories, FloatPartners } from "components/special/contents";
import { Popover, PopoverBody } from "reactstrap";

import OrderChannels from "./Order";
import { limit } from "variables";

import { apiUrls, infoLink } from "./init";
// import AddChannel from "./Add";
// import ChannelForm from "./Form";
import { FormButton } from "./Form";

const { api } = adminAPI.actions;

const ChannelsTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();

  const [copied, setCopied] = useState(false);
  const [copiedId, setCopiedId] = useState(null);
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => {
        setCopied(false);
        setCopiedId(null);
      }, 3000);
    }
  }, [copied]);

  const copyLink = (event) => {
    // metrikaTargetClick({ ...event });
    event.preventDefault();
  };

  const [channels, setChannels] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setChannels(body);
    };
    const onRequest = () => {
      setChannels({ total: -1 });
    };

    query.limit && props.getChannels({ query: { ...query, ...params } }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Fragment>
      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr className="text-nowrap">
            <th>
              <Finder className="mr-1 d-inline" isActive={query.name}>
                <Finder.input name="name" {...{ query, setQuery }} />
              </Finder>
              <IntlMessages id="Channels.name" />
            </th>
            <th>
              <FloatCategories
                _id={query.categoryId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(categoryId) => {
                  setQuery({ ...query, categoryId });
                }}
              />
              <IntlMessages id="Channels.category" />
            </th>

            <th>
              Подписчики
              <Sorter query={query} url={url} order="subscribersCount" />
            </th>
            <th>
              Слушатели
              <Sorter query={query} url={url} order="listeningStats.sessionsCount" />
            </th>
            <th>
              Прослушивания
              <Sorter query={query} url={url} order="listeningStats.statsCount" />
            </th>
            <th>
              Охват
              <Sorter query={query} url={url} order="listeningStats.coverage" />
            </th>
            <th>
              ERR
              <Sorter query={query} url={url} order="listeningStats.ERR" />
            </th>
            <th>
              Дослушивания
              <Sorter query={query} url={url} order="listeningStats.progressListening" />
            </th>
            <th>
              Избранное
              <Sorter query={query} url={url} order="favoritesCount" />
            </th>
            <th>
              Посты
              <Sorter query={query} url={url} order="charsStats.messagesCount" />
            </th>
            <th>
              Обработка
              <Sorter query={query} url={url} order="charsStats.charsSum" />
            </th>
            <th>
              Обработка $
              <Sorter query={query} url={url} order="charsStats.charsCost" />
            </th>

            <th>
              <Finder className="mr-1 d-inline" isActive={query.alias}>
                <Finder.input name="alias" {...{ query, setQuery }} />
              </Finder>
              <IntlMessages id="Channels.alias" />
            </th>

            <th>Radiogram</th>

            <th>
              <i className="fa fa-home-heart" />
              <Sorter query={query} url={url} order="playlistDefault" />
            </th>

            {/* 
            <th>
              <FloatPartners
                _id={query.partnerId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(partnerId) => {
                  setQuery({ ...query, partnerId });
                }}
              />
              <IntlMessages id="Channels.partner" />
            </th>
             */}
            <th className="text-right col-1">
              {/* <OrderChannels /> */}
              <Finder.search {...{ query, url }} className="mr-1">
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>

              {/* <AddChannel refreshHandler={refreshHandler} /> */}
            </th>
          </tr>
        </thead>
        {channels.total < 0 && <TableLoading />}
        <tbody>
          {_.map(channels.data, (channel, key) => {
            const radiogramUrl = `${process.env.REACT_APP_RADIOGRAM_URL}/${channel.alias}`;
            const id = `copy_${channel._id}`;
            return (
              <tr key={key} className={!channel.enabled ? "text-strike" : ""}>
                <td>
                  <div className="text-truncate" style={{ width: "200px" }}>
                    <Link to={infoLink(channel)}>{channel.name}</Link>
                  </div>
                </td>
                <td>{channel.category?.name}</td>

                <td>{priceValue(channel.subscribersCount)}</td>
                <td>{priceValue(channel.listeningStats?.sessionsCount)}</td>
                <td>{priceValue(channel.listeningStats?.statsCount)}</td>
                <td>{priceValue(channel.listeningStats?.coverage)}</td>
                <td>{priceValue(channel.listeningStats?.ERR)}</td>
                <td>{priceValue(channel.listeningStats?.progressListening)}</td>
                <td>{priceValue(channel.favoritesCount)}</td>
                <td>{priceValue(channel.charsStats?.messagesCount)}</td>
                <td>{priceValue(channel.charsStats?.charsSum)}</td>
                <td>{priceValue(channel.charsStats?.charsCost)}</td>

                <td>
                  <a href={`https://t.me/${channel.alias}`} target="_blank">
                    {channel.alias}
                  </a>
                </td>
                <td>
                  <CopyToClipboard
                    text={radiogramUrl}
                    onCopy={() => {
                      setCopied(true);
                      setCopiedId(id);
                    }}
                  >
                    <a
                      href={radiogramUrl}
                      id={id}
                      className="d-block text-black"
                      target="_blank"
                      data-id="share-daily"
                      onClick={copyLink}
                    >
                      <i className="fa fa-copy" />
                    </a>
                  </CopyToClipboard>

                  {copied && (
                    <Popover fade={true} placement="top" isOpen={copied} target={copiedId}>
                      <PopoverBody>Ссылка скопирована</PopoverBody>
                    </Popover>
                  )}
                </td>
                <td>{channel.playlistDefault ? <i className="fa fa-check" /> : null}</td>
                <td className="text-right">
                  <FormButton {...{ channel }} />
                  {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={channels.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </Fragment>
  );
};

export default connect(null, {
  getChannels: api.get(apiUrls.list),
})(ChannelsTable);

/** модальное окно для выбора локализаций в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";
import FloatCategories from "./FloatCategories";

const { api } = adminAPI.actions;

const FloatChannelsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Channels" />}
      {...props}
      head={(search, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="name" />
            </th>
            <th className="text-nowrap">
              <IntlMessages id="Channels.category" />
              <FloatCategories
                _id={searchQuery.categoryId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm ml-1"
                successHandler={(categoryId) => {
                  setSearchQuery({ ...searchQuery, categoryId });
                  // Object.assign(values[index], { categoriesId });
                  // changeValues(values);
                }}
              />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(channel, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!channel.enabled ? "text-strike" : ""}>
            {channel.name}
          </td>

          <td onClick={clickHandler} className={!channel.enabled ? "text-strike" : ""}>
            {channel.category?.name}
          </td>

          <td></td>
        </>
      )}
    />
  );
};

const FloatChannels = (props) => {
  return <FloatChannelsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/channels"),
})(FloatChannels);

import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useParams, Link } from "react-router-dom";

import { Alert } from "reactstrap";
import { SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";
import { ImagePreview, Uploader, TelegramPublishFormatSelector } from "components/standart";
import { FloatPublishChannels } from "components/special/contents";

import IntlMessages from "util/intlMessages";
import SpecialsData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const ChannelForm = (props) => {
  const params = useParams();
  const { breadCrumbs, specials, setSpecials } = props;
  const intl = useIntl();

  const changeSpecials =
    specials.status === "draft" ? setOnChange(specials, setSpecials) : new Function();

  const [dataSaved, setDataSaved] = useState(false);
  const [error, setError] = useState(false);
  const successAlertRef = useRef(null);

  // useEffect(() => {
  //   specials && setChannelId(specials._id);
  // }, [specials]);

  const saveHandler = (req, res) => {
    const onSuccess = (body) => {
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
      // props.refreshHandler();
      // toggleModal();
    };
    const onFailure = ({ message }) => {
      setError(message);
    };
    props.saveData({ body: specials, params }, { onSuccess, onFailure });
  };

  /*
  const removeHandler = () => {
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить канал?");
    if (isConfirmed) {
      props.removeData({ params: { ...params, channelId } }, res);
    }
  };
  */

  const timeout = useRef(null);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                {error && (
                  <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                    <IntlMessages id={error} />
                  </Alert>
                )}

                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              <SpecialsData {...{ specials, setSpecials }} />

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  {intl.formatMessage({ id: "Specials.translationPublish" })}
                </label>
                <div className="col my-auto">
                  <SwitchValue
                    value={specials.translationPublish}
                    name="translationPublish"
                    onChange={changeSpecials}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  {intl.formatMessage({ id: "Specials.telegramPublish" })}
                </label>
                <div className="col my-auto">
                  <SwitchValue
                    value={specials.telegramPublish}
                    name="telegramPublish"
                    onChange={changeSpecials}
                  />
                </div>
              </div>

              {specials.telegramPublish ? (
                <>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      {intl.formatMessage({
                        id: "telegramPublishChannel",
                      })}
                    </label>
                    <div className="col my-auto">
                      <FloatPublishChannels
                        _id={specials.publishChannelId}
                        multi={false}
                        showTitle={true}
                        showCount={false}
                        className="btn-sm mr-1"
                        successHandler={([publishChannelId]) => {
                          setSpecials({ ...specials, publishChannelId });
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      {intl.formatMessage({ id: "telegramPublishFormat" })}
                    </label>
                    <div className="col my-auto">
                      <TelegramPublishFormatSelector
                        value={specials.telegramPublishFormat}
                        setValue={changeSpecials}
                        name="telegramPublishFormat"
                      />
                    </div>
                  </div>

                  {/* 
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      {intl.formatMessage({ id: "Specials.telegramTextPublish" })}
                    </label>
                    <div className="col my-auto">
                      <SwitchValue
                        value={specials.telegramTextPublish}
                        name="telegramTextPublish"
                        labelOff={intl.formatMessage({
                          id: "Specials.telegramTextPublish.audio",
                        })}
                        labelOn={intl.formatMessage({
                          id: "Specials.telegramTextPublish.text",
                        })}
                        onChange={changeSpecials}
                      />
                    </div>
                  </div>
                   */}

                  {/* {specials.telegramTextPublish ? ( */}
                  <>
                    <div className="row">
                      <label className="col-4 col-form-label">
                        {intl.formatMessage({ id: "Specials.templateFileId" })}
                        <Uploader
                          className="btn-success btn-sm pull-right"
                          successHandler={([file]) => {
                            setSpecials({ ...specials, templateFileId: file._id });
                          }}
                          imagesOnly="true"
                          multiple={false}
                        />
                      </label>
                      <div className="col my-auto">
                        {specials.templateFileId ? (
                          <ImagePreview
                            file={{ _id: specials.templateFileId }}
                            deleteHandler={() => {
                              setSpecials({ ...specials, templateFileId: null });
                            }}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-4 col-form-label">
                        {intl.formatMessage({ id: "Specials.telegramStoryPublish" })}
                      </label>
                      <div className="col my-auto">
                        <SwitchValue
                          value={specials.telegramStoryPublish}
                          name="telegramStoryPublish"
                          onChange={changeSpecials}
                          // onChange={changeContent}
                        />
                      </div>
                    </div>

                    {specials.telegramStoryPublish ? (
                      <>
                        <div className="row">
                          <label className="col-4 col-form-label">
                            {intl.formatMessage({ id: "Specials.storyTemplateFileId" })}
                            <Uploader
                              className="btn-success btn-sm pull-right"
                              successHandler={([file]) => {
                                setSpecials({ ...specials, storyTemplateFileId: file._id });
                              }}
                              imagesOnly="true"
                              multiple={false}
                            />
                          </label>
                          <div className="col my-auto">
                            {specials.storyTemplateFileId ? (
                              <ImagePreview
                                file={{ _id: specials.storyTemplateFileId }}
                                deleteHandler={() => {
                                  setSpecials({ ...specials, storyTemplateFileId: null });
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                  {/* ) : null} */}
                </>
              ) : null}

              <div className="form-group row">
                <div className="col-4">
                  <button
                    className="form-control c-primary"
                    disabled={specials.status !== "draft"}
                    type="button"
                    onClick={saveHandler}
                  >
                    <IntlMessages id="save" />
                  </button>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { special } = props;
  return (
    <Link to={formLink(special)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

const startPublish = api.post(apiUrls.publish);
export const PublishButton = (props) => {
  const { special, refreshHandler } = props;
  const dispatch = useDispatch();
  const confirmPublishHandler = () => {
    const confirm = window.confirm(
      "Опубликовать спецвыпуск? После запуска публикации нельзя будет изменить значения в выпуске."
    );
    if (confirm) {
      dispatch(
        startPublish(
          { params: { specialId: special._id } },
          {
            onSuccess() {
              refreshHandler();
            },
          }
        )
      );
    }
  };
  return special.status === "draft" ? (
    <button className="btn btn-warning btn-sm mx-1" onClick={confirmPublishHandler}>
      <i className="fa fa-check" />
    </button>
  ) : null;
};

const deletePublish = api.post(apiUrls.delete);
export const DeleteButton = (props) => {
  const { special, refreshHandler } = props;
  const dispatch = useDispatch();
  const confirmDeleteHandler = () => {
    const confirm = window.confirm("Удалить спецвыпуск?");
    if (confirm) {
      dispatch(
        deletePublish(
          { params: { specialId: special._id } },
          {
            onSuccess() {
              refreshHandler();
            },
          }
        )
      );
    }
  };
  return special.status === "published" ? (
    <button className="btn btn-danger btn-sm mx-1" onClick={confirmDeleteHandler}>
      <i className="fa fa-times" />
    </button>
  ) : null;
};

export default connect(null, {
  saveData: api.put(apiUrls.info),
})(ChannelForm);

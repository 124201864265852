/** список выпусков */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Pager, TableLoading, Finder } from "components/standart";
import { FloatCategories, FloatChannels } from "components/special/contents";
import { ShowShortDuration } from "components/special/durations";

import { limit } from "variables";

import { GeneratedCastInfoButton } from "./Info";
import { apiUrls, infoLink } from "./init";
import { infoLink as scheduleInfoLink } from "../Schedules/init";

const { api } = adminAPI.actions;

const GeneratedCastsTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [data, setData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);
  const { envStore } = props;

  const refreshHandler = () => setRefresh(Date.now());

  const resetQuery = () => {
    setQuery({ ...props.query, ...params, limit, ...qs.parse(history.location.search) });
  };

  useEffect(() => {
    resetQuery();
    // setQuery({ ...props.query, ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location.search]);

  useEffect(() => {
    const onSuccess = (body) => {
      setData(body);
    };
    const onRequest = () => {
      setData({ total: -1 });
    };

    query.limit && props.getCasts({ params, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <div>
      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr>
            <th className="text-nowrap">
              <IntlMessages id="OpenAI.Schedules" />
            </th>

            <th className="text-nowrap">
              <FloatCategories
                _id={query.categoryId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(categoryId) => {
                  setQuery({ ...query, categoryId });
                }}
              />

              <IntlMessages id="OpenAI.Casts.category" />
            </th>
            <th className="text-nowrap">
              <FloatChannels
                _id={query.channelId}
                query={{ everything: false }}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(channelId) => {
                  setQuery({ ...query, channelId });
                }}
              />

              <IntlMessages id="OpenAI.Casts.channel" />
            </th>
            <th>
              <IntlMessages id="time" />
            </th>
            <th>
              <Finder className="mr-1 d-inline" isActive={query.status}>
                <Finder.select
                  name="status"
                  {...{ query, setQuery }}
                  source={_.get(envStore, "messagesCastsStatuses")}
                  mask="OpenAI.Casts.status"
                />
              </Finder>

              <IntlMessages id="OpenAI.Casts.status" />
            </th>
            <th>
              <IntlMessages id="OpenAI.Casts.publishedAt" />
            </th>
            <th>
              <IntlMessages id="OpenAI.Casts.isLast" />
            </th>
            {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
            <th className="text-right text-nowrap -col-4">
              <Finder.search {...{ query, url }} onClick={resetQuery} className="mr-1" />
            </th>
          </tr>
        </thead>
        {data.total < 0 && <TableLoading />}
        <tbody>
          {_.map(data.data, (cast, key) => {
            return (
              <tr key={key}>
                <td>
                  <Link to={scheduleInfoLink(cast.schedule)}>{cast.schedule?.name}</Link>
                </td>
                <td>{cast.category?.name}</td>
                <td>{cast.channel?.name}</td>
                <td>{cast.duration ? <ShowShortDuration {...cast} /> : null}</td>

                <td>{intl.formatMessage({ id: `OpenAI.Casts.status.${cast.status}` })}</td>

                <td>{cast.publishedAt ? moment(cast.publishedAt).format(dateTimeFormat) : null}</td>
                <td>{intl.formatMessage({ id: cast.isLast ? "yes" : "no" })}</td>

                <td className="text-right">
                  <GeneratedCastInfoButton {...{ cast, refreshHandler }} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={data.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getCasts: api.get(apiUrls.casts),
})(GeneratedCastsTable);

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import AdvertiserUserData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../../init";

const { api } = adminAPI.actions;

const AddUser = (props) => {
  const params = useParams();

  const [advertiserUser, setAdvertiserUser] = useState({});
  // const changeUser = setOnChange(newUser, setNewUser);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.addUser({ ...req, params }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setAdvertiserUser({});
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Logins.Add"} />
          </ModalHeader>
          <ModalBody>
            <AdvertiserUserData {...{ advertiserUser, setAdvertiserUser, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addUser: api.post(apiUrls.users),
})(AddUser);

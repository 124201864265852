/** управление каналами
 */
import _ from "lodash";
import React from "react";

import ChannelsMessagesTable from "./Table";

const ChannelMessages = (props) => {
  return (
    <div>
      <ChannelsMessagesTable />
    </div>
  );
};

export default ChannelMessages;

export const colorJson = {
  red: {
      "50": { textColor: "#000000", hex: "#ffebee" },
      "100": { textColor: "#000000", hex: "#ffcdd2" },
      "200": { textColor: "#000000", hex: "#ef9a9a" },
      "300": { textColor: "#000000", hex: "#e57373" },
      "400": { textColor: "#000000", hex: "#ef5350" },
      "500": { textColor: "#FFFFFF", hex: "#f44336" },
      "600": { textColor: "#FFFFFF", hex: "#e53935" },
      "700": { textColor: "#FFFFFF", hex: "#d32f2f" },
      "800": { textColor: "#FFFFFF", hex: "#c62828" },
      "900": { textColor: "#FFFFFF", hex: "#b71c1c" },
      a100: { textColor: "#000000", hex: "#ff8a80" },
      a200: { textColor: "#FFFFFF", hex: "#ff5252" },
      a400: { textColor: "#FFFFFF", hex: "#ff1744" },
      a700: { textColor: "#FFFFFF", hex: "#d50000" }
  },
  pink: {
      "50": { textColor: "#000000", hex: "#fce4ec" },
      "100": { textColor: "#000000", hex: "#f8bbd0" },
      "200": { textColor: "#000000", hex: "#f48fb1" },
      "300": { textColor: "#000000", hex: "#f06292" },
      "400": { textColor: "#000000", hex: "#ec407a" },
      "500": { textColor: "#FFFFFF", hex: "#e91e63" },
      "600": { textColor: "#FFFFFF", hex: "#d81b60" },
      "700": { textColor: "#FFFFFF", hex: "#c2185b" },
      "800": { textColor: "#FFFFFF", hex: "#ad1457" },
      "900": { textColor: "#FFFFFF", hex: "#880e4f" },
      a100: { textColor: "#000000", hex: "#ff80ab" },
      a200: { textColor: "#FFFFFF", hex: "#ff4081" },
      a400: { textColor: "#FFFFFF", hex: "#f50057" },
      a700: { textColor: "#FFFFFF", hex: "#c51162" }
  },
  purple: {
      "50": { textColor: "#000000", hex: "#f3e5f5" },
      "100": { textColor: "#000000", hex: "#e1bee7" },
      "200": { textColor: "#000000", hex: "#ce93d8" },
      "300": { textColor: "#FFFFFF", hex: "#ba68c8" },
      "400": { textColor: "#FFFFFF", hex: "#ab47bc" },
      "500": { textColor: "#FFFFFF", hex: "#9c27b0" },
      "600": { textColor: "#FFFFFF", hex: "#8e24aa" },
      "700": { textColor: "#FFFFFF", hex: "#7b1fa2" },
      "800": { textColor: "#FFFFFF", hex: "#6a1b9a" },
      "900": { textColor: "#FFFFFF", hex: "#4a148c" },
      a100: { textColor: "#000000", hex: "#ea80fc" },
      a200: { textColor: "#FFFFFF", hex: "#e040fb" },
      a400: { textColor: "#FFFFFF", hex: "#d500f9" },
      a700: { textColor: "#FFFFFF", hex: "#aa00ff" }
  },
  "deep-purple": {
      "50": { textColor: "#000000", hex: "#ede7f6" },
      "100": { textColor: "#000000", hex: "#d1c4e9" },
      "200": { textColor: "#000000", hex: "#b39ddb" },
      "300": { textColor: "#FFFFFF", hex: "#9575cd" },
      "400": { textColor: "#FFFFFF", hex: "#7e57c2" },
      "500": { textColor: "#FFFFFF", hex: "#673ab7" },
      "600": { textColor: "#FFFFFF", hex: "#5e35b1" },
      "700": { textColor: "#FFFFFF", hex: "#512da8" },
      "800": { textColor: "#FFFFFF", hex: "#4527a0" },
      "900": { textColor: "#FFFFFF", hex: "#311b92" },
      a100: { textColor: "#000000", hex: "#b388ff" },
      a200: { textColor: "#FFFFFF", hex: "#7c4dff" },
      a400: { textColor: "#FFFFFF", hex: "#651fff" },
      a700: { textColor: "#FFFFFF", hex: "#6200ea" }
  },
  indigo: {
      "50": { textColor: "#000000", hex: "#e8eaf6" },
      "100": { textColor: "#000000", hex: "#c5cae9" },
      "200": { textColor: "#000000", hex: "#9fa8da" },
      "300": { textColor: "#FFFFFF", hex: "#7986cb" },
      "400": { textColor: "#FFFFFF", hex: "#5c6bc0" },
      "500": { textColor: "#FFFFFF", hex: "#3f51b5" },
      "600": { textColor: "#FFFFFF", hex: "#3949ab" },
      "700": { textColor: "#FFFFFF", hex: "#303f9f" },
      "800": { textColor: "#FFFFFF", hex: "#283593" },
      "900": { textColor: "#FFFFFF", hex: "#1a237e" },
      a100: { textColor: "#000000", hex: "#8c9eff" },
      a200: { textColor: "#FFFFFF", hex: "#536dfe" },
      a400: { textColor: "#FFFFFF", hex: "#3d5afe" },
      a700: { textColor: "#FFFFFF", hex: "#304ffe" }
  },
  blue: {
      "50": { textColor: "#000000", hex: "#e3f2fd" },
      "100": { textColor: "#000000", hex: "#bbdefb" },
      "200": { textColor: "#000000", hex: "#90caf9" },
      "300": { textColor: "#000000", hex: "#64b5f6" },
      "400": { textColor: "#000000", hex: "#42a5f5" },
      "500": { textColor: "#FFFFFF", hex: "#2196f3" },
      "600": { textColor: "#FFFFFF", hex: "#1e88e5" },
      "700": { textColor: "#FFFFFF", hex: "#1976d2" },
      "800": { textColor: "#FFFFFF", hex: "#1565c0" },
      "900": { textColor: "#FFFFFF", hex: "#0d47a1" },
      a100: { textColor: "#000000", hex: "#82b1ff" },
      a200: { textColor: "#FFFFFF", hex: "#448aff" },
      a400: { textColor: "#FFFFFF", hex: "#2979ff" },
      a700: { textColor: "#FFFFFF", hex: "#2962ff" }
  },
  "light-blue": {
      "50": { textColor: "#000000", hex: "#e1f5fe" },
      "100": { textColor: "#000000", hex: "#b3e5fc" },
      "200": { textColor: "#000000", hex: "#81d4fa" },
      "300": { textColor: "#000000", hex: "#4fc3f7" },
      "400": { textColor: "#000000", hex: "#29b6f6" },
      "500": { textColor: "#FFFFFF", hex: "#03a9f4" },
      "600": { textColor: "#FFFFFF", hex: "#039be5" },
      "700": { textColor: "#FFFFFF", hex: "#0288d1" },
      "800": { textColor: "#FFFFFF", hex: "#0277bd" },
      "900": { textColor: "#FFFFFF", hex: "#01579b" },
      a100: { textColor: "#000000", hex: "#80d8ff" },
      a200: { textColor: "#000000", hex: "#40c4ff" },
      a400: { textColor: "#000000", hex: "#00b0ff" },
      a700: { textColor: "#FFFFFF", hex: "#0091ea" }
  },
  cyan: {
      "50": { textColor: "#000000", hex: "#e0f7fa" },
      "100": { textColor: "#000000", hex: "#b2ebf2" },
      "200": { textColor: "#000000", hex: "#80deea" },
      "300": { textColor: "#000000", hex: "#4dd0e1" },
      "400": { textColor: "#000000", hex: "#26c6da" },
      "500": { textColor: "#FFFFFF", hex: "#00bcd4" },
      "600": { textColor: "#FFFFFF", hex: "#00acc1" },
      "700": { textColor: "#FFFFFF", hex: "#0097a7" },
      "800": { textColor: "#FFFFFF", hex: "#00838f" },
      "900": { textColor: "#FFFFFF", hex: "#006064" },
      a100: { textColor: "#000000", hex: "#84ffff" },
      a200: { textColor: "#000000", hex: "#18ffff" },
      a400: { textColor: "#000000", hex: "#00e5ff" },
      a700: { textColor: "#000000", hex: "#00b8d4" }
  },
  teal: {
      "50": { textColor: "#000000", hex: "#e0f2f1" },
      "100": { textColor: "#000000", hex: "#b2dfdb" },
      "200": { textColor: "#000000", hex: "#80cbc4" },
      "300": { textColor: "#000000", hex: "#4db6ac" },
      "400": { textColor: "#000000", hex: "#26a69a" },
      "500": { textColor: "#FFFFFF", hex: "#009688" },
      "600": { textColor: "#FFFFFF", hex: "#00897b" },
      "700": { textColor: "#FFFFFF", hex: "#00796b" },
      "800": { textColor: "#FFFFFF", hex: "#00695c" },
      "900": { textColor: "#FFFFFF", hex: "#004d40" },
      a100: { textColor: "#000000", hex: "#a7ffeb" },
      a200: { textColor: "#000000", hex: "#64ffda" },
      a400: { textColor: "#000000", hex: "#1de9b6" },
      a700: { textColor: "#000000", hex: "#00bfa5" }
  },
  green: {
      "50": { textColor: "#000000", hex: "#e8f5e9" },
      "100": { textColor: "#000000", hex: "#c8e6c9" },
      "200": { textColor: "#000000", hex: "#a5d6a7" },
      "300": { textColor: "#000000", hex: "#81c784" },
      "400": { textColor: "#000000", hex: "#66bb6a" },
      "500": { textColor: "#000000", hex: "#4caf50" },
      "600": { textColor: "#FFFFFF", hex: "#43a047" },
      "700": { textColor: "#FFFFFF", hex: "#388e3c" },
      "800": { textColor: "#FFFFFF", hex: "#2e7d32" },
      "900": { textColor: "#FFFFFF", hex: "#1b5e20" },
      a100: { textColor: "#000000", hex: "#b9f6ca" },
      a200: { textColor: "#000000", hex: "#69f0ae" },
      a400: { textColor: "#000000", hex: "#00e676" },
      a700: { textColor: "#000000", hex: "#00c853" }
  },
  "light-green": {
      "50": { textColor: "#000000", hex: "#f1f8e9" },
      "100": { textColor: "#000000", hex: "#dcedc8" },
      "200": { textColor: "#000000", hex: "#c5e1a5" },
      "300": { textColor: "#000000", hex: "#aed581" },
      "400": { textColor: "#000000", hex: "#9ccc65" },
      "500": { textColor: "#000000", hex: "#8bc34a" },
      "600": { textColor: "#000000", hex: "#7cb342" },
      "700": { textColor: "#FFFFFF", hex: "#689f38" },
      "800": { textColor: "#FFFFFF", hex: "#558b2f" },
      "900": { textColor: "#FFFFFF", hex: "#33691e" },
      a100: { textColor: "#000000", hex: "#ccff90" },
      a200: { textColor: "#000000", hex: "#b2ff59" },
      a400: { textColor: "#000000", hex: "#76ff03" },
      a700: { textColor: "#000000", hex: "#64dd17" }
  },
  lime: {
      "50": { textColor: "#000000", hex: "#f9fbe7" },
      "100": { textColor: "#000000", hex: "#f0f4c3" },
      "200": { textColor: "#000000", hex: "#e6ee9c" },
      "300": { textColor: "#000000", hex: "#dce775" },
      "400": { textColor: "#000000", hex: "#d4e157" },
      "500": { textColor: "#000000", hex: "#cddc39" },
      "600": { textColor: "#000000", hex: "#c0ca33" },
      "700": { textColor: "#000000", hex: "#afb42b" },
      "800": { textColor: "#000000", hex: "#9e9d24" },
      "900": { textColor: "#FFFFFF", hex: "#827717" },
      a100: { textColor: "#000000", hex: "#f4ff81" },
      a200: { textColor: "#000000", hex: "#eeff41" },
      a400: { textColor: "#000000", hex: "#c6ff00" },
      a700: { textColor: "#000000", hex: "#aeea00" }
  },
  yellow: {
      "50": { textColor: "#000000", hex: "#fffde7" },
      "100": { textColor: "#000000", hex: "#fff9c4" },
      "200": { textColor: "#000000", hex: "#fff59d" },
      "300": { textColor: "#000000", hex: "#fff176" },
      "400": { textColor: "#000000", hex: "#ffee58" },
      "500": { textColor: "#000000", hex: "#ffeb3b" },
      "600": { textColor: "#000000", hex: "#fdd835" },
      "700": { textColor: "#000000", hex: "#fbc02d" },
      "800": { textColor: "#000000", hex: "#f9a825" },
      "900": { textColor: "#000000", hex: "#f57f17" },
      a100: { textColor: "#000000", hex: "#ffff8d" },
      a200: { textColor: "#000000", hex: "#ffff00" },
      a400: { textColor: "#000000", hex: "#ffea00" },
      a700: { textColor: "#000000", hex: "#ffd600" }
  },
  amber: {
      "50": { textColor: "#000000", hex: "#fff8e1" },
      "100": { textColor: "#000000", hex: "#ffecb3" },
      "200": { textColor: "#000000", hex: "#ffe082" },
      "300": { textColor: "#000000", hex: "#ffd54f" },
      "400": { textColor: "#000000", hex: "#ffca28" },
      "500": { textColor: "#000000", hex: "#ffc107" },
      "600": { textColor: "#000000", hex: "#ffb300" },
      "700": { textColor: "#000000", hex: "#ffa000" },
      "800": { textColor: "#000000", hex: "#ff8f00" },
      "900": { textColor: "#000000", hex: "#ff6f00" },
      a100: { textColor: "#000000", hex: "#ffe57f" },
      a200: { textColor: "#000000", hex: "#ffd740" },
      a400: { textColor: "#000000", hex: "#ffc400" },
      a700: { textColor: "#000000", hex: "#ffab00" }
  },
  orange: {
      "50": { textColor: "#000000", hex: "#fff3e0" },
      "100": { textColor: "#000000", hex: "#ffe0b2" },
      "200": { textColor: "#000000", hex: "#ffcc80" },
      "300": { textColor: "#000000", hex: "#ffb74d" },
      "400": { textColor: "#000000", hex: "#ffa726" },
      "500": { textColor: "#000000", hex: "#ff9800" },
      "600": { textColor: "#000000", hex: "#fb8c00" },
      "700": { textColor: "#000000", hex: "#f57c00" },
      "800": { textColor: "#FFFFFF", hex: "#ef6c00" },
      "900": { textColor: "#FFFFFF", hex: "#e65100" },
      a100: { textColor: "#000000", hex: "#ffd180" },
      a200: { textColor: "#000000", hex: "#ffab40" },
      a400: { textColor: "#000000", hex: "#ff9100" },
      a700: { textColor: "#000000", hex: "#ff6d00" }
  },
  "deep-orange": {
      "50": { textColor: "#000000", hex: "#fbe9e7" },
      "100": { textColor: "#000000", hex: "#ffccbc" },
      "200": { textColor: "#000000", hex: "#ffab91" },
      "300": { textColor: "#000000", hex: "#ff8a65" },
      "400": { textColor: "#000000", hex: "#ff7043" },
      "500": { textColor: "#FFFFFF", hex: "#ff5722" },
      "600": { textColor: "#FFFFFF", hex: "#f4511e" },
      "700": { textColor: "#FFFFFF", hex: "#e64a19" },
      "800": { textColor: "#FFFFFF", hex: "#d84315" },
      "900": { textColor: "#FFFFFF", hex: "#bf360c" },
      a100: { textColor: "#000000", hex: "#ff9e80" },
      a200: { textColor: "#000000", hex: "#ff6e40" },
      a400: { textColor: "#FFFFFF", hex: "#ff3d00" },
      a700: { textColor: "#FFFFFF", hex: "#dd2c00" }
  },
  brown: {
      "50": { textColor: "#000000", hex: "#efebe9" },
      "100": { textColor: "#000000", hex: "#d7ccc8" },
      "200": { textColor: "#000000", hex: "#bcaaa4" },
      "300": { textColor: "#FFFFFF", hex: "#a1887f" },
      "400": { textColor: "#FFFFFF", hex: "#8d6e63" },
      "500": { textColor: "#FFFFFF", hex: "#795548" },
      "600": { textColor: "#FFFFFF", hex: "#6d4c41" },
      "700": { textColor: "#FFFFFF", hex: "#5d4037" },
      "800": { textColor: "#FFFFFF", hex: "#4e342e" },
      "900": { textColor: "#FFFFFF", hex: "#3e2723" }
  },
  grey: {
      "50": { textColor: "#000000", hex: "#fafafa" },
      "100": { textColor: "#000000", hex: "#f5f5f5" },
      "200": { textColor: "#000000", hex: "#eeeeee" },
      "300": { textColor: "#000000", hex: "#e0e0e0" },
      "400": { textColor: "#FFFFFF", hex: "#bdbdbd" },
      "500": { textColor: "#FFFFFF", hex: "#9e9e9e" },
      "600": { textColor: "#FFFFFF", hex: "#757575" },
      "700": { textColor: "#FFFFFF", hex: "#616161" },
      "800": { textColor: "#FFFFFF", hex: "#424242" },
      "900": { textColor: "#FFFFFF", hex: "#212121" }
  },
  "blue-grey": {
      "50": { textColor: "#000000", hex: "#eceff1" },
      "100": { textColor: "#000000", hex: "#cfd8dc" },
      "200": { textColor: "#000000", hex: "#b0bec5" },
      "300": { textColor: "#000000", hex: "#90a4ae" },
      "400": { textColor: "#000000", hex: "#78909c" },
      "500": { textColor: "#000000", hex: "#607d8b" },
      "600": { textColor: "#FFFFFF", hex: "#546e7a" },
      "700": { textColor: "#FFFFFF", hex: "#455a64" },
      "800": { textColor: "#FFFFFF", hex: "#37474f" },
      "900": { textColor: "#FFFFFF", hex: "#263238" }
  },
  white: {
      "500": { textColor: "#000000", hex: "#FFFFFF" }
  },
  black: {
      "500": { textColor: "#FFFFFF", hex: "#000000" }
  }
};

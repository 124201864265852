import _ from "lodash";
import React from "react";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";

import AttrsValuesContainer from "./AttrsValuesContainer";

const AttrsListValues = (props) => {
  return (
    <AttrsValuesContainer {...props}>
      {({ datasets, labels, query, setQuery }) => {
        return (
          <div className="card roe-shadow-2 fill-height">
            <div className="card-body">
              <div className="h4 card-title text-nowrap">
                <IntlMessages id={props.title} />
              </div>

              <table>
                <tbody>
                  {_.map(datasets, (dataset, index) => {
                    const color = dataset.color; // colors[index % colors.length];
                    return (
                      <tr key={index}>
                        <td className="pr-3 text-nowrap">
                          {/* <i className="fa fa-circle mr-3" style={{ color }} /> */}
                          {dataset.label}
                        </td>
                        <td>
                          {/* {JSON.stringify(dataset.data)} */}
                          {_.map(dataset.dataPoints, (data, index) => {
                            return data ? (
                              <div className="ml-2" key={index}>
                                {data.value}
                              </div>
                            ) : null;
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      }}
    </AttrsValuesContainer>
  );
};

export default AttrsListValues;

import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import Uploader from "./Uploader";
import AudioPreview from "./AudioPreview";

export const TimeSoundTracks = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-5">
              <div className="row">
                <div className="col-6">
                  <input
                    type="number"
                    name="hoursFrom"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "hoursFrom" })}
                    value={elem.hoursFrom || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: +target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    name="hoursTill"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "hoursTill" })}
                    value={elem.hoursTill || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { hoursTill: +target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                {/* 
                <div className="col-4">
                  <input
                    type="number"
                    name="volume"
                    min={1}
                    max={100}
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "volume" })}
                    value={elem.volume || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { volume: +target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                 */}
              </div>
            </div>

            <div className="col-6">
              {elem.fileId ? (
                <AudioPreview
                  file={{ _id: elem.fileId }}
                  deleteHandler={() => {
                    Object.assign(values[index], { fileId: null });
                    changeValues(values);
                  }}
                />
              ) : (
                <Uploader
                  className="btn-success btn-sm"
                  successHandler={([file]) => {
                    Object.assign(values[index], { fileId: file._id });
                    changeValues(values);
                  }}
                  accept="*.mp3"
                  multiple={false}
                />
              )}
            </div>
            <div className="col text-right">
              <TimeSoundTracks.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

TimeSoundTracks.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{}]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

TimeSoundTracks.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default TimeSoundTracks;

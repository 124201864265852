/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { AudioPreview, Pager, TableLoading } from "components/standart";

import { limit } from "variables";

import { apiUrls } from "../../init";
import AddAdvert from "./Add";
import AdvertForm from "./Form";
const { api } = adminAPI.actions;

const AdvertsTable = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();

  const [adverts, setAdverts] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setAdverts(body);
    };
    const onRequest = () => {
      setAdverts({ total: -1 });
    };

    query.limit && props.getAdverts({ params, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Adverts.name" />
          </th>
          <th>
            <IntlMessages id="Adverts.file" />
          </th>
          <th>
            <IntlMessages id="Adverts.url" />
          </th>
          <th className="text-right col-4">
            <AddAdvert refreshHandler={refreshHandler} />
          </th>
        </tr>
      </thead>
      {adverts.total < 0 && <TableLoading />}
      <tbody>
        {_.map(adverts.data, (advert, key) => {
          return (
            <tr key={key} className={!advert.enabled ? "text-strike" : ""}>
              <td>{advert.name}</td>
              <td>
                <AudioPreview file={{ _id: advert.fileId }} />
              </td>
              <td>{advert.url}</td>
              <td className="text-right">
                <AdvertForm {...{ advert, refreshHandler }} />
                {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={adverts.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getAdverts: api.get(apiUrls.adverts),
})(AdvertsTable);

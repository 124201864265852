import { apiUrls as parentUrls, infoLink as parentInfo } from "../init";

export const path = "threads";

export const apiUrls = {
  threads: `${parentUrls.info}/threads`,
  threadInfo: `${parentUrls.info}/threads/thread_{threadId}`,
  threadRuns: `${parentUrls.info}/threads/thread_{threadId}/runs`,
  threadRunInfo: `${parentUrls.info}/threads/thread_{threadId}/runs/run_{runId}`,
  threadRunCheck: `${parentUrls.info}/threads/thread_{threadId}/runs/run_{runId}/check`,
};

export const infoLink = (thread) => {
  return `${parentInfo({ _id: thread.assistantId })}/${path}?threadId=${thread._id}`;
};

export const formLink = (thread) => {
  return `${infoLink(thread)}/form`;
};

import _ from "lodash";
import React from "react";
import { CONTEXT_SEGMENTS } from "cloclo/constants";
import Finder from "./Finder";
import IntlMessages from "util/intlMessages";

const ContextSegments = ({ context_segments }) => {
  return (
    <>
      {_.map(context_segments, (value, index) => {
        return (
          <span className="mr-2" key={index}>
            <IntlMessages id={`context_segment.${value}`} />
          </span>
        );
      })}
    </>
  );
};

const Form = (props) => {
  return (
    <Finder.select
      name="context_segment"
      source={_.values(CONTEXT_SEGMENTS)}
      mask="context_segment"
      {...props}
    />
  );
};

const Switcher = (props) => {
  return (
    <>
      {_.map(CONTEXT_SEGMENTS, (value, index) => {
        const activeStyle = value === props.currentContextSegment ? "btn-dark" : "border-dark";
        return value ? (
          <span
            className={`btn mr-2 ${activeStyle}`}
            key={index}
            onClick={props.changeContextSegmentHandler(value)}
          >
            <IntlMessages id={`context_segment.${value}`} />
          </span>
        ) : null;
      })}
    </>
  );
};

Object.assign(ContextSegments, { Form, Switcher });

export default ContextSegments;

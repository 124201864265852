import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsPieChart from "components/stats/AttrsPieChart";
import AttrsLineChart from "components/stats/AttrsLineChart";
import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const AliasSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/alias")}
          dataAttr={"statsCount"}
          title="Общее количество прослушиваний"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/alias")}
          dataAttr={"dataCount"}
          title="Общее количество файлов"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/alias")}
          dataAttr={"statsCount"}
          title="Динамика прослушиваний"
          query={defaultQuery()}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/alias")}
          dataAttr={"dataCount"}
          title="Динамика количества файлов"
          query={defaultQuery()}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(AliasSummaryStats);

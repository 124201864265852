import { 
    file1, 
    file2, 
    file3, 
    file4, 
    file5, 
    file6, 
    file7, 
    file8,
    file9, 
    file10, 
    file11, 
    file12, 
    file13, 
    file14, 
    file15, 
    file16,
    file17, 
    file18,
} from 'helper/constant';

export const foldersData = [
    {
        id: 1,
        name: 'folder1',
        created: 1558545033
    },
    {
        id: 2,
        name: 'folder2',
        created: 1558462233
    },
    {
        id: 3,
        name: 'folder3',
        created: 1557943833
    },
    {
        id: 4,
        name: 'folder4',
        created: 1555956633
    },
    {
        id: 5,
        name: 'folder5',
        created: 1527012633
    }
]

export const mediasData = [
    {
        id: 1,
        file: file1,
        name: 'file1.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 2,
        file: file2,
        name: 'file2.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 3,
        file: file3,
        name: 'file3.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 4,
        file: file4,
        name: 'file4.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 5,
        file: file5,
        name: 'file5.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 6,
        file: file6,
        name: 'file6.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 7,
        file: file7,
        name: 'file7.png',
        folder_id: null,
        created: 1558545033
    },
    {
        id: 8,
        file: file8,
        name: 'file8.png',
        folder_id: 2,
        created: 1558545033
    },
    {
        id: 9,
        file: file9,
        name: 'file9.png',
        folder_id: 5,
        created: 1558545033
    },
    {
        id: 10,
        file: file10,
        name: 'file10.png',
        folder_id: 4,
        created: 1558545033
    },
    {
        id: 11,
        file: file11,
        name: 'file11.png',
        folder_id: 3,
        created: 1558545033
    },
    {
        id: 12,
        file: file12,
        name: 'menofhouse.png',
        folder_id: 2,
        created: 1558545033
    },
    {
        id: 13,
        file: file13,
        name: 'natural13.png',
        folder_id: 1,
        created: 1558545033
    },
    {
        id: 14,
        file: file14,
        name: 'beauty.jpg',
        folder_id: 5,
        created: 1558545033
    },
    {
        id: 15,
        file: file15,
        name: 'bitch.png',
        folder_id: 4,
        created: 1558545033
    },
    {
        id: 16,
        file: file16,
        name: 'cool.png',
        folder_id: 3,
        created: 1558545033
    },
    {
        id: 17,
        file: file17,
        name: 'sevenstart.png',
        folder_id: 2,
        created: 1558545033
    },
    {
        id: 18,
        file: file18,
        name: 'hello.png',
        folder_id: 1,
        created: 1558545033
    }
]
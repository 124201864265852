import _ from "lodash";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI } from "redux/api";
import IntlMessages from "util/intlMessages";

import AttrsSingleLineChart from "components/stats/AttrsSingleLineChart";
import AttrsBarChart from "components/stats/AttrsBarChart";
import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const SingleLinesStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/messages")}
          attrNameOnly="sessionsCount"
          title="Слушатели (чел)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/messages")}
          attrNameOnly="statsCount"
          title="Прослушивания (шт)"
          titleOnly={true}
          // title="Dashboard.Messages.Dynamic"
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/users/register_dynamic")}
          attrNameOnly="value"
          title="Регистрации (чел)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>


      <StatsComponent>
        <AttrsBarChart
          queryHandler={api.get("/messages/activity")}
          plainDataAttr="activityDaysCount"
          // attrNameOnly="sessionsCount"
          title={"RR (чел, дни)"}
          query={defaultQuery()}
        />
      </StatsComponent>
      
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/messages")}
          attrNameOnly="messagesPerListener"
          // title="Dashboard.Messages.Dynamic"
          query={defaultQuery()}
        />
      </StatsComponent>
   
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/messages/durationDynamic")}
          attrNameOnly="duration"
          // title="Dashboard.Messages.Dynamic"
          query={defaultQuery()}
        />
      </StatsComponent>


      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/channels/dynamic")}
          attrNameOnly="value"
          title="Каналы (шт)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/chars")}
          attrNameOnly="charsSum"
          title="Обработка (симв)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/chars")}
          attrNameOnly="charsCost"
          title="Обработка ($)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/chars")}
          attrNameOnly="countActions"
          title="Обработка (посты)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      {/* 
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/adverts")}
          attrNameOnly="statsCount"
          title="Реклама (шт)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>


      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/currencies/adverts")}
          dataAttr={"currencySum"}
          attrNameOnly={"currencyId"}
          title="Реклама (руб)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/users/premiums_dynamic")}
          attrNameOnly="value"
          title="Премиум (чел)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/currencies/payments")}
          dataAttr={"currencySum"}
          attrNameOnly={"currencyId"}
          title="Premium (руб)"
          titleOnly={true}
          query={defaultQuery()}
        />
      </StatsComponent>
       */}

      {/* 
      <StatsComponent>
        <AttrsListValues queryHandler={api.get("/messages")} title="Dashboard.Messages.Summary" />
      </StatsComponent>
      <StatsComponent>
        <AttrsListValues queryHandler={api.get("/adverts")} title="Dashboard.Adverts.Summary" />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/adverts")}
          title="Dashboard.Adverts.Dynamic"
          query={defaultQuery()}
        />
      </StatsComponent>
       */}
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(SingleLinesStats);

import { path as parentPath } from "../init";

export const path = "schedules";

export const apiUrls = {
  list: "/api/openai/schedules",
  positions: "/api/openai/schedules/positions",
  info: "/api/openai/schedules/schedule_{scheduleId}",
  start: "/api/openai/schedules/schedule_{scheduleId}/start",
};

export const infoLink = (schedule) => {
  return `${parentPath}/${path}/schedule_${schedule._id}`;
};

export const formLink = (schedule) => {
  return `${infoLink(schedule)}/form`;
};

import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import IntlMessages from "util/intlMessages";
import Select from "react-select";

const TelegramPublishFormatSelector = (props) => {
  const { telegramPublishFormats } = props.envStore;
  const { name = "telegramPublishFormat", setValue } = props;

  const options = () =>
    _.map(telegramPublishFormats, (value) => {
      return {
        value,
        label: <IntlMessages id={`telegramPublishFormat.${value}`} />,
      };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    const target = { name, value };
    setValue({ target });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      value={
        props.value
          ? {
              value: props.value,
              label: (
                <IntlMessages
                  id={`telegramPublishFormat.${props.value}`}
                />
              ),
            }
          : null
      }
      options={options()}
      // placeholder={<IntlMessages id={"telegramPublishFormat"} />}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(TelegramPublishFormatSelector);

import { path as parentPath } from "../init";

export const path = "translations";

export const apiUrls = {
  list: "/api/openai/translations",
  reload: "/api/openai/translations/reload",
  info: "/api/openai/translations/translation_{translationId}",
};

export const infoLink = (translation) => {
  return `${parentPath}/${path}/translation_${translation._id}`;
};

export const formLink = (translation) => {
  return `${infoLink(translation)}/form`;
};

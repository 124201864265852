import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import LoginData from "./LoginData";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddLogin = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userForm } = props;
  const [newLogin, setNewLogin] = useState({});
  // const changeUser = setOnChange(newUser, setNewUser);

  const addLoginHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.addLogin({ ...req, params }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setNewLogin({});
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Logins.Add"} />
          </ModalHeader>
          <ModalBody>
            <LoginData login={newLogin} setLogin={setNewLogin} saveHandler={addLoginHandler} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addLogin: api.post(apiUrls.logins),
})(AddLogin);

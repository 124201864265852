export const apiUrls = {
  list: "/api/specials",
  compilation: "/api/specials/compilation",
  info: "/api/specials/special_{specialId}",
  publish: "/api/specials/special_{specialId}/startPublish",
  delete: "/api/specials/special_{specialId}/delete",
  settings: "/api/specials/special_{specialId}/settings",
  getTrailer: "/api/specials/special_{specialId}/getTrailer",
  messages: "/api/specials/special_{specialId}/messages",
  messagesPositions: "/api/specials/special_{specialId}/messages/positions",
  messageInfo: "/api/specials/special_{specialId}/messages/message_{messageId}",
};

export const path = "/specials";

export const infoLink = (special) => {
  return `${path}/special_${special._id}`;
};

export const formLink = (special) => {
  return `${infoLink(special)}/form`;
};

/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";

import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, Pager, TableLoading, AudioPreview } from "components/standart";
import { FloatChannels } from "components/special/contents";
import { useIntl } from "react-intl";

import { limit } from "variables";

import { apiUrls, infoLink } from "./init";

const { api } = adminAPI.actions;

const ThreadRunsTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [data, setData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setData(body);
    };
    const onRequest = () => {
      setData({ total: -1 });
    };

    query.limit &&
      props.getThreadRuns({ params: { ...params, ...query }, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="OpenAI.Runs.openAIId" />
          </th>
          <th>
            <IntlMessages id="OpenAI.Runs.startDate" />
          </th>
          <th>
            <IntlMessages id="OpenAI.Runs.finishDate" />
          </th>
          {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
          <th className="text-right text-nowrap -col-4">
            {/* <Finder.search {...{ query, url }} className="mr-1" /> */}
          </th>
        </tr>
      </thead>
      {data.total < 0 && <TableLoading />}
      <tbody>
        {_.map(data.data, (value, key) => {
          return (
            <tr key={key}>
              <td>{value.openAIId}</td>

              <td>{value.startDate ? moment(value.startDate).format(dateTimeFormat) : null}</td>

              <td>{value.finishDate ? moment(value.finishDate).format(dateTimeFormat) : null}</td>

              <td className="text-right">
                {!value.finishDate ? <ThreadRunCheck {...params} {...value} /> : null}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={data.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const checkThreadRun = api.post(apiUrls.threadRunCheck);
const ThreadRunCheck = (props) => {
  const dispatch = useDispatch();
  const { assistantId, threadId, _id: runId } = props;
  const [isChecking, setChecking] = useState(false);
  const clickHandler = () => {
    const params = { assistantId, threadId, runId };
    dispatch(
      checkThreadRun(
        { params },
        {
          onSuccess() {
            //
            setChecking(false);
          },
          onRequest() {
            //
            setChecking(true);
          },
        }
      )
    );
  };
  return (
    <button className="btn btn-sm btn-info" onClick={clickHandler}>
      <i className={`fa fa-sync ${isChecking ? "fa-spin" : ""}`} />
    </button>
  );
};

export default connect(null, {
  getThreadRuns: api.get(apiUrls.threadRuns),
})(ThreadRunsTable);

import _ from "lodash";
import { applyUrlParams } from "./index";
import { accessToken } from "../init";

export const API_SOCKET = process.env.REACT_APP_API_SOCKET;
if (!API_SOCKET) throw "REACT_APP_API_SOCKET not defined";

const sockets = {
  connect(url, { params } = {}, { onmessage } = {}) {
    const ws = new WebSocket(
      API_SOCKET + applyUrlParams(url, params),
      _.toString(localStorage.getItem(accessToken)).replace(" ", "_") // в качестве протокола - токен пользователя
    );

    ws.onopen = function () {
      console.log("Socket is opened.", url);
    };

    onmessage && (ws.onmessage = onmessage);

    ws.onclose = function (e) {
      console.log("Socket is closed. Reconnect will be attempted in 1 second.", e.reason);
      setTimeout(function () {
        sockets.connect(url, { params }, { onmessage });
      }, 1000);
    };

    ws.onerror = function (err) {
      console.error("Socket encountered error: ", err.message, "Closing socket");
      ws.close();
    };

    return ws;
  },

  auto(ident, ...args) {
    const route = _.get(window.cloclo_routes, ident);
    if (route) {
      return sockets.connect(route.path, ...args);
    } else {
      throw `no route for: ${ident}`;
    }
  },
};

export default sockets;

/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { InputGroup, InputGroupAddon } from "reactstrap";
import CheckLogin from "../forms/CheckLogin";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { Finder } from "components/standart";
import { phoneNumber, FloatModal, setOnChange, AccessControl } from "components/special";

const { api } = adminAPI.actions;

const FloatCustomersModal = (props) => {
  const [validPhone, setValidPhone] = useState(null);
  const [newCustomer, setNewCustomer] = useState({});
  const changeNewCustomer = setOnChange(newCustomer, setNewCustomer);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  /*
  const fastAddCustomer = (searchCustomers) => {
    const body = { ...newCustomer, phone: validPhone };

    props.addCustomer(
      { body },
      {
        onSuccess() {
          setValidPhone(null);
          setDropdownOpen(false);
          searchCustomers({ ...body });
        },
      }
    );
  };
  */
  return (
    <FloatModal
      header={<IntlMessages id="Customers" />}
      title={(elem) => {
        const { name, surname } = elem;
        const fullname = [name, surname].filter(_.size).join(" ");
        const { phone } = _.keyBy(elem.logins, "type");
        return (
          <>
            {fullname ? fullname + " / " : null}
            {phoneNumber(phone.login)}
          </>
        );
      }}
      {...props}
      size="md"
      head={(searchCustomers, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Customers.phone" />
            </th>
            {/* 
						<th>
							<IntlMessages id="Customers.name">
								{(name) => (
									<Finder.input
										name="name"
										placeholder={name}
										query={searchQuery}
										setQuery={setSearchQuery}
									/>
								)}
							</IntlMessages>
            </th>
             */}
            <th style={{ width: "30px" }}>
              {/* 
              <Dropdown size="sm" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret className="c-primary">
                  <i className="fa fa-plus" />
                </DropdownToggle>
                <DropdownMenu right className="p-1">
                  <div className="font-weight-bold">
                    <IntlMessages id="Customers.Add" />
                  </div>
                  <div className="mt-2"></div>
                  <IntlMessages id="Customers.phone">
                    {(placeholder) => (
                      <CheckLogin
                        {...{
                          setValidPhone,
                          validPhone,
                        }}
                        placeholder={placeholder}
                        // className="form-control-sm"
                      />
                    )}
                  </IntlMessages>
                  <div className="mt-2"></div>
                  <IntlMessages id="Customers.name">
                    {(placeholder) => (
                      <input
                        type="text"
                        placeholder={placeholder}
                        name="name"
                        autoComplete="off"
                        className="form-control"
                        onChange={changeNewCustomer}
                      />
                    )}
                  </IntlMessages>
                  <div className="mt-2"></div>
                  <IntlMessages id="Customers.surname">
                    {(placeholder) => (
                      <input
                        type="text"
                        placeholder={placeholder}
                        name="surname"
                        autoComplete="off"
                        className="form-control"
                        onChange={changeNewCustomer}
                      />
                    )}
                  </IntlMessages>
                  <div className="mt-2"></div>
                  <button
                    className="btn c-primary"
                    disabled={!validPhone}
                    onClick={() => fastAddCustomer(searchCustomers)}
                  >
                    <IntlMessages id="save" />
                  </button>
                </DropdownMenu>
              </Dropdown>
               */}
            </th>
          </>
        );
      }}
      row={(customer, clickHandler) => (
        <>
          <td onClick={clickHandler}>
            {/* 
            {_.map(customer.logins, ({ login, type }, key) => {
              return type === "phone" ? <div key={key}>{login}</div> : null;
            })}
            */}
            <div>{customer.phone.value}</div>
          </td>
          {/* 
					<td onClick={clickHandler}>
						
          </td>
           */}
          <td></td>
        </>
      )}
    />
  );
};

const FloatCustomers = (props) => {
  return (
    <AccessControl id="Customers">
      <FloatCustomersModal {...props} />
    </AccessControl>
  );
};

export default connect(null, {
  getData: api.get("/api/customers"),
  // addCustomer: api.auto("Customers.Add"),
})(FloatCustomers);

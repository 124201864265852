import _ from "lodash";
import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";

import TransactionsTable from "./Table";
// import TransactionInfo from "./Info";

import { path } from "../init";

export const transactionInfo = (transaction) => {
  return `${path}/${Transactions.path}/transaction_${transaction._id}`;
};

export const transactionForm = (transaction) => {
  return `${transactionInfo(transaction)}/form`;
};

const Transactions = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Payments.Transactions" />,
    url,
    // actions: [<TransactionAdd {...{ transactionForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <TransactionsTable />
      </Route>

      {/* 
      <Route path={`${path}/transaction_:transaction_id`}>
        <TransactionInfo {...{ breadCrumbs }} />
      </Route>
      */}

      <Route component={Error400} />
    </Switch>
  );
};

Transactions.path = "transactions";

export default Transactions;

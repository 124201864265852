import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import ContentInfo from "./Info";

const PaymentsCurrencies = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const contentInfo = (currency) => {
    return `${url}/currency_${currency._id}`;
  };

  const contentForm = (currency) => {
    return `${contentInfo(currency)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Payments.Currencies" />,
    url,
    actions: [<AddContent {...{ contentForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable {...{ contentInfo, contentForm }} />
      </Route>

      <Route path={`${path}/currency_:currencyId`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default PaymentsCurrencies;

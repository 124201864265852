import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/intlMessages";

import { Alert } from "reactstrap";
import { loginBack, ForgotIcon } from "helper/constant";
import NumberFormat from "react-number-format";

import { authAPI, adminAPI, accessToken } from "redux/api";
import { phoneFormat, setOnChange } from "components/special";
const { auth } = authAPI.actions;

const ForgotPassword = (props) => {
  const [codeRequested, setCodeRequested] = useState(false);
  const { requestCode, validateCode } = props;
  //const { values, handleChange, handleBlur, errors, touched, submitCount } = props;
  const [values, setValues] = useState({ login: "", code: "" });
  const changeValues = setOnChange(values, setValues);
  // const [phone, setPhone] = useState(false);

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
  };

  // const [count, setCount] = useState(0);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const submitHandler = () => {
    //*
    const headers = props.authStore;

    const onFailure = ({ message }) => {
      setError(message);
    };
    if (codeRequested && values.code && values.login) {
      const onSuccess = (body) => {
        const { accessToken: token = "", type = "" } = { ...body };
        setError(null);
        if (token) {
          const onSuccess = ({ body }) => {
            // window.cloclo_routes = body.routes;
            dispatch(push("/"));
          };

          localStorage.setItem(accessToken, `${type} ${token}`);
          dispatch(adminAPI.actions.session.actions.getUser({}, { onSuccess }));
        }
      };

      validateCode({ body: values, headers }, { onSuccess, onFailure });
    }

    if (!codeRequested && values.login) {
      const onSuccess = () => {
        setCodeRequested(true);
        setError(null);
      };
      requestCode({ body: values, headers }, { onSuccess, onFailure });
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">
          <IntlMessages id="Auth.forgotPassword" />
        </div>
        <div className="text-center form-info-text plr-24 mt-16">
          <IntlMessages id="Auth.enterRestoreData" />
        </div>
        <div className="p-3">
          {error && (
            <Alert className="alert-danger" toggle={() => setError(null)}>
              <IntlMessages id={error} />
            </Alert>
          )}

          <div className="form-group">
            <NumberFormat
              name="login"
              format={phoneFormat}
              value={values.login}
              onChange={changeValues}
              className="form-control react-form-input"
              readOnly={!!codeRequested}
            />
          </div>
          {codeRequested && (
            <div className="form-group">
              <div>
                <IntlMessages id="Auth.confirmCode" />
              </div>
              <input
                type="text"
                name="code"
                value={values.code}
                onChange={changeValues}
                className="form-control react-form-input"
                autoComplete="one-time-code"
              />
            </div>
          )}
          <button type="button" className="btn form-button" onClick={submitHandler}>
            {codeRequested ? (
              <IntlMessages id="Auth.doConfirmCode" />
            ) : (
              <IntlMessages id="Auth.doRequestCode" />
            )}
          </button>
        </div>
        <div className="text-center pb-2">
          <Link to="/" className="link-label">
            <i className="fa fa-arrow-left mx-1" />
            <IntlMessages id="Auth.back" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authStore: state.auth.auth,
});
// /*
export default compose(connect(mapStateToProps, { ...auth.actions }))(ForgotPassword);
// */

// export default ForgotPassword;

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";
import IntlMessages from "util/intlMessages";

import AttrsPieChart from "components/stats/AttrsPieChart";
import AttrsLineChart from "components/stats/AttrsLineChart";
import AttrsListValues from "components/stats/AttrsListValues";
import { StatsContainer, StatsComponent } from "components/stats/init";

const { api } = statsAPI.actions;

const CharsSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsListValues
          queryHandler={api.get("/chars")}
          title={<IntlMessages id="Dashboard.Chars.Summary" />}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/categories/chars")}
          dataAttr={"charsSum"}
          title={<IntlMessages id="Dashboard.Categories.CharsSummary" />}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/chars")}
          title={<IntlMessages id="Dashboard.Chars.Dynamic" />}
          query={{ minDateTime: moment().subtract(10, "day").format(), baseTime: "toStartOfDay" }}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/categories/chars")}
          dataAttr={"charsSum"}
          title={<IntlMessages id="Dashboard.Categories.CharsDynamic" />}
          query={{ minDateTime: moment().subtract(10, "day").format(), baseTime: "toStartOfDay" }}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(CharsSummaryStats);

/** управление каналами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import qs from "query-string";
import { history } from "redux/store";

import AssistantThreadsTable from "./Table";
import ThreadRunsTable from "./Runs";
import { path } from "./init";

const AssistantThreads = (props) => {
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery({ ...qs.parse(history.location.search) });
  }, [history.location]);

  return (
    <div>
      {query.threadId ? <ThreadRunsTable {...props} /> : <AssistantThreadsTable {...props} />}
    </div>
  );
};
AssistantThreads.path = path;
export default AssistantThreads;

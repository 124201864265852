export const apiUrls = {
  list: "/api/advertisers",
  info: "/api/advertisers/advertiser_{advertiserId}",
  users: "/api/advertisers/advertiser_{advertiserId}/users",
  userInfo: "/api/advertisers/advertiser_{advertiserId}/users/id_{id}",
  adverts: "/api/advertisers/advertiser_{advertiserId}/adverts",
  advertIndex: "/api/advertisers/advertiser_{advertiserId}/adverts/advert_{advertId}",
  advertInfo: "/api/advertisers/advertiser_{advertiserId}/adverts/advert_{advertId}/info",
  advertStats: "/api/advertisers/advertiser_{advertiserId}/adverts/advert_{advertId}/stats",
  companies: "/api/advertisers/advertiser_{advertiserId}/companies",
  companyInfo: "/api/advertisers/advertiser_{advertiserId}/companies/company_{companyId}",
  companyStats: "/api/advertisers/advertiser_{advertiserId}/companies/company_{companyId}/stats",
  companyStart: "/api/advertisers/advertiser_{advertiserId}/companies/company_{companyId}/start",
  companyPause: "/api/advertisers/advertiser_{advertiserId}/companies/company_{companyId}/pause",
  companyResume: "/api/advertisers/advertiser_{advertiserId}/companies/company_{companyId}/resume",
  settings: "/api/advertisers/settings",
  intros: "/api/advertisers/intros",
  introInfo: "/api/advertisers/intros/intro_{introId}",


};

export const path = "/adverts";

export const infoLink = (advertiser) => {
  return `${path}/advertisers/advertiser_${advertiser._id}`;
};

export const formLink = (advertiser) => {
  return `${infoLink(advertiser)}/form`;
};

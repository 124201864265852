import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { Alert } from "reactstrap";
import { SwitchValue } from "components/special/forms";

import IntlMessages from "util/intlMessages";
import ChannelData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";
import { setOnChange } from "components/special";

const { api } = adminAPI.actions;

const ChannelSettings = (props) => {
  const params = useParams();
  const { breadCrumbs, channel, setChannel } = props;

  const [data, setData] = useState(null);
  const [dataSaved, setDataSaved] = useState(false);
  const [error, setError] = useState(false);
  const successAlertRef = useRef(null);

  const changeData = setOnChange(data, setData);

  useEffect(() => {
    channel &&
      props.getData(
        { params },
        {
          onSuccess(body) {
            setData(body);
          },
        }
      );
  }, [channel]);

  const saveHandler = (req, res) => {
    const onSuccess = (body) => {
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
      // props.refreshHandler();
      // toggleModal();
    };
    const onFailure = ({ message }) => {
      setError(message);
    };
    props.saveData({ body: data, params }, { onSuccess, onFailure });
  };

  const changeDataIndex = (name, index) => (event) => {
    const { value } = event.target;
    setData({ ...data, [name]: _.map(data[name], (e, _index) => (_index !== index ? e : value)) });
  };

  const deleteDataIndex = (name, index) => (event) => {
    setData({ ...data, [name]: _.filter(data[name], (e, _index) => _index !== index) });
  };

  const addDataIndex = (name) => (event) => {
    setData({ ...data, [name]: _.concat(data[name], [[""]]) });
  };

  return data ? (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                {error && (
                  <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                    <IntlMessages id={error} />
                  </Alert>
                )}

                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              <div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id="Channels.Settings.excludeStrings" />
                    <button
                      className="btn btn-sm btn-success pull-right"
                      onClick={addDataIndex("excludeStrings")}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  </label>
                  <div className="col-8">
                    {_.map(data.excludeStrings, (value, index) => {
                      return (
                        <div className="row mt-1 mb-1">
                          <div className="col-10">
                            <input
                              type="text"
                              value={value || ""}
                              onChange={changeDataIndex("excludeStrings", index)}
                              className="form-control react-form-input"
                              autoComplete="off"
                            />
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-sm btn-small btn-danger"
                              onClick={deleteDataIndex("excludeStrings", index)}
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id="Channels.Settings.stopWords" />
                    <button
                      className="btn btn-sm btn-success pull-right"
                      onClick={addDataIndex("stopWords")}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  </label>
                  <div className="col-8">
                    {_.map(data.stopWords, (value, index) => {
                      return (
                        <div className="row mt-1 mb-1">
                          <div className="col-10">
                            <input
                              type="text"
                              value={value || ""}
                              onChange={changeDataIndex("stopWords", index)}
                              className="form-control react-form-input"
                              autoComplete="off"
                            />
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-sm btn-small btn-danger"
                              onClick={deleteDataIndex("stopWords", index)}
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id="Channels.Settings.keepHashtags" />
                  </label>
                  <div className="col-8 my-auto">
                    <SwitchValue
                      value={data.keepHashtags}
                      name="keepHashtags"
                      onChange={changeData}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default connect(null, {
  getData: api.get(apiUrls.settings),
  saveData: api.post(apiUrls.settings),
})(ChannelSettings);

import _ from "lodash";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI } from "redux/api";
import IntlMessages from "util/intlMessages";

import AttrsLineChart from "components/stats/AttrsLineChart";
import AttrsListValues from "components/stats/AttrsListValues";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const MessagesSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsListValues
          queryHandler={api.get("/messages")}
          title={<IntlMessages id="Dashboard.Messages.Summary" />}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsListValues
          queryHandler={api.get("/adverts")}
          title={<IntlMessages id="Dashboard.Adverts.Summary" />}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/messages")}
          title={<IntlMessages id="Dashboard.Messages.Dynamic" />}
          query={defaultQuery()}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/adverts")}
          title={<IntlMessages id="Dashboard.Adverts.Dynamic" />}
          query={defaultQuery()}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(MessagesSummaryStats);

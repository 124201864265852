/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { AudioPreview } from "components/standart";
import { Pager, Finder, FilterButton, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const { contentInfo, contentForm } = props;

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(contentData.data, result.source.index, result.destination.index);

    const _id = _.map(newOrder, ({ _id }) => _id);

    props.savePositions(
      { body: { _id } },
      {
        onSuccess() {
          console.log(new Date(), "save priority success");
        },
      }
    );

    setContentData({ ...contentData, data: newOrder });
  };

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    query.limit && props.getContent({ query: { ...query, limit: 9999 } }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="text" />
            </th>
            <th>
              <IntlMessages id="file" />
            </th>

            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(contentData.data, (content, key) => (
            <tr key={key} className={`${!content.enabled ? "text-strike" : ""}`}>
              <td>{content.text}</td>
              <td>
                <AudioPreview file={{ _id: content.fileId }} />
              </td>

              <td>{moment(content.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ content, contentForm }} />
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.intros),
  // savePositions: api.post(apiUrls.premiumsPositions),
})(ContentTable);

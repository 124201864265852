/** страница типа транспорта
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useRouteMatch, Route, Redirect, Switch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";

import { adminAPI } from "redux/api";
import UserForm, { FormButton } from "./UserForm";
import UserInfoData from "./User";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const UserInfo = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();

  const { breadCrumbs } = props;
  const [user, setUser] = useState(null);

  const [notFound, setNotFound] = useState(false);

  if (user) {
    breadCrumbs.push({
      title: [user.login, user.profile?.name].filter(_.size).join(" / ") || user._id,
      url,
      actions: [<FormButton {...{ user }} />],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setUser(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getUser({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {user && (
        <Switch>
          <Route exact path={`${path}/form`}>
            <UserForm {...{ user, setUser, breadCrumbs }} />
          </Route>

          <Route path={path}>
            {/* <Redirect to={formLink(user)} /> */}
            <PageTitle {...breadCrumbs} />
            <UserInfoData {...{ user }} />
            {/* 
          
          
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="roe-card-style">
                  <div className="roe-card-header"></div>
                  <div className="roe-card-body">... user data</div>
                </div>
              </div>
            </div>
             */}
          </Route>
          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getUser: api.get(apiUrls.info),
})(UserInfo);

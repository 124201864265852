import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import DatePicker from "react-datepicker";

const ContentData = (props) => {
  const { content, setContent } = props;
  const format = "HH:mm";
  const intl = useIntl();

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="CastsTranslations.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="CastsTranslations.time" />
        </label>
        <div className="col-8">
          <DatePicker
            selected={content.time ? moment(content.time, format).toDate() : ""}
            onChange={(date) => {
              setContent({ ...content, time: moment(date).format(format) });
            }}
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={intl.formatMessage({ id: "time" })}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ContentData);

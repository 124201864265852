import { path as parentPath } from "../init";

export const apiUrls = {
  list: "/api/references/partners",
  info: "/api/references/partners/partner_{partnerId}",
  users: "/api/references/partners/partner_{partnerId}/users",
  userInfo: "/api/references/partners/partner_{partnerId}/users/id_{id}",
};

export const path = "partners";

export const infoLink = (partner) => {
  return `${parentPath}/${path}/partner_${partner._id}`;
};

export const formLink = (partner) => {
  return `${infoLink(partner)}/form`;
};

import _ from "lodash";
import React, { useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { setOnChange } from "components/special";
import { SwitchValue } from "components/special/forms";

const AdvertiserData = (props) => {
  const { advertiser, setAdvertiser, saveHandler, requestHandler = new Function() } = props;
  const changePartner = setOnChange(advertiser, setAdvertiser);
  const [error, setError] = useState(false);

  const saveAdvertiser = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: advertiser }, { onFailure, onRequest });
  };

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Partners.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={advertiser.name || ""}
            onChange={changePartner}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="tables.enabled" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={advertiser.enabled} onChange={changePartner} />
        </div>
      </div>

      <button
        type="button"
        disabled={!advertiser.name}
        onClick={saveAdvertiser}
        className="btn c-primary form-button"
      >
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

export default AdvertiserData;

import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import { connect } from "react-redux";
import { Alert } from "reactstrap";
import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { SoundMessage, SwitchValue } from "components/special/forms";
import { Uploader, ImagePreview } from "components/standart";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;
const Landings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const { envStore } = props;
  const [localeId, setLocaleId] = useState(envStore.locales[0]?._id);
  const [landingType, setLandingType] = useState(envStore.landingTypes[0]);

  const [landings, setLandings] = useState({});
  useEffect(() => {
    if (localeId && landingType) {
      props.getData(
        { query: { localeId, type: landingType } },
        {
          onRequest() {
            setLandings({});
            setDataSaved(false);
          },
          onSuccess(body) {
            setLandings(body);
          },
        }
      );
    }
  }, [localeId, landingType]);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Landing" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeLandings = setOnChange(landings, setLandings);

  const uploadFilesHandler =
    (file) =>
    ([fileData]) => {
      changeLandings({ target: { name: file, value: fileData._id } });
    };

  const saveSettingsHandler = () => {
    props.saveData(
      {
        query: { localeId, type: landingType },
        body: { ...landings },
      },
      {
        onSuccess(body) {
          setLandings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <Nav tabs style={{ cursor: "pointer" }}>
        {_.map(envStore.locales, (locale, index) => {
          return _.map(envStore.landingTypes, (type, index) => (
            <Fragment key={index}>
              <NavItem>
                <span
                  className={`nav-link ${
                    localeId === locale._id && landingType === type ? "active" : ""
                  }`}
                  onClick={() => {
                    setLocaleId(locale._id);
                    setLandingType(type);
                  }}
                >
                  {locale.name}: {type}
                </span>
              </NavItem>
            </Fragment>
          ));
        })}
      </Nav>

      {landings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                <h3>
                  <IntlMessages id={`Www.Landing.soundMessage`} />
                </h3>

                <SoundMessage data={landings} setData={setLandings} attrName="soundMessage" />

                {["intro", "block1", "block2", "block3", "block4", "block5", "extra"].map((name, index) => {
                  const title = `${name}.title`;
                  const content = `${name}.content`;
                  const file = `${name}.fileId`;
                  const isHtml = `${name}.isHtml`;
                  return (
                    <div key={index}>
                      <h3>
                        <IntlMessages id={`Www.Landing.${name}`} />
                      </h3>
                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Landing.title`} />
                        </label>
                        <div className="col-8">
                          <textarea
                            // type="text"
                            rows={2}
                            name={title}
                            value={_.get(landings, title) || ""}
                            onChange={changeLandings}
                            className="form-control react-form-input"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div
                        className="form-group row"
                        style={_.get(landings, isHtml) ? { height: 150 } : {}}
                      >
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Landing.content`} />
                        </label>
                        <div className="col-8">
                          {_.get(landings, isHtml) ? (
                            <ReactQuill
                              value={_.get(landings, content) || ""}
                              style={{ height: 100 }}
                              onChange={(value) => {
                                changeLandings({ target: { name: content, value } });
                              }}
                            />
                          ) : (
                            <textarea
                              name={content}
                              onChange={changeLandings}
                              value={_.get(landings, content) || ""}
                              rows={3}
                              className="form-control react-form-input"
                            />
                          )}
                        </div>
                      </div>

                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Landing.isHtml`} />
                        </label>
                        <div className="col-8  my-auto">
                          <SwitchValue
                            value={_.get(landings, isHtml)}
                            name={isHtml}
                            onChange={changeLandings}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          <IntlMessages id="Www.Landing.image" />
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(file)}
                            imagesOnly="true"
                            multiple={false}
                          />
                        </label>
                        <div className="col-8">
                          {_.get(landings, file) ? (
                            <ImagePreview
                              file={{ _id: _.get(landings, file) }}
                              deleteHandler={() => {
                                changeLandings({ target: { name: file, value: null } });
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* 
                <div>
                  <h3>
                    <IntlMessages id={`Www.Meta`} />
                  </h3>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Meta.title`} />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name={"metadata.title"}
                        value={_.get(landings, "metadata.title") || ""}
                        onChange={changeLandings}
                        className="form-control react-form-input"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Meta.description`} />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name={"metadata.description"}
                        value={_.get(landings, "metadata.description") || ""}
                        onChange={changeLandings}
                        className="form-control react-form-input"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Meta.keywords`} />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name={"metadata.keywords"}
                        value={_.get(landings, "metadata.keywords") || ""}
                        onChange={changeLandings}
                        className="form-control react-form-input"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Www.Meta.image" />
                      <Uploader
                        className="btn-success btn-sm pull-right"
                        successHandler={uploadFilesHandler("meta.fileId")}
                        imagesOnly="true"
                        multiple={false}
                      />
                    </label>
                    <div className="col-8">
                      {_.get(landings, "meta.fileId") ? (
                        <ImagePreview
                          file={{ _id: _.get(landings, "meta.fileId") }}
                          deleteHandler={() => {
                            changeLandings({ target: { name: "meta.fileId", value: null } });
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                */}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getData: api.get(apiUrls.landings),
  saveData: api.patch(apiUrls.landings),
})(Landings);

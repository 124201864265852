/** управление пользователями
 * в самом начале получает список пользователей на сервере
 * строит таблицу и позволяет открывать карточки пользователей
 * для просмотра и редактирования
 * в общем смысле возвращаемая структура данных также должна сообщить
 * о том, какие действия допустимы на странице
 * то есть текущему пользователю, выполняющему запрос, может быть недоступно
 * какое-либо из действий, что должно отразиться на интерфейсе
 * это будет добавлено чуть позже в виде структур access со связанными идентификаторами
 * модулей на бекенде
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import moment from "moment";
import { useIntl } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { FormButton } from "./Form";
import { AccessControl, phoneNumber } from "components/special";
import { Finder, Pager, Sorter, TableLoading } from "components/standart";
import { limit } from "variables";
import { apiUrls, infoLink } from "./init";

const { api } = adminAPI.actions;

export const ident = "Users";

const PartnersTable = (props) => {
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const [partners, setPartners] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setPartners(body);
    };
    const onRequest = () => {
      setPartners({ total: -1 });
    };

    query.limit && props.getPartners({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <AccessControl id={ident}>
      <Table responsive style={{ position: "relative" }}>
        <thead
          style={{
            position: "sticky",
            top: 0,
            boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
          }}
        >
          <tr>
            {/* 
            <th>
              <Finder className="mr-1 d-inline" isActive={query.login}>
                <Finder.input name="login" {...{ query, setQuery }} />
              </Finder>

              <IntlMessages id="Partners.login" />
              <Sorter query={query} url={url} order="login" />
            </th>
          */}
            <th>
              <Finder className="mr-1 d-inline" isActive={query.name}>
                <Finder.input name="name" {...{ query, setQuery }} />
              </Finder>

              <IntlMessages id="Partners.name" />
              <Sorter query={query} url={url} order="name" />
            </th>
            {/* 
            <th>
              <FloatAccess
                _id={query.access_id}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(access_id) => {
                  setQuery({ ...query, access_id });
                }}
              />
              <IntlMessages id="Partners.access" />
            </th>
               */}
            <th>
              <IntlMessages id="Partners.channelsCount" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }} />
            </th>
          </tr>
        </thead>
        {partners.total < 0 && <TableLoading />}
        <tbody>
          {_.map(partners.data, (partner, key) => {
            return (
              <tr key={key} className={!partner.enabled ? "text-strike" : ""}>
                {/* 
                <td>
                  <AccessControl id={userIdent} default={user.login}>
                    <Link to={userLink(user)}>{user.login}</Link>
                  </AccessControl>
                </td>
                  */}
                <td>
                  <Link to={infoLink(partner)}>{partner.name}</Link>

                  {/* <div>{phoneNumber(logins.phone?.login)}</div> */}
                </td>
                {/* 
                <td>
                  {_.map(user.accesses, (access, index) => (
                    <span className="mr-2" key={index}>
                      {access.title}
                    </span>
                  ))}
                </td>
                */}
                <td>{partner.channelsCount}</td>
                <td>{moment(partner.createdAt).format(dateTimeFormat)}</td>
                <td>
                  <FormButton {...partner} className="btn btn-sm btn-secondary" />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={partners.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </AccessControl>
  );
};

export default connect(null, {
  getPartners: api.get(apiUrls.list),
})(PartnersTable);

import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import DatePicker from "react-datepicker";
import { Alert } from "reactstrap";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { Uploader, ImagePreview, AudioPreview, Text2Mp3Button } from "components/standart";

const { api } = adminAPI.actions;

const ChannelMessageData = (props) => {
  const params = useParams();
  const { message, setMessage, saveHandler, requestHandler = new Function() } = props;
  const changeMessage = setOnChange(message, setMessage);

  const dateFormat = "dd.MM.yyyy HH:mm";
  const timeFormat = "HH:mm";

  const [error, setError] = useState(false);

  const saveMessage = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: { ...message } }, { onFailure, onRequest });
  };

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.ident" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="ident"
            value={message.ident || ""}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.date" />
        </label>
        <div className="col-8">
          <DatePicker
            selected={message.date ? new Date(message.date) : ""}
            dateFormat={dateFormat}
            showTimeSelect
            timeIntervals={10}
            timeFormat={timeFormat}
            popperPlacement="bottom-start"
            onChange={(value) =>
              setMessage({
                ...message,
                [`date`]: value ? value.toJSON() : null,
              })
            }
            className="form-control"
            calendarClassName="custom-calender-class"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="title"
            value={message.title || ""}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.score" />
        </label>
        <div className="col-8">
          <input
            type="number"
            name="score"
            min={1}
            max={10}
            step={1}
            value={message.score || ""}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.text" />
        </label>
        <div className="col">
          <textarea
            multiple
            name="text"
            rows={15}
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
            value={message.text || ""}
          />
        </div>

        <div className="col-1">
          <Text2Mp3Button
            value={message?.text}
            onSuccess={(file) => setMessage({ ...message, fileId: file._id, mp3: file.location })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Messages.mp3" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) =>
              setMessage({ ...message, fileId: file._id, mp3: file.location })
            }
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col">
          {message.fileId && (
            <AudioPreview
              file={{ _id: message.fileId }}
              deleteHandler={() => {
                setMessage({ ...message, fileId: null, mp3: null });
              }}
            />
          )}
        </div>
      </div>

      <button
        type="button"
        disabled={!message.ident}
        onClick={saveMessage}
        className="btn c-primary form-button"
      >
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  // checkChannel: api.post(apiUrls.channelsCheck),
})(ChannelMessageData);

/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { LocalesValues, PaymentsServicesTypesSelector } from "components/standart";
import { FloatReceiptsServices } from "components/special/payments";

const ContentData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="localesName" />

          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesName}
            changeValues={(localesName) => setContent({ ...content, localesName })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesName}
            changeValues={(localesName) => setContent({ ...content, localesName })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="type" />
        </label>
        <div className="col-8">
          <PaymentsServicesTypesSelector value={content.type} setValue={changeContent} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Payments.ReceiptsServices" />
        </label>
        <div className="col-8">
          <FloatReceiptsServices
            _id={content.receiptServiceId}
            multi={false}
            showTitle={true}
            showCount={false}
            className="btn-sm mr-1"
            successHandler={([receiptServiceId]) => setContent({ ...content, receiptServiceId })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="description" />
        </label>
        <div className="col-8">
          <textarea
            name="description"
            value={content.description || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ContentData);

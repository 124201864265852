/** управление данными для сайта
 */
import _ from "lodash";
import React, { useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import { BreadCrumbs } from "components/standart/PageTitle";

import OpenAIAssistants from "./Assistants";
import OpenAISchedules from "./Schedules";
import OpenAICompletions from "./Completions";
import CastsTranslations from "./Translations";
import GeneratedCasts from "./Casts";

import { path } from "./init";

const OpenAI = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="OpenAI" />,
    url,
  });
  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/${OpenAIAssistants.path}`}>
          <OpenAIAssistants {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${OpenAICompletions.path}`}>
          <OpenAICompletions {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${OpenAISchedules.path}`}>
          <OpenAISchedules {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${CastsTranslations.path}`}>
          <CastsTranslations {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${GeneratedCasts.path}`}>
          <GeneratedCasts {...{ breadCrumbs }} />
        </Route>

        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/${GeneratedCasts.path}`} />}
        />

        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: OpenAI };

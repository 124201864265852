/** страница типа транспорта
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useRouteMatch, Route, Redirect, Switch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";

import { adminAPI } from "redux/api";
import PartnerForm, { FormButton } from "./Form";
import PartnerInfoData from "./Partner";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const PartnerInfo = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();

  const { breadCrumbs } = props;
  const [partner, setPartner] = useState(null);

  const [notFound, setNotFound] = useState(false);

  if (partner) {
    breadCrumbs.push({
      title: [partner.name].filter(_.size).join(" / "),
      url,
      actions: [<FormButton {...partner} />],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setPartner(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getPartner({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {partner && (
        <Switch>
          <Route exact path={`${path}/form`}>
            <PartnerForm {...{ partner, setPartner, breadCrumbs }} />
          </Route>
          
          <Route path={path}>
            {/* <Redirect to={formLink(partner)} /> */}
            <PageTitle {...breadCrumbs} />
            <PartnerInfoData {...{ partner }} />
            {/* 
          
          
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="roe-card-style">
                  <div className="roe-card-header"></div>
                  <div className="roe-card-body">... partner data</div>
                </div>
              </div>
            </div>
             */}
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getPartner: api.get(apiUrls.info),
})(PartnerInfo);

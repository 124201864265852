import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { adminAPI } from "redux/api";
import { setOnChange } from "components/special";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const LoginPassword = (props) => {
  const params = useParams();
  const { login } = props;
  const [password, setPassword] = useState({});

  const changePassword = setOnChange(password, setPassword);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validPassword, setValidpassword] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const savePassword = () => {
    props.setPassword(
      { body: password, query: { loginId: login._id } },
      {
        onSuccess({ message }) {
          setSuccess(message);
          setPassword({});
        },
        onFailure({ message }) {
          setError(message);
        },
        onRequest() {
          setSuccess(false);
          setError(false);
        },
      }
    );
  };
  useEffect(() => {
    setValidpassword(
      password.password &&
        password.password.length >= 8 &&
        password.password === password.passwordConfirm
    );
  }, [password]);

  return (
    <>
      <button className="btn btn-sm btn-info ml-1" onClick={toggleModal}>
        <i className="fa fa-key" />
      </button>

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"settings.changePassword"} />
          </ModalHeader>
          <ModalBody>
            {success && (
              <Alert className="alert-success" isOpen="true" toggle={() => setSuccess(false)}>
                <IntlMessages id={success} />
              </Alert>
            )}

            {error && (
              <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                <IntlMessages id={error} />
              </Alert>
            )}

            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="settings.changePassword.newPassword" />
              </label>
              <div className="col-8">
                <input
                  type="password"
                  name="password"
                  value={password.password || ""}
                  onChange={changePassword}
                  className="form-control react-form-input"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="settings.changePassword.repeatPassword" />
              </label>
              <div className="col-8">
                <input
                  type="password"
                  name="passwordConfirm"
                  value={password.passwordConfirm || ""}
                  onChange={changePassword}
                  className="form-control react-form-input"
                  autoComplete="off"
                />
              </div>
            </div>

            <small className="text-danger my-1">
              {(password.password && password.password.length < 8) ||
              (password.passwordConfirm && password.passwordConfirm.length < 8) ? (
                <div>Пароль должен быть не менее 8 символов</div>
              ) : null}

              {(password.password || password.passwordConfirm) &&
              password.password !== password.passwordConfirm ? (
                <div>Пароли не совпадают</div>
              ) : null}
            </small>

            <button
              type="button"
              disabled={!validPassword}
              onClick={savePassword}
              className="btn c-primary form-button"
            >
              <IntlMessages id={"settings.changePassword.submit"} />
            </button>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  setPassword: api.post(apiUrls.changePassword),
})(LoginPassword);

import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { adminAPI } from "redux/api";

const { api } = adminAPI.actions;

const text2Mp3Request = api.post("/api/text2mp3");

const Text2Mp3Button = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const convertMp3Handler = () => {
    const confirm = window.confirm("Конвертировать текст в mp3?");
    if (!confirm) return false;
    dispatch(
      text2Mp3Request(
        { body: { text: props.value } },
        {
          onSuccess(body) {
            setLoading(false);
            props.onSuccess(body);
          },
          onRequest() {
            setLoading(true);
            setError(false);
            //
          },
          onFailure() {
            setLoading(false);
            setError(true);
            //
          },
        }
      )
    );
  };
  return (
    <button
      className={`btn btn-sm ${!error ? "btn-info" : "btn-danger"}`}
      disabled={loading}
      onClick={convertMp3Handler}
    >
      <i className={`fa ${!loading ? "fa-file-audio" : "fa-spinner fa-spin"}`} />
    </button>
  );
};
export default connect(null, {})(Text2Mp3Button);

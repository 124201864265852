/** полные reducer-ы для всех API вызовов
 * собираются из типовых вызовов метода `reducer()` соответствующих объектов
 */
// import auth from './auth'
import session from "./session";
import settings from "./settings";
import env from "./env";

import apiClass from "./init";

const api = new apiClass();

const modulesToReducers = (modules) => {
  const reducers = {};
  const middlewares = [];
  for (let ident in modules) {
    Object.assign(reducers, { [ident]: modules[ident].reducer() });
    middlewares.push(modules[ident].middleware());
  }

  return { reducers, middlewares };
};

const actions = { session, settings, api, env };
export default { actions, ...modulesToReducers(actions) };

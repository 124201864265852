import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { ContactsData, SoundMessage, SwitchValue, SubscribeData } from "components/special/forms";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { LocalesValues, Uploader, ImagePreview } from "components/standart";
import ReactQuill from "react-quill";
import { TimeSoundTracks, AudioPreview, StringArrayValues } from "components/standart";

import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { FloatAccounts } from "components/special/contents";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";
const { api } = adminAPI.actions;

const Settings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const [settings, setSettings] = useState({});
  useEffect(() => {
    props.getSettings(
      {},
      {
        onRequest() {
          setSettings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setSettings(body);
        },
      }
    );
  }, []);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Settings" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeSettings = setOnChange(settings, setSettings);

  // аттрибуты, которые необходимо отобразить
  const inputAttrs = [
    "email",
    "telegram",
    "versionNumber",
    "playlistText",
    "channelsDescription",
    "telegramChannel",
    "telegramBot",
    "telegramWebApp",
    "telegramBotNewUserButton",
  ];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [];
  const booleanAttrs = ["allowSelectCountry"];
  const htmlAttrs = [];
  const imagesAttrs = ["logoFileId", "defaultCoverFileId", "specialCoverFileId", "specialStoryFileId"];
  const textAttrs = [
    "telegramBotNewUserMessage",
    "telegramBotStartMessage",
    "favoritesDescription",
    "footerText",
  ];
  const numberAttrs = [];
  const soundFileAttrs = [];
  const listValueAttrs = [
    // { name: "backgroundSounds", component: TimeSoundTracks },
    // { name: "playlistVariants", component: StringArrayValues },
  ];
  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    { name: "aboutContacts", component: ContactsData },
    { name: "specialsBlock", component: SubscribeData },
    { name: "subscribeBlock", component: SubscribeData },
    { name: "liveStreamPage", component: SubscribeData },
    { name: "liveStreamBlock", component: SubscribeData },
    { name: "channelInfoBlock", component: SubscribeData },
    { name: "howItWorkBlock", component: SubscribeData },
    { name: "joinChannelBlock", component: SubscribeData },
    { name: "finalSound", component: SoundMessage },
  ];

  const saveSettingsHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.saveSettings(
      {
        body: { ...settings },
      },
      {
        onSuccess(body) {
          setSettings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  function successFloat(attr) {
    return ([value = null]) => {
      setSettings({ ...settings, [attr]: value });
    };
  }

  const uploadFilesHandler =
    (attrName) =>
    ([file]) => {
      setSettings({ ...settings, [attrName]: file._id });
    };

  const height = 100;

  return (
    <>
      <PageTitle {...breadCrumbs} />

      {settings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                {inputAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <input
                          type="text"
                          name={name}
                          value={settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                {textAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <textarea
                          rows={3}
                          name={name}
                          value={settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                {numberAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <input
                          name={name}
                          type="number"
                          min={0.1}
                          step={0.1}
                          value={settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    <IntlMessages id={`Www.Settings.telegramAccountId`} />
                  </label>
                  <div className="col-8">
                    <FloatAccounts
                      _id={settings.telegramAccountId}
                      multi={false}
                      showTitle={true}
                      showCount={false}
                      className="btn-sm"
                      successHandler={([telegramAccountId]) => {
                        setSettings({ ...settings, telegramAccountId });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    <IntlMessages id={`Www.Settings.telegramStoriesAccountId`} />
                  </label>
                  <div className="col-8">
                    <FloatAccounts
                      _id={settings.telegramStoriesAccountId}
                      multi={false}
                      showTitle={true}
                      showCount={false}
                      className="btn-sm"
                      successHandler={([telegramStoriesAccountId]) => {
                        setSettings({ ...settings, telegramStoriesAccountId });
                      }}
                    />
                  </div>
                </div>

                {localesAttrs.map((name, index) => {
                  return (
                    <div key={index} className="form-group row">
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                        <LocalesValues.AddButton
                          className="btn-sm float-right"
                          values={settings[name]}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </label>
                      <div className="col-8">
                        <LocalesValues
                          values={settings[name]}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </div>
                    </div>
                  );
                })}

                {htmlAttrs.map((name, index) => {
                  return (
                    <div key={index} className="form-group row" style={{ height: height + 70 }}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <ReactQuill
                          value={settings[name] || ""}
                          style={{ height }}
                          onChange={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </div>
                    </div>
                  );
                })}

                {booleanAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8  my-auto">
                        <SwitchValue value={settings[name]} name={name} onChange={changeSettings} />
                      </div>
                    </div>
                  );
                })}

                {imagesAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                        <div className="pull-right">
                          {!settings[name] ? (
                            <Uploader
                              className="btn-success btn-sm pull-right"
                              successHandler={uploadFilesHandler(name)}
                              imagesOnly="true"
                              multiple={false}
                            />
                          ) : null}
                        </div>
                      </label>
                      <div className="col-8 my-auto">
                        {settings[name] ? (
                          <ImagePreview
                            file={{ _id: settings[name] }}
                            className="rounded-circle"
                            deleteHandler={() => {
                              setSettings({ ...settings, [name]: null });
                            }}
                          />
                        ) : (
                          "Нет"
                        )}
                      </div>
                    </div>
                  );
                })}

                {soundFileAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8 my-auto">
                        {settings[name] ? (
                          <AudioPreview
                            file={{ _id: settings[name] }}
                            deleteHandler={() => {
                              setSettings({ ...settings, [name]: null });
                            }}
                          />
                        ) : (
                          "Нет"
                        )}

                        <div className="pull-right">
                          {!settings[name] ? (
                            <Uploader
                              className="btn-success btn-sm pull-right"
                              successHandler={uploadFilesHandler(name)}
                              accept="*.mp3"
                              multiple={false}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {listValueAttrs.map((elem, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-3 col-form-label">
                        <IntlMessages id={`Www.Settings.${elem.name}`} />
                        <elem.component.AddButton
                          className="btn-sm float-right"
                          values={settings[elem.name]}
                          changeValues={(values) =>
                            setSettings({ ...settings, [elem.name]: values })
                          }
                        />
                      </label>
                      <div className="col-8 my-auto">
                        <elem.component
                          values={settings[elem.name]}
                          changeValues={(values) =>
                            setSettings({ ...settings, [elem.name]: values })
                          }
                        />
                      </div>
                    </div>
                  );
                })}

                {componentAttrs.map((elem, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <h3>
                        <IntlMessages id={`Www.Settings.${elem.name}`} />
                      </h3>

                      <elem.component data={settings} setData={setSettings} attrName={elem.name} />
                      <hr />
                    </div>
                  );
                })}

                {/* <h3>
                  <IntlMessages id="Www.Settings.finalSound" />
                </h3>
                <SoundMessage data={settings} setData={setSettings} attrName="finalSound" />
                <hr /> */}
                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

export default connect(null, {
  getSettings: api.get(apiUrls.settings),
  saveSettings: api.patch(apiUrls.settings),
})(Settings);

import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    login: Yup.string().required("Auth.login-required"),
    password: Yup.string().required("Auth.password-required"),
  }),
  mapPropsToValues: (props) => ({
    login: "",
    password: "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;

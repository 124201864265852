import _ from "lodash";
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import FaqTable from "./Table";
import { PageTitle } from "components/standart";
import FaqAdd from "./Add";
import FaqInfo from "./Info";

const Faq = (props) => {
  const { url, path } = useRouteMatch();
  const { envStore, breadCrumbs } = props;
  const [localeId, setLocaleId] = useState(envStore.locales[0]?._id);

  const faqInfo = (faq) => {
    return `${url}/faq_${faq._id}`;
  };

  const faqForm = (faq) => {
    return `${faqInfo(faq)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Www.Faq" />,
    url,
    actions: [<FaqAdd {...{ faqForm, localeId }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <Nav tabs style={{ cursor: "pointer" }}>
          {_.map(envStore.locales, (locale, index) => {
            return (
              <Fragment key={index}>
                <NavItem>
                  <span
                    className={`nav-link ${localeId === locale._id ? "active" : ""}`}
                    onClick={() => setLocaleId(locale._id)}
                  >
                    {locale.name}
                  </span>
                </NavItem>
              </Fragment>
            );
          })}
        </Nav>
        <FaqTable {...{ faqInfo, faqForm, localeId }} />
      </Route>

      <Route path={`${path}/faq_:faq_id`}>
        <FaqInfo {...{ breadCrumbs, faqForm, faqInfo }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(Faq);

import _ from "lodash";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { adminAPI } from "redux/api";
// import { phoneFormat } from "components/cloclo";
import PhoneNumber from "./PhoneNumber";

const { api } = adminAPI.actions;
const CheckLogin = (props) => {
  const { validPhone, setValidPhone } = props;
  const checkPhoneNumber = (value, format) => {
    if (value.trim().length === format.length) {
      const type = "phone";
      const body = { type, login: value, ...props.body };

      props.checkLogin(
        { body },
        {
          onSuccess({ body }) {
            setValidPhone(body);
          },
        }
      );
    } else {
      setValidPhone(null);
    }
  };

  return (
    <PhoneNumber
      {...props}
      value={validPhone}
      className={validPhone ? "border-success" : "border-danger"}
      onChange={checkPhoneNumber}
    />
  );
  /*
  return (
    <NumberFormat
      type="text"
      format={phoneFormat}
      value={validPhone || phone || ""}
      placeholder={props.placeholder}
      className={`form-control ${
        validPhone ? "border-success" : "border-danger"
      } ${props.className}`}
      autoComplete="off"
      onChange={changePhoneNumber}
    />
  );
  */
};

// export default CheckLogin;

export default connect(
  null,
  {
    checkLogin: api.auto("Customers.CheckLogin"),
  },
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  })
)(CheckLogin);

/** управление данными для сайта
 */
import _ from "lodash";
import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import SoundsBackgrounds from "./Backgrounds";
import SoundsSettings from "./Settings";
import SoundsCompiler from "./Complier";

import { path } from "./init";
import { BreadCrumbs } from "components/standart/PageTitle";

const Sounds = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Sounds" />,
    url,
  });

  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/backgrounds`}>
          <SoundsBackgrounds {...{ breadCrumbs }} />
        </Route>

        <Route exact path={`${path}/settings`}>
          <SoundsSettings {...{ breadCrumbs }} />
        </Route>

        <Route exact path={`${path}/compiler`}>
          <SoundsCompiler {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${path}/settings`} />} />
        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Sounds };

import _ from "lodash";
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useParams, useRouteMatch, Route, Switch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import IntlMessages from "util/intlMessages";

import Info from "./Info";
import Adverts from "./Adverts";
import Companies from "./Companies";
import Users from "./Users";

const AdvertiserInfoData = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const { advertiser } = props;
  const NavTabs = [
    {
      path: "/companies",
      title: <IntlMessages id="Advertisers.Companies" />,
      component: <Companies />,
    },
    {
      path: "/adverts",
      title: <IntlMessages id="Advertisers.Adverts" />,
      component: <Adverts />,
    },
    {
      path: "/stats",
      title: <IntlMessages id="Advertisers.Stats" />,
      component: <Info {...advertiser} />,
    },
    // {
    //   path: "/accounts",
    //   title: <IntlMessages id="Advertisers.Users" />,
    //   component: <Users />,
    // },
  ];
  return (
    <>
      <Route path={path}>
        <div className="mb-1">
          <Nav tabs>
            {NavTabs.map((navTab, index) => {
              const link = url + _.toString(navTab.path);
              return (
                <Fragment key={index}>
                  <NavItem>
                    <span className={`nav-link ${currentTab === index ? "active" : ""}`}>
                      <Link to={link}>{navTab.title || <IntlMessages id={navTab.id} />}</Link>
                    </span>
                  </NavItem>
                </Fragment>
              );
            })}
          </Nav>
        </div>

        <Switch>
          {NavTabs.map((navTab, index) => {
            const link = path + _.toString(navTab.path);
            return (
              <Route
                key={index}
                exact
                path={link}
                render={() => {
                  setCurrentTab(index);
                  return <Fragment key={index}>{navTab.component}</Fragment>;
                }}
              />
            );
          })}
          <Route exact path={path} render={() => <Redirect to={`${url}${NavTabs[0].path}`} />} />
        </Switch>
      </Route>
    </>
  );
};

export default connect(null, {})(AdvertiserInfoData);

import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import ContentInfo from "./Info";

const Countries = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const contentInfo = (country) => {
    return `${url}/country_${country._id}`;
  };

  const contentForm = (country) => {
    return `${contentInfo(country)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Countries" />,
    url,
    actions: [<AddContent {...{ contentForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable {...{ contentInfo, contentForm }} />
      </Route>

      <Route path={`${path}/country_:countryId`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default Countries;

import React from "react";
import { lockscreenBack, AuthInProgressScreen } from "helper/constant";
import IntlMessages from "util/intlMessages";

const AuthInProgress = (props) => {
  const loginContainer = {
    backgroundImage: `url(${lockscreenBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon lock-screen-profile">
          <img src={AuthInProgressScreen} alt="icon" height="100px" />
        </div>
        <div className="login-title">
          <IntlMessages id="Auth.in-progress" />
        </div>
      </div>
    </div>
  );
};

export default AuthInProgress;

import apiClass from "./init";
import { accessToken, sessionToken } from "../init";

const session = new apiClass();

session.actions = {
  getUser: session.get("/api", {
    success: (state, result) => {
      return { ...result, authInProgress: false };
    },
    request: (state) => {
      return { ...state, authInProgress: true };
    },
  }),
  refreshInfo: session.get("/api", {
    success: (state, result) => {
      return { ...result };
    },
  }),
  logout: session.post("/logout", {
    request(state) {
      localStorage.removeItem(sessionToken);
      localStorage.removeItem(accessToken);
      return {};
    },
  }),
};

export default session;

import _ from "lodash";
import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";

import ReceiptsTable from "./Table";
// import ReceiptInfo from "./Info";

import { path } from "../init";

export const receiptInfo = (receipt) => {
  return `${path}/${Receipts.path}/receipt_${receipt._id}`;
};

export const receiptForm = (receipt) => {
  return `${receiptInfo(receipt)}/form`;
};

const Receipts = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Payments.Receipts" />,
    url,
    // actions: [<ReceiptAdd {...{ receiptForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <ReceiptsTable />
      </Route>

      {/* 
      <Route path={`${path}/receipt_:receipt_id`}>
        <ReceiptInfo {...{ breadCrumbs }} />
      </Route>
      */}

      <Route component={Error400} />
    </Switch>
  );
};

Receipts.path = "receipts";

export default Receipts;

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsBlockValues from "components/stats/AttrsBlockValues";
// import DynamicGroupLineChart from "../Components/DynamicGroupLineChart";
// import IntlMessages from "util/intlMessages";
import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const BlocksSummaryStats = (props) => {
  useEffect(() => {}, []);
  const params = useParams();

  return (
    <StatsContainer>

        <AttrsBlockValues
          queryHandler={api.get("/messages")}
          query={{ minDateTime: moment().subtract(1, "month").format(), ...params }}
        />
        <AttrsBlockValues
          queryHandler={api.get("/chars")}
          attrsOnly={["charsSum"]}
          query={params}
        />

        <AttrsBlockValues
          queryHandler={api.get("/channels/inFavorites")}
          attrsOnly={["usersCount"]}
          query={params}
        />

    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(BlocksSummaryStats);

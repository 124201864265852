import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { CurrenciesValues } from "components/standart";
import { SwitchValue } from "components/special/forms";
import { FloatCategories } from "components/special/contents";
import { setOnChange } from "components/special";
import { FloatAdverts } from "components/special/adverts";
import { adminAPI } from "redux/api";

const { api } = adminAPI.actions;

const CompanyData = (props) => {
  const params = useParams();
  const intl = useIntl();
  const dateFormat = "dd.MM.yyyy HH:mm";
  const timeFormat = "HH:mm";
  const { company, setCompany, saveHandler, requestHandler = new Function() } = props;
  const changeCompany = setOnChange(company, setCompany);

  const [error, setError] = useState(false);
  const [validCompany, setValidCompany] = useState(false);

  const saveCompany = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: { ...params, ...company } }, { onFailure, onRequest });
  };

  const timeout = useRef(null);

  // проверим, что логин доступн
  useEffect(() => {
    clearTimeout(timeout.current);
    setValidCompany(company.startDate && +company.totalLimit > 0);
  }, [company]);

  const addDefaultPrices = (categoryId) => {
    if (_.size(categoryId)) {
      props.getCategories(
        { query: { _id: categoryId } },
        {
          onSuccess(body) {
            const prices = _.flattenDeep([
              company.prices,
              _.map(body.data, (category) => category.prices).filter(_.size),
            ]).filter(Boolean);

            setCompany({ ...company, categoryId, prices });
          },
        }
      );
    }
  };
  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.advert" />
        </label>
        <div className="col-8">
          <FloatAdverts
            _id={company.advertId}
            params={params}
            multi={false}
            showTitle={true}
            title={(e) => e.name}
            showCount={false}
            className="btn-sm mr-1"
            successHandler={([advertId]) => setCompany({ ...company, advertId })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.category" />
        </label>
        <div className="col-8">
          <FloatCategories
            _id={company.categoryId}
            multi={false}
            showTitle={true}
            showCount={false}
            className="btn-sm"
            successHandler={([categoryId]) => {
              addDefaultPrices(categoryId);
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.prices" />

          <CurrenciesValues.AddButton
            className="btn-sm float-right"
            values={company.prices}
            changeValues={(prices) => setCompany({ ...company, prices })}
          />
        </label>
        <div className="col">
          <CurrenciesValues
            values={company.prices}
            changeValues={(prices) => setCompany({ ...company, prices })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.startDate" />
        </label>
        <div className="col-8">
          <DatePicker
            selected={company.startDate ? new Date(company.startDate) : ""}
            dateFormat={dateFormat}
            showTimeSelect
            timeIntervals={10}
            timeFormat={timeFormat}
            popperPlacement="bottom-start"
            onChange={(value) =>
              setCompany({
                ...company,
                [`startDate`]: value ? value.toJSON() : null,
              })
            }
            className="form-control"
            calendarClassName="custom-calender-class"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.endDate" />
        </label>
        <div className="col-8">
          <DatePicker
            selected={company.endDate ? new Date(company.endDate) : ""}
            dateFormat={dateFormat}
            showTimeSelect
            timeIntervals={10}
            timeFormat={timeFormat}
            popperPlacement="bottom-start"
            onChange={(value) =>
              setCompany({
                ...company,
                [`endDate`]: value ? value.toJSON() : null,
              })
            }
            className="form-control"
            calendarClassName="custom-calender-class"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.totalLimit" />
        </label>
        <div className="col-4">
          <input
            type="number"
            name="totalLimit"
            value={company.totalLimit || ""}
            onChange={changeCompany}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Companies.dayLimit" />
        </label>
        <div className="col-4">
          <input
            type="number"
            name="dayLimit"
            value={company.dayLimit || ""}
            onChange={changeCompany}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="tables.enabled" />
        </label>
        <div className="col-4 my-auto">
          <SwitchValue value={company.enabled} onChange={changeCompany} />
        </div>
      </div>

      <button
        type="button"
        disabled={!validCompany}
        onClick={saveCompany}
        className="btn c-primary form-button"
      >
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getCategories: api.get("/api/references/categories"),
})(CompanyData);

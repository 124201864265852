import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { SwitchValue } from "components/special/forms";

export const AssistantModifyAttrs = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <div className="row">
                <div className="col-4">
                  <input
                    type="string"
                    name="attrName"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "OpenAI.Assistants.ModifyAttrs.attrName",
                    })}
                    value={elem.attrName || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                <div className="col-4">
                  <input
                    type="string"
                    name="schemaName"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "OpenAI.Assistants.ModifyAttrs.schemaName",
                    })}
                    value={elem.schemaName || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                <div className="col-4">
                  <input
                    type="string"
                    name="schemaMethod"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "OpenAI.Assistants.ModifyAttrs.schemaMethod",
                    })}
                    value={elem.schemaMethod || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col text-right">
              <AssistantModifyAttrs.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

AssistantModifyAttrs.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{}]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

AssistantModifyAttrs.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default AssistantModifyAttrs;

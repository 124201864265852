import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

import { phoneFormat } from "components/special";

const PhoneNumber = (props) => {
  const [phone, setPhone] = useState(props.value);

  const changePhoneNumber = (event) => {
    const { target } = event;
    let { value = "" } = target;

    // проверка первого символа, с заменой 8 на 7
    value = value
      ? value[0] === "7"
        ? value
        : "7" + (value[0] === "8" ? value.substr(1) : value)
      : "";

    setPhone(value);
    if (props.onChange) {
      props.onChange(value, phoneFormat);
    }
  };

  return (
    <NumberFormat
      type="text"
      format={phoneFormat}
      value={props.value || phone || ""}
      placeholder={props.placeholder}
      className={`form-control ${props.className}`}
      autoComplete="off"
      onChange={changePhoneNumber}
    />
  );
};

export default PhoneNumber;

/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";

import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Pager, TableLoading } from "components/standart";

import { useIntl } from "react-intl";

import { limit } from "variables";

import { apiUrls, infoLink } from "./init";

const { api } = adminAPI.actions;

const AssistantThreadsTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [data, setData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setData(body);
    };
    const onRequest = () => {
      setData({ total: -1 });
    };

    query.limit && props.getThreads({ params, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="OpenAI.Threads.openAIId" />
          </th>
          <th>
            <IntlMessages id="OpenAI.Threads.category" />
          </th>
          <th>
            <IntlMessages id="OpenAI.Threads.lastMessageDate" />
          </th>
          <th>
            <IntlMessages id="OpenAI.Threads.lastRunDate" />
          </th>
          {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
          <th className="text-right text-nowrap -col-4">
            {/* <Finder.search {...{ query, url }} className="mr-1" /> */}
          </th>
        </tr>
      </thead>
      {data.total < 0 && <TableLoading />}
      <tbody>
        {_.map(data.data, (value, key) => {
          return (
            <tr key={key}>
              <td>
                <Link to={infoLink(value)}>{value.openAIId}</Link>
              </td>
              <td>{value.category?.name}</td>

              <td>
                {value.lastMessageDate
                  ? moment(value.lastMessageDate).format(dateTimeFormat)
                  : null}
              </td>

              <td>{value.lastRunDate ? moment(value.lastRunDate).format(dateTimeFormat) : null}</td>

              <td className="text-right"></td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={data.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getThreads: api.get(apiUrls.threads),
})(AssistantThreadsTable);

import _ from "lodash";
import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";

export const TimeArrayValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  const format = "HH:mm";
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <DatePicker
                selected={elem ? moment(elem, format).toDate() : ""}
                onChange={(date) => {
                  values[index] = moment(date).format(format);
                  changeValues(values);
                }}
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption={intl.formatMessage({ id: "time" })}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
              />
            </div>

            <div className="col text-right">
              <TimeArrayValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

TimeArrayValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([""]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

TimeArrayValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default TimeArrayValues;

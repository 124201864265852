import _ from "lodash";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import IntlMessages from "util/intlMessages";

export default (props) => {
  const { query, setQuery, name } = props;
  const id = _.snakeCase(props.id);
  const icon = !query[name] ? props.on : props.off;
  const style = !query[name] ? "secondary" : "info";
  const actionHandler = () =>
    setQuery({ ...query, [name]: query[name] ? undefined : true });
  return (
    <>
      <button
        id={id}
        className={`btn btn-sm btn-${style} ${props.className}`}
        onClick={actionHandler}
      >
        <i className={`fa fa-${icon}`} />
      </button>
      <UncontrolledTooltip placement="top" target={id}>
        <IntlMessages id={props.id} />
      </UncontrolledTooltip>
    </>
  );
};

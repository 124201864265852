import _ from "lodash";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { statsAPI } from "redux/api";

import AttrsSingleLineChart from "components/stats/AttrsSingleLineChart";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const SingleLinesStats = (props) => {
  useEffect(() => {}, []);
  const params = useParams();

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/adverts")}
          attrNameOnly="sessionsCount"
          query={{ ...props.query, ...defaultQuery(), ...params }}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/adverts")}
          attrNameOnly="statsCount"
          query={{ ...props.query, ...defaultQuery(), ...params }}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsSingleLineChart
          queryHandler={api.get("/adverts")}
          attrNameOnly="currencySum"
          query={{ ...props.query, ...defaultQuery(), ...params }}
        />
      </StatsComponent>
    </StatsContainer>
  );
};

export default connect(null, {})(SingleLinesStats);

import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { adminAPI } from "redux/api";

import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const EmailsButton = (props) => {
  const [isLoading, setLoading] = useState(false);
  const getEmailsHandler = () => {
    props.getEmails(
      {},
      {
        onSuccess(result) {
          setLoading(false);
          const header = ["index", "email"];
          const fileData = [
            header.join(","),
            ...result.map((email, index) => {
              return [index + 1, email].join(",");
            }),
          ].join("\n");
          const blob = new Blob([fileData], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = "emails.csv";
          link.href = url;
          link.click();
        },
        onRequest() {
          setLoading(true);
        },
      }
    );
  };

  return (
    <>
      <button className="btn btn-sm btn-info mx-1" onClick={getEmailsHandler}>
        <i className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-file-csv"}`} />
      </button>
    </>
  );
};

export default connect(null, {
  getEmails: api.get(apiUrls.emails),
})(EmailsButton);

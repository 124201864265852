import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import IntlMessages from "util/intlMessages";
import CompanyData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../../init";

const { api } = adminAPI.actions;

const CompanyForm = (props) => {
  const params = useParams();
  const [company, setCompany] = useState(props.company);
  const [companyId, setCompanyId] = useState(props.company._id);
  useEffect(() => {
    company && setCompanyId(company._id);
  }, [company]);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.saveData({ ...req, params: { ...params, companyId } }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setCompany(props.company);
  }, [openModal]);

  const startHandler = (e) => {
    e.preventDefault();
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Запустить кампанию?");
    if (isConfirmed) {
      props.startCompany({ params: { ...params, companyId } }, res);
    }
  };

  const pauseHandler = (e) => {
    e.preventDefault();
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Остановить кампанию?");
    if (isConfirmed) {
      props.pauseCompany({ params: { ...params, companyId } }, res);
    }
  };

  const resumeHandler = (e) => {
    e.preventDefault();
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Продолжить кампанию?");
    if (isConfirmed) {
      props.resumeCompany({ params: { ...params, companyId } }, res);
    }
  };

  return (
    <div className="text-nowrap">
      {company.status === "new" ? (
        <>
          <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
            <i className="fa fa-edit" />
          </button>
          <button className="btn btn-sm btn-success ml-1" onClick={startHandler}>
            <i className="fa fa-play" />
          </button>
        </>
      ) : null}

      {company.status === "in_progress" ? (
        <>
          <button className="btn btn-sm btn-warning ml-1" onClick={pauseHandler}>
            <i className="fa fa-pause" />
          </button>
        </>
      ) : null}

      {company.status === "paused" ? (
        <>
          <button className="btn btn-sm btn-warning ml-1" onClick={resumeHandler}>
            <i className="fa fa-play" />
          </button>
        </>
      ) : null}

      {openModal && (
        <Modal isOpen={openModal} centered size="xl" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"edit"} />
          </ModalHeader>
          <ModalBody>
            <CompanyData {...{ company, setCompany, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  saveData: api.put(apiUrls.companyInfo),
  removeData: api.delete(apiUrls.companyInfo),
  startCompany: api.post(apiUrls.companyStart),
  pauseCompany: api.post(apiUrls.companyPause),
  resumeCompany: api.post(apiUrls.companyResume),
})(CompanyForm);

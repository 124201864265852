import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";

const InjectMessage = (props) => (
  <FormattedMessage {...props} defaultMessage={props.id} />
);

export default injectIntl(InjectMessage, {
  withRef: false,
});

/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { onEnter, setOnChange } from "components/special";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatPaymentsServicesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Payments.PaymentsServices" />}
      {...props}
      head={(searchServices, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="name" />
            </th>
            <th>
              <IntlMessages id="type" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(service, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!service.enabled ? "text-strike" : ""}>
            {service.name}
          </td>
          <td>
            <IntlMessages id={`PaymentsServicesTypes.${service.type}`} />
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatPaymentsServices = (props) => {
  return <FloatPaymentsServicesModal {...props} />;
};

export default connect(null, {
  getData: api.get("/api/payments/services"),
})(FloatPaymentsServices);

import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { connect } from "react-redux";
import { Alert } from "reactstrap";

import IntlMessages from "util/intlMessages";
import { AudioPreview, Uploader, ImagePreview } from "components/standart";

import { PageTitle, TTSModelSelector } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;

const SoundsCompiler = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;

  const [compiler, setCompiler] = useState({});
  const [isLoading, setLoading] = useState(false);

  breadCrumbs.push({
    title: <IntlMessages id="Sounds.Compiler" />,
    url,
  });

  const [dataSent, setDataSent] = useState(false);
  const [dataError, setDataError] = useState(false);

  const successAlertRef = useRef(null);

  const changeCompiler = setOnChange(compiler, setCompiler);

  const doCompileHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.doCompile(
      {
        body: { ...compiler },
      },
      {
        onSuccess(body) {
          setDataSent(true);
          successAlertRef.current.scrollIntoView();
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
          setDataSent(false);
          setDataError(false);
        },
        onFailure() {
          setLoading(false);
          setDataError(true);
        },
      }
    );
  };

  const inputAttrs = ["author", "title"];
  const soundFileAttrs = ["mp3"];
  const imageFileAttrs = ["image"];

  function uploadFilesHandler(attrName) {
    return ([file]) => {
      setCompiler({ ...compiler, [attrName]: file._id });
    };
  }

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12 -col-lg-6">
          <div className="roe-card-style">
            <div className="roe-card-header"></div>
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSent}
                  toggle={() => setDataSent(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
                <Alert
                  className="alert-danger"
                  isOpen={dataError}
                  toggle={() => setDataError(false)}
                >
                  <IntlMessages id="error" />
                </Alert>
              </div>

              {soundFileAttrs.map((name, index) => {
                return (
                  <div className="form-group row" key={index}>
                    <div className="col-3 col-form-label">
                      <IntlMessages id={`Sounds.Compiler.${name}`} />

                      <div className="pull-right">
                        {!compiler[name] ? (
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(name)}
                            accept="*.mp3"
                            multiple={false}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-8 my-auto">
                      {compiler[name] ? (
                        <AudioPreview
                          file={{ _id: compiler[name] }}
                          deleteHandler={() => {
                            setCompiler({ ...compiler, [name]: null });
                          }}
                        />
                      ) : (
                        "Нет"
                      )}
                    </div>
                  </div>
                );
              })}

              {imageFileAttrs.map((name, index) => {
                return (
                  <div className="form-group row" key={index}>
                    <div className="col-3 col-form-label">
                      <IntlMessages id={`Sounds.Compiler.${name}`} />

                      <div className="pull-right">
                        {!compiler[name] ? (
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(name)}
                            imagesOnly="true"
                            multiple={false}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-8 my-auto">
                      {compiler[name] ? (
                        <ImagePreview
                          file={{ _id: compiler[name] }}
                          deleteHandler={() => {
                            setCompiler({ ...compiler, [name]: null });
                          }}
                        />
                      ) : (
                        "Нет"
                      )}
                    </div>
                  </div>
                );
              })}

              {inputAttrs.map((name, index) => {
                return (
                  <div className="form-group row" key={index}>
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Sounds.Compiler.${name}`} />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name={name}
                        value={compiler[name] || ""}
                        onChange={changeCompiler}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                );
              })}

              <div className="form-group row">
                <div className="col-6">
                  <button
                    type="button"
                    className="form-control c-primary"
                    onClick={doCompileHandler}
                  >
                    {isLoading ? <LoadingIcon /> : <IntlMessages id="Sounds.Compiler.submit" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  doCompile: api.post(apiUrls.compiler),
})(SoundsCompiler);

/** полные reducer-ы для всех API вызовов
 * собираются из типовых вызовов метода `reducer()` соответствующих объектов
 */

import apiClass from "./init";

const api = new apiClass();

const modulesToReducers = (modules) => {
  const reducers = {};
  const middlewares = [];
  for (let ident in modules) {
    Object.assign(reducers, { [ident]: modules[ident].reducer() });
    middlewares.push(modules[ident].middleware());
  }

  return { reducers, middlewares };
};

const actions = { api };
export default { actions, ...modulesToReducers(actions) };

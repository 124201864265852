import React from "react";
import { Progress } from "reactstrap";

const Example = () => {
    return (
        <div className="progress-block">
            <div className="text-center">0%</div>
            <Progress />
            <div className="text-center">25%</div>
            <Progress value="25" />
            <div className="text-center">50%</div>
            <Progress value={50} />
            <div className="text-center">75%</div>
            <Progress value={75} />
            <div className="text-center">100%</div>
            <Progress value="100" />
            <div className="text-center">Multiple bars</div>
            <Progress multi>
                <Progress bar value="15" />
                <Progress bar className="c-success" value="30" />
                <Progress bar className="c-info" value="25" />
                <Progress bar className="c-warning" value="20" />
                <Progress bar className="c-danger" value="5" />
            </Progress>
        </div>
    );
};

export default Example;

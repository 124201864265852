import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import Select from "react-select";

const SpecialsStatusesSelector = (props) => {
  const { specialsStatuses } = props.envStore;
  const { name = "status", setValue } = props;
  const intl = useIntl();
  const prefix = "Specials.status";
  const makeLabel = (value) => intl.formatMessage({ id: `${prefix}.${value}` });
  const options = () =>
    _.map(specialsStatuses, (value) => {
      return { value, label: makeLabel(value) };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    setValue({ [name]: value });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      value={props.value ? { value: props.value, label: makeLabel(props.value) } : null}
      options={options()}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(SpecialsStatusesSelector);

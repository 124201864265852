/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatAdvertsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Adverts" />}
      {...props}
      head={(searchCountry, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Adverts.name" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(advert, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!advert.enabled ? "text-strike" : ""}>
            {advert.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatAdverts = (props) => {
  return <FloatAdvertsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/advertisers/advertiser_{advertiserId}/adverts"),
})(FloatAdverts);

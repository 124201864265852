import React from "react";
import dot from "dot-object";
import _ from "lodash";
import AccessControl from "./AccessControl";
import FloatModal from "./FloatModal";
import NumberFormat from "react-number-format";
import moment from "moment";
import qs from "query-string";

export const onEnter =
  (successHandler, ...args) =>
  (event) => {
    if (event.key === "Enter") successHandler(...args, event);
  };

export const setOnChange = (data, setData) => (event) => {
  const { target } = event;
  const { value, name, type } = target;
  const isCheckbox = type === "checkbox" || type === "radio";
  const set = { [name]: !isCheckbox ? value : target.checked && value };
  // const set = { [name]: value };
  const result = { ...dot.dot(data), ...set };
  setData(dot.object(result));
};

export const phoneFormat = "# (###) ###-####";

export const phoneNumber = (value) => {
  return <NumberFormat displayType="text" format={phoneFormat} value={value} />;
};

// сгенерировать имя клиента
export const customerName = (customer) =>
  [_.get(customer, "name"), _.get(customer, "surname")].join(" ");

export const priceValue = (value, symbol) => {
  return (
    <NumberFormat
      thousandSeparator={" "}
      decimalSeparator={"."}
      suffix={symbol ? " " + symbol : ""}
      decimalScale={2}
      allowNegative={false}
      displayType="text"
      value={value || 0}
    />
  );
};

// типовая визуализация цены
export const productPriceData = (product) => {
  const { price, origin_price, reward } = product;
  const profit = price - reward;
  return (
    <>
      <div className="text-nowrap">
        <span className="mr-2">{priceValue(price)}</span>
        {origin_price !== price && (
          <small className="text-strike">{priceValue(origin_price)}</small>
        )}
      </div>
      <div className="text-nowrap">
        <small>
          <span className="mr-2 text-danger">-{priceValue(reward)}</span>
          {profit > 0 ? (
            <span className="text-success">+{priceValue(profit)}</span>
          ) : (
            <span className="text-danger">{priceValue(profit)}</span>
          )}
        </small>
      </div>
    </>
  );
};

// генератор типовой функции для проверки статусов требуемому значению
export const checkStatus =
  (...statuses) =>
  (doc, attr = "status") =>
    _.indexOf(statuses, _.get(doc, attr)) >= 0;

export const digitsOnly = (event) => {
  const systemKeys = [8, 9, 46, 37, 39];
  /[0-9]/.test(event.key) || _.indexOf(systemKeys, event.keyCode) >= 0 || event.preventDefault();
};

export { AccessControl, FloatModal };

export const lifetime = (product) => {
  let lifetime;

  if (product.rejectDate || product.declineDate) {
    // время жизни с момента отказа
    lifetime = moment(product.rejectDate || product.declineDate) //
      .diff(product.stockDate || product.createdAt, "days");
  } else if (product.saleDate) {
    // если продано, то считаем, сколько было в продаже
    lifetime = moment(product.saleDate).diff(product.stockDate, "days");
  } else if (product.stockDate) {
    // если в продаже, то считаем от даты выхода на продажу
    lifetime = moment(new Date()).diff(product.stockDate, "days");
  } else {
    // иначе просто время от момент создания
    lifetime = moment(new Date()).diff(product.createdAt, "days");
  }
  return lifetime;
};

export const LoadingIcon = (props) => {
  return <i className={`fa fa-spinner fa-spin ${props.className}`} />;
};

export const productSize = (size) => {
  const { title = "", ru, intl } = size;
  const alter_title = ru && ru !== title ? ru : intl && intl != title ? intl : "";
  return title + (alter_title ? ` (${alter_title})` : "");
};

export const navRoutepath = (nav) => {
  return nav.routepath + "?" + qs.stringify(nav.query);
};

export const apiUrls = {
  list: "/api/users",
  emails: "/api/users/emails",
  info: "/api/users/user_{userId}",
  logins: "/api/users/user_{userId}/logins",
  loginInfo: "/api/users/user_{userId}/logins/login_{login_id}",
  loginPassword: "/api/users/user_{userId}/logins/login_{login_id}/password",
  checkLogins: "/api/users/check-logins",
};

export const path = "/users";

export const infoLink = (user) => {
  return `${path}/user_${user._id}`;
};

export const formLink = (user) => {
  return `${infoLink(user)}/form`;
};

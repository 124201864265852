import React, {Fragment, useState, useEffect} from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {
    LockScreen,
    Login,
    Register,
    Error400,
    ForgotPassword,
    Error500,
    PricingStyle1,
    PricingStyle2
} from "views/pages/index";

const indexRoutes = [
    { path: "/pricing/style1", component: PricingStyle1 },
    { path: "/pricing/style2", component: PricingStyle2 },
    { path: "/lockscreen", component: LockScreen },
    // { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/error400", component: Error400 },
    { path: "/error500", component: Error500 },
    { path: "/forgotPassword", component: ForgotPassword },
    { path: "/", component: Login }
];

export default indexRoutes;
export const Auth = props => {

    return (
        <Switch>
        {indexRoutes.map((prop, key) => {
            return (
                <Route
                    exact
                    path={prop.path}
                    component={prop.component}
                    key={key}
                    history={props.history}
                />
            );
        })}
        <Route component={Error400} />
        </Switch>
    )

}

import React from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import SummaryStats from "./summary";
import IntlMessages from "util/intlMessages";

import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";
// import ProductsStats from "./products";
// import PaymentsStats from "./payments";
// import PromocodesStats from "./promocodes";
// import { AccessControl } from "components/cloclo";

const Dashboard = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Dashboard" />,
    url,
    actions: [],
  });
  return (
    <div className="react-strap-doc">
      <PageTitle {...breadCrumbs} />


      <SummaryStats {...props} />
      {/* 

      <AccessControl id="Catalogs">
      </AccessControl>
      <AccessControl id="Products">
        <ProductsStats {...props} />
      </AccessControl>
      <AccessControl id="Payments">
        <PaymentsStats {...props} />
      </AccessControl>
      <AccessControl id="Catalogs">
        <PromocodesStats {...props} />
      </AccessControl>
       */}
    </div>
  );
};

export default connect(null, {})(Dashboard);

import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
// import { Line } from "react-chartjs-2";
import IntlMessages from "util/intlMessages";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

import { useIntl } from "react-intl";
import customTooltip from "components/common/chartTooltip";

import { ScaleSelector } from "./ScaleSelector";
import AttrsValuesContainer from "./AttrsValuesContainer";

const AttrsSingleLineChart = (props) => {
  const intl = useIntl();

  return (
    <AttrsValuesContainer {...props}>
      {({ datasets, plainData, labels, query, setQuery }) => {
        // console.log({ plainData, datasets });

        const data = plainData;
        return (
          <div className="card roe-shadow-2 fill-height">
            {/* <small>{JSON.stringify(data)}</small> */}
            <div className="card-body">
              <div className="d-flex mb-4 flex-nowrap -justify-content-center">
                <div className="h4 card-title">
                  {props.title}{" "}
                  {/* {props.title ? <IntlMessages id={props.title} /> : null}{" "} */}
                  {!props.titleOnly && props.attrNameOnly && _.size(datasets) > 0 ? datasets[0].label : null}
                </div>
                <div className="ml-auto text-nowrap">
                  <ScaleSelector {...{ query, setQuery }} />
                </div>
              </div>
              <div className="d-flex flex-nowrap">
                <div className="">
                  <LineChart
                    width={800}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={"_key"}
                      tickFormatter={(value) => moment(value).format("DD.MM.YYYY")}
                    />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    {datasets.map((dataset, index) => {
                      // console.log("dataset is", dataset);
                      return (
                        <Line
                          key={index}
                          type="monotone"
                          name={dataset.label}
                          // data={dataset.dataPoints}
                          dataKey={dataset.dataKey}
                          stroke={dataset.color}
                          activeDot={{ r: 4 }}
                        />
                      );
                    })}
                  </LineChart>
                </div>
              </div>
              {/* <small>{JSON.stringify(plainData)}</small> */}
              {/* <ResponsiveContainer width="100%" height="100%"> */}

              {/* 
              <div className="row">
                <div className="col-12 col-lg-9">
                  <Line data={{ datasets, labels }} height={300} options={options} />
                </div>

                <div className="col-12 col-lg-3">
                  <div className="row mt-2">
                    {_.map(datasets, (dataset, index) => {
                      const color = dataset.color; // colors[index % colors.length];
                      return (
                        <div key={index} className="col-6 col-md-4 col-lg-12 mb-3 text-nowrap">
                          <i className="fa fa-circle mr-3" style={{ color }} />
                          {dataset.label}

                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            */}
            </div>
          </div>
        );
      }}
    </AttrsValuesContainer>
  );

  // */
};

export default AttrsSingleLineChart;

/** управление каналами
 */
import _ from "lodash";
import React from "react";

import SpecialsMessagesTable from "./Table";

const SpecialsMessages = (props) => {
  return (
    <div>
      <SpecialsMessagesTable />
    </div>
  );
};

export default SpecialsMessages;

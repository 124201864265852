import { path as parentPath } from "../init";

export const path = "accounts";

export const apiUrls = {
  list: "/api/references/accounts",
  info: "/api/references/accounts/account_{accountId}",
};

export const infoLink = (account) => {
  return `${parentPath}/${path}/account_${account._id}`;
};

export const formLink = (account) => {
  return `${infoLink(account)}/form`;
};

import _ from 'lodash';
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import IntlMessages from "util/intlMessages";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const PageTitle = (props) => {
    const { id, title, sidebarTheme, className, breadCrumb, actions } = props;
    return (
        <Fragment>
            <div className="new-page-title">
                <div className={classNames("Page-title", className)}>
                    <div className="title flex-1">

                        {title
                            ? title
                            : id && <IntlMessages id={id} />
                        }

                    </div>

                    {actions && _.map(actions, (action, i) => {
                        return (
                            <div className={`roy-round-icon mr-2 ${i == 0 && "ml-auto"}`} key={i}>
                                {action}
                            </div>
                        )
                    })}

                </div>
                {(breadCrumb) && (
                    <div>
                        <Breadcrumb className="custom-breadcumb">
                            {breadCrumb &&
                                breadCrumb.map((e, i) => {
                                    return (
                                        <BreadcrumbItem key={i} active>
                                            {e.id &&
                                                <IntlMessages id={e.id}>
                                                    {translate => {
                                                        if (e.to) return (
                                                            <Link className="c-text-info" to={e.to}>
                                                                {translate}
                                                            </Link>
                                                        )
                                                        return translate;
                                                    }
                                                    }
                                                </IntlMessages>
                                            }

                                            {e.title && (
                                                e.to
                                                    ? <Link className="c-text-info" to={e.to}>
                                                        {e.title}
                                                    </Link>
                                                    : e.title
                                            )
                                            }
                                        </BreadcrumbItem>
                                    );
                                })
                            }

                        </Breadcrumb>
                    </div>
                )}

            </div>
        </Fragment>
    );
};

PageTitle.propTypes = {
    // title: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(
    mapStateToProps,
    null
)(PageTitle);

import _ from "lodash";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";
import { Uploader, ImagePreview, AudioPreview, Text2Mp3Button } from "components/standart";
import { setOnChange } from "../index";

const ContactsData = (props) => {
  const { attrName, data, setData } = props;
  const [contacts, setContacts] = useState({ ...data[attrName] });

  useEffect(() => {
    setData({ ...data, [attrName]: contacts });
  }, [contacts]);

  const changeContacts = setOnChange(contacts, setContacts);
  const height = 100;
  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="contactsData.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`title`}
            value={_.toString(contacts.title)}
            onChange={changeContacts}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row" style={{ height: height + 70 }}>
        <label className="col-4 col-form-label">
          <IntlMessages id={`contactsData.content`} />
        </label>
        <div className="col">
          <ReactQuill
            value={contacts.content || ""}
            style={{ height }}
            onChange={(content) => {
              // Object.assign(values[index], { value });
              setContacts({ ...contacts, content });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="contactsData.telegram" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`telegram`}
            value={_.toString(contacts.telegram)}
            onChange={changeContacts}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="contactsData.email" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name={`email`}
            value={_.toString(contacts.email)}
            onChange={changeContacts}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="contactsData.image" />

          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setContacts({ ...contacts, imageId: file._id })}
            imagesOnly="true"
            multiple={false}
          />
        </label>
        <div className="col-8">
          {contacts.imageId && (
            <ImagePreview
              file={{ _id: contacts.imageId }}
              deleteHandler={() => {
                setContacts({ ...contacts, imageId: null });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactsData;

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { StatsBody } from "components/stats/init";

import CharsSummaryStats from "./chars";
import CurrenciesSummaryStats from "./currencies";
import MessagesSummaryStats from "./messages";
import AliasSummaryStats from "./alias";
import CategoriesSummaryStats from "./categories";
import ProgressSummaryStats from "./progress";
import BarChartsStats from "./barcharts";
import BlocksSummaryStats from "./blocks";
import SingleLinesStats from "./single-lines";
import PiesSummaryStats from "./pies";


const SummaryStats = (props) => {
  return (
    <div className="mt-3">

      <StatsBody>
        <BlocksSummaryStats />
      </StatsBody>

      <StatsBody>
        <SingleLinesStats />
      </StatsBody>

      
      {/* <StatsBody>
        <CurrenciesSummaryStats />
      </StatsBody> */}
      {/* <StatsBody>
        <CategoriesSummaryStats />
      </StatsBody> */}
      <StatsBody>
        <PiesSummaryStats />
      </StatsBody>


      {/* 
      <StatsBody>
        <MessagesSummaryStats />
      </StatsBody>
      <StatsBody>
        <AliasSummaryStats />
      </StatsBody>
      <StatsBody>
        <ProgressSummaryStats />
      </StatsBody>
      <StatsBody>
        <CategoriesSummaryStats />
      </StatsBody>
      <StatsBody>
        <CharsSummaryStats />
      </StatsBody>
       */}
    </div>
  );
};

export default connect(null, {})(SummaryStats);

import _ from "lodash";
import React, { useState } from "react";
import { Pagination, PaginationItem } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { history } from "redux/store";
import qs from "query-string";
import IntlMessages from "util/intlMessages";

const Pager = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { query, url, total } = props;
  const maxPages = 10;
  const limit = Math.abs(+query.limit);
  if (!limit) return <></>;
  const pagesCount = Math.ceil(total / limit);
  const currentPage = Math.ceil(_.toSafeInteger(query.offset) / limit);

  if (pagesCount <= 0) return <IntlMessages id="noData" />;
  let pagesRange = [],
    next = <></>,
    prev = <></>,
    last = <></>,
    first = <></>;

  if (maxPages > pagesCount) {
    // если маскимальное количество страниц больше полного количества
    // то обычный список на максимум страниц
    pagesRange = [0, pagesCount];
  } else {
    pagesRange = [currentPage, Math.min(pagesCount, currentPage + maxPages)];

    if (currentPage > maxPages) {
      first = (
        <PaginationItem active={false}>
          <NavLink className="page-link" to={`${url}?${qs.stringify({ ...query, offset: 0 })}`}>
            <i className="fa fa-angle-double-left" />
          </NavLink>
        </PaginationItem>
      );
    }

    if (currentPage < pagesCount - maxPages) {
      const offset = (pagesCount - 1) * limit;
      last = (
        <PaginationItem active={false}>
          <NavLink className="page-link" to={`${url}?${qs.stringify({ ...query, offset })}`}>
            <i className="fa fa-angle-double-right" />
          </NavLink>
        </PaginationItem>
      );
    }

    if (currentPage > 0) {
      // смещение для шага назад
      let offset = 0;
      // если текущая страница больше следующе
      if (currentPage > maxPages) {
        offset = limit * (currentPage - maxPages);
      }

      prev = (
        <PaginationItem active={false}>
          <NavLink className="page-link" to={`${url}?${qs.stringify({ ...query, offset })}`}>
            <i className="fa fa-angle-left" />
          </NavLink>
        </PaginationItem>
      );
    }
    if (currentPage + maxPages < pagesCount) {
      // смещение для шага назад
      let offset = limit * (currentPage + maxPages);

      next = (
        <PaginationItem active={false}>
          <NavLink className="page-link" to={`${url}?${qs.stringify({ ...query, offset })}`}>
            <i className="fa fa-angle-right" />
          </NavLink>
        </PaginationItem>
      );
    }
  }

  return total > limit ? (
    <>
      <div>
        <div className="d-inline-block">
          <Pagination>
            {first}
            {prev}
            {_.range(...pagesRange).map((pageNumber) => {
              // const pageQuery = _.clone(query);
              const offset = limit * pageNumber;
              return (
                <PaginationItem key={pageNumber} active={currentPage == pageNumber}>
                  <NavLink
                    className="page-link"
                    to={`${url}?${qs.stringify({ ...query, offset })}`}
                  >
                    {pageNumber + 1}
                  </NavLink>
                </PaginationItem>
              );
            })}
            {next}
            {last}
          </Pagination>
        </div>

        <div className="d-inline-block">
          <Dropdown
            className="ml-3"
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle caret className="c-info">
              # {limit}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>
                <IntlMessages id="pager.limit" />
              </DropdownItem>
              {[30, 100, 500].map((limit, key) => {
                const offset = 0;
                return (
                  <DropdownItem
                    key={key}
                    onClick={() =>
                      history.push(`${url}?${qs.stringify({ ...query, offset, limit })}`)
                    }
                  >
                    {limit}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </>
  ) : null;
};

export default Pager;

import ruMessages from "../locales/ru_RU.js";
// require('@formatjs/intl-pluralrules/polyfill');
// const appLocaleData = require('@formatjs/intl-pluralrules/dist/locale-data/ru');
// import appLocaleData from 'react-intl/locale-data/ru';

const RuLang = {
  messages: {
    ...ruMessages,
  },
  locale: "ru-RU",
  // data: appLocaleData,
};
export default RuLang;

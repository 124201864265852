/** форма клиента
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import moment from "moment";

import { useIntl } from "react-intl";

const Info = (user) => {
  const intl = useIntl();

  const dateFormat = intl.formatMessage({ id: "dateFormat" });

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Users.Data" />}</div>
            <div className="roe-card-body">
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Users.name" />
                </div>
                <div className="col-8">{user.profile?.name}</div>
              </div>
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Users.login" />
                </div>
                <div className="col-8">{user.userLogin?.value}</div>
              </div>{" "}
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Users.email" />
                </div>
                <div className="col-8">{user.userEmail?.value}</div>
              </div>
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Users.itemsCount" />
                </div>
                <div className="col-8">{user.itemsCount}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Logins" />}</div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
          </div>
        </div>
            */}
      </div>
    </> ///
  );
};

export default connect(null, {})(Info);

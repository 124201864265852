import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const SyncMessages = (props) => {
  const params = useParams();

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  // const changeUser = setOnChange(newUser, setNewUser);

  const syncHandler = (req) => {
    const res = {
      onSuccess(body) {
        props.refreshHandler();
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
        setError(null);
      },
      onFailure({ message }) {
        setError(message);
        setLoading(false);
      },
    };

    const confirmed = window.confirm("Загрузить предыдущие сообщения из канала?");
    if (confirmed) {
      props.syncMessages({ params }, res);
    }
  };

  return (
    <>
      <button
        className={`btn btn-sm btn-secondary ${props.className}`}
        onClick={syncHandler}
        disabled={loading}
      >
        <i className={`fa fa-sync-alt ${loading ? "fa-spin" : ""}`} />
      </button>
    </>
  );
};

export default connect(null, {
  syncMessages: api.get(apiUrls.htmlParseMessages),
})(SyncMessages);

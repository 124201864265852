import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddTranslation from "./Add";
import { ReloadButton } from "./Form";
import ContentInfo from "./Info";
import { path } from "./init";

const CastsTranslations = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="CastsTranslations" />,
    url,
    actions: [<AddTranslation />, <ReloadButton />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable />
      </Route>

      <Route path={`${path}/translation_:translationId`}>
        <ContentInfo {...{ breadCrumbs }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

CastsTranslations.path = path;

export default CastsTranslations;

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { FloatAssistants, FloatCategories, FloatChannels } from "components/special/contents";
import { CategoriesWithScore, ChanneslWithScore } from "components/standart";
import { SwitchValue } from "components/special/forms";

const ContentData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Schedules.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Schedules.assistant" />
        </label>
        <div className="col-8">
          <FloatAssistants
            _id={content.assistantId}
            multi={false}
            showTitle={true}
            showCount={false}
            className="btn-sm"
            successHandler={([assistantId = null]) => {
              setContent({ ...content, assistantId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Schedules.forCategories" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue
            name="forCategories"
            value={content.forCategories}
            onChange={changeContent}
          />
        </div>
      </div>

      {content.forCategories ? (
        <div className="form-group row">
          <div className="col-4 col-form-label">
            <IntlMessages id="OpenAI.Schedules.categories" />
            <CategoriesWithScore.AddButton
              className="btn-sm float-right"
              values={content.categoriesWithScore}
              changeValues={(categoriesWithScore) =>
                setContent({ ...content, categoriesWithScore })
              }
            />
          </div>
          <div className="col-8">
            <CategoriesWithScore
              values={content.categoriesWithScore}
              changeValues={(categoriesWithScore) =>
                setContent({ ...content, categoriesWithScore })
              }
            />
          </div>
        </div>
      ) : (
        <div className="form-group row">
          <div className="col-4 col-form-label">
            <IntlMessages id="OpenAI.Schedules.channels" />
            <ChanneslWithScore.AddButton
              className="btn-sm float-right"
              values={content.channelsWithScore}
              changeValues={(channelsWithScore) => setContent({ ...content, channelsWithScore })}
            />
          </div>
          <div className="col-8">
            <ChanneslWithScore
              values={content.channelsWithScore}
              changeValues={(channelsWithScore) => setContent({ ...content, channelsWithScore })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, {})(ContentData);

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

const TTSModelSelector = (props) => {
  const { TTSModels } = props.envStore;
  const { name = "TTS", setValue } = props;

  const options = () =>
    _.map(TTSModels, (value) => {
      return { value, label: value };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    setValue({ [name]: value });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      value={props.value ? { value: props.value, label: props.value } : null}
      options={options()}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(TTSModelSelector);

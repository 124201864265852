import { path as parentPath } from "../init";

export const path = "assistants";

export const apiUrls = {
  list: "/api/openai/assistants",
  info: "/api/openai/assistants/assistant_{assistantId}",
  sync: "/api/openai/assistants/assistant_{assistantId}/sync",
};

export const infoLink = (assistant) => {
  return `${parentPath}/${path}/assistant_${assistant._id}`;
};

export const formLink = (assistant) => {
  return `${infoLink(assistant)}/form`;
};

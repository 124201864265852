export const apiUrls = {
  settings: "/api/www",
  landings: "/api/www/landings",
  metadata: "/api/www/metadata",
  contacts: "/api/www/contacts",
  contactsInfo: "/api/www/contacts/contact_{contactId}",
  contactsPositions: "/api/www/contacts/positions",
  content: "/api/www/content",
  contentInfo: "/api/www/content/id_{content_id}",
  faq: "/api/www/faq",
  faqPriorities: "/api/www/faq/priorities",
  faqInfo: "/api/www/faq/id_{faq_id}",
  feedback: "/api/www/feedbacks",
  feedbackInfo: "/api/www/feedbacks/feedback_{feedbackId}",
};

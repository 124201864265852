/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";

import ReactQuill from "react-quill";

const ContentData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="symbol" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="symbol"
            value={content.symbol || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Payments.Currencies.isoCode" />
        </label>
        <div className="col-8">
          <input
            type="number"
            name="isoCode"
            value={content.isoCode || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Payments.Currencies.isoName" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="isoName"
            value={content.isoName || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ContentData);

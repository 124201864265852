/** модальное окно для выбора локализаций в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatAccountsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Partners" />}
      {...props}
      head={(search, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="name" />
            </th>
            <th>
              <IntlMessages id="Telegram.Accounts.isBot" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(account, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!account.enabled ? "text-strike" : ""}>
            {account.name}
          </td>
          <td>
            <IntlMessages id={account.isBot ? "yes" : "no"} />
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatAccounts = (props) => {
  return <FloatAccountsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/references/accounts"),
})(FloatAccounts);

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsPieChart from "components/stats/AttrsPieChart";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const CategoriesSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      {/* 

      <StatsComponent>
        <AttrsListValues
          queryHandler={api.get("/categories/messages")}
          dataAttr={"sessionsCount"}
          title="Dashboard.Categories.MessagesStats"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsListValues
          queryHandler={api.get("/categories/adverts")}
          dataAttr={"sessionsCount"}
          title="Dashboard.Categories.AdvertsStats"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>

      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/categories/messages")}
          dataAttr={"statsCount"}
          title="Dashboard.Categories.MessagesDynamic"
          query={defaultQuery()}
        />
      </StatsComponent>
      
      */}
    </StatsContainer>
  );
};

export default connect(null, {})(CategoriesSummaryStats);

import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";

export const colors = [
  "#00c486",
  "#0e6ae5",
  "#e53e0e",
  "#e5800e",
  "#f1c232",
  "#670ee5",
  "#34ebe8",
  "#5d506e",
  "#a186c4",
  "#a6e35f",
  "#f50abe",
  "#9c4702",
  "#6e6e6e",
  "#000000",
];

export const defaultQuery = () => {
  return {
    minDateTime: moment().subtract(1, "month").format(),
    baseTime: "toStartOfDay",
    scale: "month",
  };
};

export class AttrDataKeys {
  name;
  keys;
  labels;
  _keys;
  _labels;
  rangeFormat;
  dateFormat;
  constructor({ name, rangeFormat, dateFormat }) {
    this.rangeFormat = rangeFormat;
    this.dateFormat = dateFormat;
    this.name = name || "dateTime";
    this.keys = [];
    this.labels = [];
    this._keys = {};
    this._labels = {};
  }

  add(date) {
    const key = date ? moment(date).format(this.rangeFormat) : null;
    const label = date ? moment(date).format(this.dateFormat) : null;
    this._keys[key] = this.keys.push(key) - 1;
    this._labels[key] = this.labels.push(label) - 1;
  }

  data() {
    return _.pick(this, ["keys", "labels"]);
  }
}

export class AttrDataStorage {
  keysStorage;
  datasets;
  dataAttr;
  keyPositionPlainData;
  // attrs;

  constructor(keysStorage) {
    this.keysStorage = keysStorage;
    this.datasets = {};
    this.keyPositionPlainData = [];
    // this.attrs = {};
  }

  add(dataset) {
    // this.datasets.push(dataset);
    // здесь следует разобрать значение dataset.attr и извлечь из него структурно полезные аргументы для себя
    const { attr } = dataset;
    // здесь важно понимать, что в случае наличия id оно является первичным ключом, по которому следует вести группировку
    this.datasets[attr.value || attr.name] = dataset;
  }

  setDataAttr(attrName) {
    this.dataAttr = attrName;
  }

  autoFill(value) {
    const attrs = Object.keys(this.datasets);
    // тут следует понимать, что в случае наличия value в атрибуте его значением может быть
    // только значение из какого-то указанного поля, которое в общем случае может быть в настройках, например, DataStorage

    // возьмем из значения только те значения, которые есть в составе атрибутов
    const values = _.pick(value, this.dataAttr ? [this.dataAttr] : attrs);

    const keyValue = _.get(value, this.keysStorage.name);

    const resultKeyValue = keyValue
      ? moment(_.get(value, this.keysStorage.name), 'YYYY-MM-DD').format(this.keysStorage.rangeFormat)
      : null;
    const keyPosition = this.keysStorage._keys[resultKeyValue];
    // console.log("key position", keyPosition, keyValue);
    // итак, для совместимости с canvasJS мне следует выполнить следующие преобразования
    //
    if (keyPosition >= 0) {
      _.map(attrs, (attrName) => {
        const { attr } = this.datasets[attrName];
        // если для атрибута установлено value (то есть идет обращение за конкретным значением в списке)
        if (this.dataAttr && attr.value) {
          if (_.get(value, attr.name) === attr.value) {
            const dataAttrValue = _.get(values, this.dataAttr);

            this.keyPositionPlainData[keyPosition] = {
              ...this.keyPositionPlainData[keyPosition],
              [attrName]: dataAttrValue,
              _key: resultKeyValue,
            };

            // this.datasets[attrName].data[keyPosition] = dataAttrValue; // присвоение непосредственно в позицию
            const dataPoint = {
              value: dataAttrValue,
              name: attrName,
              label: attr.label || attr.name,
            };
            this.datasets[attrName].dataPoints[keyPosition] = dataPoint; // присвоение непосредственно в позицию
          }
        } else {
          const attrValue = _.get(values, attrName);

          this.keyPositionPlainData[keyPosition] = {
            ...this.keyPositionPlainData[keyPosition],
            [attrName]: attrValue,
            _key: resultKeyValue,
          };

          // this.datasets[attrName].data[keyPosition] = _.get(values, attrValue); // присвоение непосредственно в позицию
          const dataPoint = {
            value: attrValue,
            name: attrName,
            label: attr.label || attr.name,
          };
          this.datasets[attrName].dataPoints[keyPosition] = dataPoint; // присвоение непосредственно точку назначения в позицию
        }
      });
    }
    // а тут соответствующий разбор
  }

  plainData() {
    return this.keyPositionPlainData.filter(_.size);
  }
  export() {
    // ..
    return Object.values(this.datasets).map((dataset) => dataset.toJSON());
  }
}

export class AttrDataSet {
  // атрибут
  attr;
  // лычка
  // label;
  // релевантные данные
  data;
  //
  dataPoints;
  // собственные свойства
  props;

  constructor({ attr, props }) {
    this.attr = attr;
    this.props = props;
    this.data = [];
    this.dataPoints = [];
  }

  toJSON() {
    return {
      label: this.attr?.label || this.attr?.name,
      dataKey: this.attr?.value || this.attr?.name,
      dataPoints: this.dataPoints.filter(_.size),
      // data: this.data,
      ...this.props,
    };
  }
}

export const dashboardClassName = "m-2";

export const StatsBody = (props) => {
  return <div className="d-flex flex-wrap justify-content-center">{props.children}</div>;
};

export const StatsContainer = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export const StatsComponent = (props) => {
  return <div className={dashboardClassName}>{props.children}</div>;
};

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import IntlMessages from "util/intlMessages";
import AdvertiserUserData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../../init";

const { api } = adminAPI.actions;

const PartnerUserForm = (props) => {
  const params = useParams();
  const [advertiserUser, setAdvertiserUser] = useState(null);
  const [id, setId] = useState(null);
  useEffect(() => {
    advertiserUser && setId(advertiserUser._id);
  }, [advertiserUser]);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.savePartnerUser({ ...req, params: { ...params, id } }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setAdvertiserUser(props.advertiserUser);
  }, [openModal]);

  const removeHandler = () => {
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить логин?");
    if (isConfirmed) {
      props.removePartnerUser({ params: { ...params, id } }, res);
    }
  };

  return (
    <div className="text-nowrap">
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-edit" />
      </button>
      <button className="btn btn-sm btn-danger ml-1" onClick={removeHandler}>
        <i className="fa fa-times" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Logins.Edit"} />
          </ModalHeader>
          <ModalBody>
            <AdvertiserUserData {...{ advertiserUser, setAdvertiserUser, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  savePartnerUser: api.put(apiUrls.userInfo),
  removePartnerUser: api.delete(apiUrls.userInfo),
})(PartnerUserForm);

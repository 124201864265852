/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, Pager, TableLoading, AudioPreview } from "components/standart";
import { FloatChannels } from "components/special/contents";
import { useIntl } from "react-intl";
import { ShowDuration, ShowShortDuration } from "components/special/durations";

import { apiUrls } from "../init";
import AddMessage from "./Add";
import SpecialsMessageForm from "./Form";
const { api } = adminAPI.actions;
const limit = 999_999;

const SpecialsMessagesTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [messages, setMessages] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(messages.data, result.source.index, result.destination.index);

    const _id = _.map(newOrder, ({ _id }) => _id);

    props.savePositions(
      { body: { _id }, params },
      {
        onSuccess() {
          console.log(new Date(), "save priority success");
        },
      }
    );

    setMessages({ ...messages, data: newOrder });
  };

  useEffect(() => {
    setQuery({ ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setMessages(body);
    };
    const onRequest = () => {
      setMessages({ total: -1 });
    };

    query.limit && props.getChannelMessages({ params: query, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="number" />
          </th>

          <th className="text-nowrap">
            <FloatChannels
              _id={query.channelId}
              query={{ everything: false }}
              multi={true}
              showTitle={false}
              showCount={true}
              className="btn-sm mr-1"
              successHandler={(channelId) => {
                setQuery({ ...query, channelId });
              }}
            />

            <IntlMessages id="Messages.channel" />
          </th>
          <th>
            <IntlMessages id="Messages.title" />
          </th>
          <th>
            <IntlMessages id="Messages.text" />
          </th>
          <th>
            <IntlMessages id="Messages.duration" />
          </th>
          <th>
            <IntlMessages id="Messages.date" />
          </th>
          <th className="text-right text-nowrap">
            <Finder.search {...{ query, url }} className="mr-1" />

            <AddMessage refreshHandler={refreshHandler} />
          </th>
        </tr>
      </thead>
      {messages.total < 0 && <TableLoading />}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(messages.data, (message, index) => (
                <Draggable key={message._id} draggableId={message._id} index={index}>
                  {(provided, snapshot) => (
                    <tr
                      style={{
                        ...(snapshot.isDragging ? { display: "table" } : {}),
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MessagesRowCells {...{ message, refreshHandler, index }} />
                    </tr>
                  )}
                </Draggable>
              ))}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>

      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={messages.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const MessagesRowCells = (props) => {
  const { message, index, refreshHandler } = props;
  const intl = useIntl();
  const [expandText, setExpandText] = useState(false);
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const toggleExpandText = () => setExpandText(!expandText);

  return (
    <>
      <td>{index + 1}</td>
      <td>{message.channel?.name}</td>
      <td>{message.title}</td>
      {/* <td>{message.fileId ? <AudioPreview file={{ _id: message.fileId }} /> : null}</td> */}
      <td
        className={`overflow-hidden ${expandText ? "" : "text-nowrap"}`}
        style={{ maxWidth: "250px" }}
      >
        <div>{message.text}</div>
        <div>
          <button className="btn btn-sm btn-secondary" onClick={toggleExpandText}>
            <i className={`fa ${!expandText ? "fa-expand-alt" : "fa-compress-alt"}`} />
          </button>
        </div>
      </td>

      <td>
        <ShowShortDuration {...message} />
      </td>
      <td>{moment(message.createdAt).format(dateTimeFormat)}</td>

      <td className="text-right">
        <SpecialsMessageForm {...{ message, refreshHandler }} />
      </td>
    </>
  );
};

export default connect(null, {
  getChannelMessages: api.get(apiUrls.messages),
  savePositions: api.post(apiUrls.messagesPositions),
})(SpecialsMessagesTable);

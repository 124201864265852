import _ from "lodash";
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import { Nav, NavItem } from "reactstrap";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import ContentInfo from "./Info";

const Content = (props) => {
  const { url, path } = useRouteMatch();
  const { envStore, breadCrumbs } = props;
  const [localeId, setLocaleId] = useState(envStore.locales[0]?._id);

  const contentInfo = (content) => {
    return `${url}/content_${content._id}`;
  };

  const contentForm = (content) => {
    return `${contentInfo(content)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Www.Contents" />,
    url,
    actions: [<AddContent {...{ contentForm, localeId }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <Nav tabs style={{ cursor: "pointer" }}>
          {_.map(envStore.locales, (locale, index) => {
            return (
              <Fragment key={index}>
                <NavItem>
                  <span
                    className={`nav-link ${localeId === locale._id ? "active" : ""}`}
                    onClick={() => setLocaleId(locale._id)}
                  >
                    {locale.name}
                  </span>
                </NavItem>
              </Fragment>
            );
          })}
        </Nav>
        <ContentTable {...{ contentInfo, contentForm, localeId }} />
      </Route>

      <Route path={`${path}/content_:content_id`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(Content);

import React from "react";
import { Radar } from "react-chartjs-2";

const RadarComponent = ({ sidebarTheme }) => {
    const data = {
        labels: [
            "Eating",
            "Drinking",
            "Sleeping",
            "Designing",
            "Coding",
            "Cycling",
            "Running"
        ],
        datasets: [
            {
                label: "Data",
                backgroundColor: "rgba(179,181,198,0.2)",
                borderColor: "#563c91",
                pointBackgroundColor: "rgba(179,181,198,1)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgba(179,181,198,1)",
                data: [65, 59, 90, 81, 56, 55, 40]
            },
            {
                label: "My Second dataset",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: sidebarTheme.backgroundColor,
                pointBackgroundColor: "rgba(255,99,132,1)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgba(255,99,132,1)",
                data: [28, 48, 40, 19, 96, 27, 100]
            }
        ]
    };

    return (
        <div>
            <Radar data={data} height={100} />
        </div>
    );
};

export default RadarComponent;

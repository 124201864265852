import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI, setSuccess } from "redux/api";

import AttrsPieChart from "components/stats/AttrsPieChart";

import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const PiesSummaryStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/channels/inCategories")}
          dataAttr="value"
          showPercent={true}
          title="Каналы (шт, кат)"
          cols={2}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/categories/messages")}
          dataAttr={"sessionsCount"}
          title="Интересы (чел, кат)"
          cols={2}
        />
      </StatsComponent>

      {/* 
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/categories/adverts")}
          dataAttr={"currencySum"}
          title="Реклама (руб, кат) "
          showPercent={true}
          cols={2}
          // query={defaultQuery()}
        />
      </StatsComponent>
       */}

      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/categories/chars")}
          dataAttr={"charsCost"}
          title="Обработка ($)"
          showPercent={true}
          cols={2}
          // query={defaultQuery()}
        />
      </StatsComponent>
      {/* 
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/messages/listeners")}
          title="Слушатели"
          showPercent={true}
          query={{ minDateTime: moment().subtract(1, "month").format() }}
        />
      </StatsComponent>
      <StatsComponent>
        <AttrsPieChart
          queryHandler={api.get("/currencies/alias")}
          dataAttr={"currencySum"}
          title="Доходы"
          query={{}}
          showPercent={true}
        />
      </StatsComponent>
       */}
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(PiesSummaryStats);

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import IntlMessages from "util/intlMessages";
import AdvertData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../../init";

const { api } = adminAPI.actions;

const AdvertForm = (props) => {
  const params = useParams();
  const [advert, setAdvert] = useState(props.advert);
  const [advertId, setAdvertId] = useState(props.advert._id);

  useEffect(() => {
    advert && setAdvertId(advert._id);
  }, [advert]);

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.saveData({ ...req, params: { ...params, advertId } }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setAdvert(props.advert);
  }, [openModal]);

  const removeHandler = () => {
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить канал?");
    if (isConfirmed) {
      props.removeData({ params: { ...params, advertId } }, res);
    }
  };

  return (
    <div className="text-nowrap">
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-edit" />
      </button>
      <button className="btn btn-sm btn-danger ml-1" onClick={removeHandler}>
        <i className="fa fa-times" />
      </button>

      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"edit"} />
          </ModalHeader>
          <ModalBody>
            <AdvertData {...{ advert, setAdvert, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  saveData: api.put(apiUrls.advertIndex),
  removeData: api.delete(apiUrls.advertIndex),
})(AdvertForm);

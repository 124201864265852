import apiClass from "./init";
import { sessionToken, accessToken } from "../init";

const auth = new apiClass({ session: localStorage.getItem(sessionToken) });

auth.actions = {
  login: auth.post("/login"),
  requestCode: auth.post("/request"),
  validateCode: auth.post("/confirm"),
  session: auth.post("/session", {
    success(state, body) {
      localStorage.setItem(sessionToken, body.session);
      return { ...state, session: body.session };
    },
  }),
  logout: auth.post("/logout", {
    request(state) {
      localStorage.removeItem(sessionToken);
      localStorage.removeItem(accessToken);
      return { ...state, session: null };
    },
  }),
};

export default auth;

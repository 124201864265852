/** управление каналами
 */
import _ from "lodash";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages/index";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";
import IntlMessages from "util/intlMessages";
import { path } from "./init";
import ChannelsTable from "./Table";
import Info from "./Info";
import AddChannel from "./Add";

const Channels = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Channels" />,
    url,
    actions: [<AddChannel />],
  });

  return (
    <div className="react-strap-doc">
      <Switch>
        <Route exact path={`${path}`}>
          <PageTitle {...breadCrumbs} />

          <ChannelsTable />
        </Route>

        <Route path={`${path}/channel_:channelId`}>
          <Info {...{ breadCrumbs }} />
        </Route>

        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Channels };


/* управление каналами
import _ from "lodash";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import PartnerChannelsTable from "./Table";
import ChannelsMessagesTable from "../Messages/Table";

const PartnerChannels = (props) => {
  const location = useLocation();
  const [query, setQuery] = useState({});
  useEffect(() => {
    setQuery(qs.parse(location.search));
    // console.log("qs", qs.parse(location.search));
  }, [location]);
  return <div>{query.channelId ? <ChannelsMessagesTable /> : <PartnerChannelsTable />}</div>;
};

export default PartnerChannels;
 */

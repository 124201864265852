import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import IntlMessages from "util/intlMessages";
import LoginData from "./LoginData";
import LoginPassword from "./LoginPassword";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const LoginForm = (props) => {
  const params = useParams();
  const [login, setLogin] = useState(props.login);

  const saveLoginHandler = (req, res) => {
    const login_id = login._id;
    res.onSuccess = (body) => {
      props.refreshHandler();
      toggleModal();
    };
    props.saveLogin({ ...req, params: { ...params, login_id } }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setLogin(props.login);
  }, [openModal]);

  const removeHandler = () => {
    const login_id = login._id;
    const res = {
      onSuccess() {
        props.refreshHandler();
      },
    };
    const isConfirmed = window.confirm("Удалить логин?");
    if (isConfirmed) {
      props.removeLogin({ params: { ...params, login_id } }, res);
    }
  };

  return (
    <div className="text-nowrap">
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-edit" />
      </button>
      <button className="btn btn-sm btn-danger ml-1" onClick={removeHandler}>
        <i className="fa fa-times" />
      </button>
      {login.type === "plain" ? <LoginPassword login={login} /> : null}
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Logins.Edit"} />
          </ModalHeader>
          <ModalBody>
            <LoginData login={login} setLogin={setLogin} saveHandler={saveLoginHandler} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  saveLogin: api.put(apiUrls.loginInfo),
  removeLogin: api.delete(apiUrls.loginInfo),
})(LoginForm);

/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { TableLoading } from "components/standart";

import { limit } from "variables";

import { apiUrls } from "../../init";
import AddUser from "./Add";
import PartnerUserForm from "./Form";
const { api } = adminAPI.actions;

const UsersTable = (props) => {
  const params = useParams();

  const [advertiserUsers, setAdvertiserUsers] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setAdvertiserUsers(body);
    };
    const onRequest = () => {
      setAdvertiserUsers({ total: -1 });
    };

    query.limit && props.getPartnerUsers({ params }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Users.name" />
          </th>
          <th className="text-right">
            <AddUser refreshHandler={refreshHandler} />
          </th>
        </tr>
      </thead>
      {advertiserUsers.total < 0 && <TableLoading />}
      <tbody>
        {_.map(advertiserUsers.data, (advertiserUser, key) => {
          return (
            <tr key={key} className={""}>
              <td>{advertiserUser.user.profile?.name}</td>
              <td className="text-right">
                <PartnerUserForm {...{ advertiserUser, refreshHandler }} />
                {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default connect(null, {
  getPartnerUsers: api.get(apiUrls.users),
})(UsersTable);

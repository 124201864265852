/** список логинов */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams, Link } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, AudioPreview, Pager, Sorter, TableLoading } from "components/standart";
import { Popover, PopoverBody } from "reactstrap";
import moment from "moment";
import { useIntl } from "react-intl";
import { FloatSpecialsCategories } from "components/special/contents";

import { limit } from "variables";

import { apiUrls, infoLink } from "./init";
// import AddChannel from "./Add";
// import ChannelForm from "./Form";
import { DeleteButton, FormButton, PublishButton } from "./Form";

const { api } = adminAPI.actions;

const SpecialsTable = (props) => {
  const { envStore } = props;

  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [copied, setCopied] = useState(false);
  const [copiedId, setCopiedId] = useState(null);
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => {
        setCopied(false);
        setCopiedId(null);
      }, 3000);
    }
  }, [copied]);

  const copyLink = (event) => {
    // metrikaTargetClick({ ...event });
    event.preventDefault();
  };

  const resetQueryHandler = () => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
    // setQuery({ ...props.query, ...params, limit, ...qs.parse(history.location.search) });
  };
  const [specials, setSpecials] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    resetQueryHandler();
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setSpecials(body);
    };
    const onRequest = () => {
      setSpecials({ total: -1 });
    };

    query.limit && props.getSpecials({ query: { ...query, ...params } }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Fragment>
      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr className="text-nowrap">
            <th>
              <Finder className="mr-1 d-inline" isActive={query.name}>
                <Finder.input name="name" {...{ query, setQuery }} />
              </Finder>
              <IntlMessages id="Specials.name" />
            </th>

            <th>
              <FloatSpecialsCategories
                _id={query.categoryId}
                query={{ everything: false }}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                multi={true}
                successHandler={([categoryId]) => {
                  setQuery({ ...query, categoryId });
                }}
              />
              <IntlMessages id="Specials.category" />
            </th>

            <th>
              <Finder className="mr-1 d-inline" isActive={query.status}>
                <Finder.select
                  name="status"
                  {...{ query, setQuery }}
                  source={_.get(envStore, "specialsStatuses")}
                  mask="Specials.status"
                />
              </Finder>
              <IntlMessages id="Specials.status" />
            </th>

            {/* <th>
              Подписчики
              <Sorter query={query} url={url} order="subscribersCount" />
            </th> */}
            <th>
              <IntlMessages id="Specials.text" />
            </th>

            <th>Radiogram</th>

            <th>
              <IntlMessages id="tables.created" />
            </th>

            <th className="text-right col-1">
              {/* <OrderChannels /> */}
              <Finder.search {...{ query, url }} onClick={resetQueryHandler} className="mr-1" />

              {/* <AddChannel refreshHandler={refreshHandler} /> */}
            </th>
          </tr>
        </thead>
        {specials.total < 0 && <TableLoading />}
        <tbody>
          {_.map(specials.data, (special, key) => {
            const radiogramUrl = `${process.env.REACT_APP_RADIOGRAM_URL}/?specialId=${special._id}`;
            const id = `copy_${special._id}`;
            return (
              <tr key={key}>
                <td>
                  <div className="text-truncate" style={{ width: "200px" }}>
                    <Link to={infoLink(special)}>{special.name}</Link>
                  </div>
                </td>

                <td>{special.category?.name}</td>

                <td
                  className={`text-${intl.formatMessage({
                    id: `Specials.status.${special.status}.style`,
                  })}`}
                >
                  {intl.formatMessage({ id: `Specials.status.${special.status}` })}
                </td>
                <td>
                  <div className="overflow-hidden text-nowrap" style={{ width: "200px" }}>
                    {special.text}
                  </div>
                  {/* {special.soundFileId && <AudioPreview file={{ _id: special.soundFileId }} />} */}
                </td>
                <td>
                  {special.status === "published" ? (
                    <>
                      <CopyToClipboard
                        text={radiogramUrl}
                        onCopy={() => {
                          setCopied(true);
                          setCopiedId(id);
                        }}
                      >
                        <a
                          href={radiogramUrl}
                          id={id}
                          className="d-block text-black"
                          target="_blank"
                          data-id="share-daily"
                          onClick={copyLink}
                        >
                          <i className="fa fa-copy" />
                        </a>
                      </CopyToClipboard>
                      {copied && (
                        <Popover fade={true} placement="top" isOpen={copied} target={copiedId}>
                          <PopoverBody>Ссылка скопирована</PopoverBody>
                        </Popover>
                      )}
                    </>
                  ) : null}
                </td>
                <td>{moment(special.createdAt).format(dateTimeFormat)}</td>
                <td className="text-right">
                  <FormButton {...{ special }} />
                  <PublishButton {...{ special, refreshHandler }} />
                  <DeleteButton {...{ special, refreshHandler }} />
                  {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={specials.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getSpecials: api.get(apiUrls.list),
})(SpecialsTable);

import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";

export const NumberArrayValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <input
                type="number"
                name="value"
                className="form-control"
                placeholder={intl.formatMessage({ id: "value" })}
                value={elem || ""}
                onChange={({ target }) => {
                  values[index] = +target.value;
                  changeValues(values);
                }}
              />
            </div>

            <div className="col text-right">
              <NumberArrayValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

NumberArrayValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat(['']));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

NumberArrayValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default NumberArrayValues;

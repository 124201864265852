import styled from 'styled-components';

const SelectWrapper = styled.div`
    .select-menus-block {
        .roe-card-style {
            height: 100%;
        }
    }
`;

export default SelectWrapper
import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import Select from "react-select";

const AttachmentsReferencesSelector = (props) => {
  const { publicationsAttachments } = props.envStore;
  const { name = "type", setValue } = props;
  const nameKey = "Publications.attachments.references";
  const options = () =>
    _.map(publicationsAttachments, (value) => {
      return { value, label: value };
    });

  const onChangeHandler = (selectedOptions) => {
    const { value } = selectedOptions;
    setValue(value);
  };

  return (
    <Select
      isMulti={false}
      name={name}
      // defaultValue={defaultValues()}
      // /*
      value={
        props.value
          ? {
              value: props.value,
              label: props.value,
            }
          : null
      }
      // */
      options={options()}
      placeholder={<IntlMessages id={`${nameKey}`} />}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(AttachmentsReferencesSelector);

import React, { useState } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from "reactstrap";

const items = [
    {
        id: 1,
        altText: "Slide 1",
        caption: "Slide 1"
    },
    {
        id: 2,
        altText: "Slide 2",
        caption: "Slide 2"
    },
    {
        id: 3,
        altText: "Slide 3",
        caption: "Slide 3"
    }
];

const Example = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    let animating = false;
    const onExiting = () => {
        animating = true;
    };

    const onExited = () => {
        animating = false;
    };

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map(item => {
        return (
            <CarouselItem
                className="custom-tag"
                tag="div"
                key={item.id}
                onExiting={onExiting}
                onExited={onExited}
            >
                <CarouselCaption
                    className="text-danger"
                    captionText={item.caption}
                    captionHeader={item.caption}
                />
            </CarouselItem>
        );
    });

    return (
        <div>
            <style>
                {`.custom-tag {
                max-width: 100%;
                height: 500px;
                background: rgb(36, 29, 59);
              }`}
            </style>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </div>
    );
};

export default Example;

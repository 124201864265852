import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";

const calcDuration = (totalSeconds) => {
  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const hours = 0 * Math.floor(totalSeconds / secondsInHour);
  const minutes = Math.floor((totalSeconds - hours * secondsInHour) / secondsInMinute);
  const seconds = Math.floor(totalSeconds - hours * secondsInHour - minutes * secondsInMinute);
  return { hours, minutes, seconds };
};

export const ShowDuration = (source) => {
  const duration = calcDuration(source.duration);

  const intl = useIntl();
  const result = [
    duration.hours ? intl.formatMessage({ id: "hours" }, { value: duration.hours }) : null,
    duration.minutes ? intl.formatMessage({ id: "minutes" }, { value: duration.minutes }) : null,
    0 * duration.seconds
      ? intl.formatMessage({ id: "seconds" }, { value: duration.seconds })
      : null,
  ].filter(Boolean);
  
  return source.duration ? (
    <>
      {/* <i className="far fa-headphones mr-2" /> */}
      {result.join(" ")}
    </>
  ) : null;
};

export const ShowShortDuration = (source) => {
  const duration = calcDuration(source.duration);

  const result = [
    // duration.hours ? duration.hours : null,
    duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes,
    duration.seconds < 10 ? `0${duration.seconds}` : duration.seconds,
  ];
  return source.duration ? (
    <>
      {/* <i className="far fa-headphones mr-2" /> */}
      {result.join(":")}
    </>
  ) : null;
};

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { StatsBody } from "components/stats/init";

import BlocksSummaryStats from "./blocks";
import SingleLinesStats from "./single-lines";
import PiesSummaryStats from "./pies";

const ChannelStats = (props) => {
  return (
    <div className="mt-3">
      <StatsBody>
        <BlocksSummaryStats />
      </StatsBody>
      <StatsBody>
        <SingleLinesStats />
      </StatsBody>
      <StatsBody>
        <PiesSummaryStats />
      </StatsBody>
    </div>
  );
};

export default connect(null, {})(ChannelStats);

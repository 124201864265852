import _ from "lodash";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { statsAPI } from "redux/api";

import AttrsBarChart from "components/stats/AttrsBarChart";
import IntlMessages from "util/intlMessages";
// import AttrsBarChart from "components/stats/AttrsBarChart";
import { StatsContainer, StatsComponent, defaultQuery } from "components/stats/init";

const { api } = statsAPI.actions;

const SingleLinesStats = (props) => {
  useEffect(() => {}, []);

  return (
    <StatsContainer>
      <StatsComponent>
        <AttrsBarChart
          queryHandler={api.get("/messages/activity")}
          plainDataAttr="activityDaysCount"
          // attrNameOnly="sessionsCount"
          title={<IntlMessages id="Dashboard.Activity.Retention" />}
          query={defaultQuery()}
        />
      </StatsComponent>

      {/* 
      <StatsComponent>
        <AttrsListValues queryHandler={api.get("/messages")} title="Dashboard.Messages.Summary" />
      </StatsComponent>
      <StatsComponent>
        <AttrsListValues queryHandler={api.get("/adverts")} title="Dashboard.Adverts.Summary" />
      </StatsComponent>
      <StatsComponent>
        <AttrsLineChart
          queryHandler={api.get("/adverts")}
          title="Dashboard.Adverts.Dynamic"
          query={defaultQuery()}
        />
      </StatsComponent>
       */}
    </StatsContainer>
  );
};

export default connect(null, {
  // getCharsStats: api.get("/chars"),
  // getCharsDynamic: api.get("/chars/dynamic"),
})(SingleLinesStats);

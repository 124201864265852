/** компонента проверки контроля доступа
 * вызывается как
 * ```
 * <AccessControl id="Users.Add">
 *  <Button ... />
 * </AccessControl>
 * ```
 * Если в контексте сессии нет указанного идентификатора, значит доступ к дочернему элементу закрыт
 * и компонента ничего не вернет.
 */
import React from "react";
import { connect } from "react-redux";

const AccessControl = (props) => {
  const { routes = {} } = props.session;
  const { id } = props;
  return props.children;
  // return routes[id] ? props.children : <>{props.default}</>;
};

const mapStateToProps = (state) => ({
  session: state.api.session,
});
export default connect(mapStateToProps, {})(AccessControl);

import _ from 'lodash';
import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import qs from 'query-string';

const Sorter = props => {

    const { query, url, order } = props;
    const currentOrder = query.order;
    const ascOrder = `+${order}`;
    const descOrder = `-${order}`;
    let icon = 'sort';
    if (currentOrder === ascOrder) icon = 'sort-up';
    if (currentOrder === descOrder) icon = 'sort-down';

    // orderQuery.order = currentOrder === ascOrder ? descOrder : ascOrder;
    const newOrder = currentOrder === descOrder ? ascOrder : descOrder
    return (
        <NavLink className="text-dark" to={`${url}?${qs.stringify({...query, order: newOrder})}`}>
            {props.children}
            <i className={`btn-sm text-info fa fa-${icon}`} />
        </NavLink>
    )

}

export default Sorter;
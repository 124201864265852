/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { useIntl } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Finder, FilterButton, Pager, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";

import { apiUrls, infoLink } from "./init";
import { FormButton, StartButton } from "./Form";
import { FloatChannels, FloatCategories } from "components/special/contents";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const limit = 99999;
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [updated, setUpdated] = useState(null);

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(contentData.data, result.source.index, result.destination.index);

    const _id = _.map(newOrder, ({ _id }) => _id);

    props.savePositions(
      { body: { _id } },
      {
        onSuccess() {
          console.log(new Date(), "save priority success");
        },
      }
    );

    setContentData({ ...contentData, data: newOrder });
  };

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    (query.limit || updated) && props.getContent({ query: { ...query } }, { onSuccess, onRequest });
  }, [query, updated]);

  return (
    <>
      <Table responsive>
        <thead className="-text-nowrap">
          <tr>
            <th className="col-1">
              <IntlMessages id="OpenAI.Schedules.name" />
            </th>
            <th className="col-1">
              <IntlMessages id="OpenAI.Schedules.assistant" />
            </th>
            <th className="col-2">
              <FloatCategories
                _id={query.categoryId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(categoryId) => {
                  setQuery({ ...query, categoryId });
                }}
              />

              <IntlMessages id="OpenAI.Schedules.categories" />
            </th>
            <th className="col-3">
              <FloatChannels
                _id={query.channelId}
                query={{ everything: false }}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(channelId) => {
                  setQuery({ ...query, channelId });
                }}
              />

              <IntlMessages id="OpenAI.Schedules.channel" />
            </th>
            <th className="col-2">
              <IntlMessages id="OpenAI.Schedules.runtime" />
            </th>
            <th className="col-1">
            <IntlMessages id="OpenAI.Schedules.autoPublish.short" />

            </th>
            {/* <th className="col-1">
              <IntlMessages id="OpenAI.Schedules.telegramPublish" />
            </th> */}
            <th className="col-1">
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {_.map(contentData.data, (content, index) => (
                  <Draggable key={content._id} draggableId={content._id} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        style={{
                          ...(snapshot.isDragging ? { display: "table" } : {}),
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${!content.enabled ? "text-strike" : ""}`}
                      >
                        <td>
                          <Link to={infoLink(content)}>{content.name}</Link>
                        </td>
                        <td>{content.assistant?.name}</td>
                        <td>{content.categories.map((category) => category.name).join(", ")}</td>
                        <td>{content.channels.map((channel) => channel.name).join(", ")}</td>
                        {/* <td>{content.channel?.name}</td> */}
                        <td>
                          {content.runtimes.map((value, index) => {
                            return (
                              <div className="text-nowrap" key={index}>
                                {value.time}
                                {value.telegramPublish ? (
                                  <i className="fab fa-telegram-plane ml-1" />
                                ) : null}
                              </div>
                            );
                          })}
                        </td>

                        <td>{content.autoPublish ? <i className="fa fa-check" /> : null}</td>
                        {/* <td>{content.telegramPublish ? <i className="fa fa-check" /> : null}</td> */}
                        <td className="text-nowrap">
                          <FormButton {...content} />
                          {content.enabled ? (
                            <StartButton
                              {...content}
                              onSuccessHandler={() => setUpdated(Date.now())}
                            />
                          ) : null}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>

        <tfoot>
          <tr>
            <td colSpan="99">
              {/* <Pager query={query} url={url} total={contentData.total} /> */}
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.list),
  savePositions: api.post(apiUrls.positions),
})(ContentTable);

import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { join } from "path";
import { Link, useParams, useRouteMatch, Route, Redirect, Switch } from "react-router-dom";
import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";
import { Nav, NavLink, NavItem } from "reactstrap";
import IntlMessages from "util/intlMessages";
import { Error400 } from "views/pages";
import { adminAPI } from "redux/api";

import Form from "./Form";
// import AssistantThreads from "./Threads";
// import Assistant from "./Assistant";

import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const ScheduleInfo = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const { breadCrumbs } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [content, setContent] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const onSuccess = (body) => {
      setContent(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getContent({ params }, { onSuccess, onFailure });
  }, []);

  if (content) {
    breadCrumbs.push({
      title: [content.name].filter(_.size).join(" / "),
      url,
      actions: [
        <Link to={formLink(content)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  const NavTabs = [
    {
      path: "form",
      title: <IntlMessages id="OpenAI.Schedules.Form" />,
      component: <Form {...{ breadCrumbs, content, setContent }} />,
    },
  ];
  return (
    <>
      {notFound && <Error400 />}
      {content ? (
        <Fragment>
          <PageTitle {...breadCrumbs} />

          <Route path={path}>
            <div className="mb-1">
              <Nav tabs>
                {NavTabs.map((navTab, index) => {
                  const link = join(url, _.toString(navTab.path));
                  return (
                    <Fragment key={index}>
                      <NavItem>
                        <span className={`nav-link ${currentTab === index ? "active" : ""}`}>
                          <Link to={link}>{navTab.title || <IntlMessages id={navTab.id} />}</Link>
                        </span>
                      </NavItem>
                    </Fragment>
                  );
                })}
              </Nav>
            </div>

            <Switch>
              {NavTabs.map((navTab, index) => {
                const link = join(path, _.toString(navTab.path));
                return (
                  <Route
                    key={index}
                    exact
                    path={link}
                    render={() => {
                      setCurrentTab(index);
                      return <Fragment key={index}>{navTab.component}</Fragment>;
                    }}
                  />
                );
              })}

              <Route
                exact
                path={path}
                render={() => <Redirect to={`${join(url, NavTabs[0].path)}`} />}
              />
            </Switch>
          </Route>
        </Fragment>
      ) : null}
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.info),
})(ScheduleInfo);

import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";

import { Alert } from "reactstrap";
import { setOnChange } from "components/special";
import { FloatChannels } from "components/special/contents";

import { Uploader, AudioPreview, Text2Mp3Button } from "components/standart";

const SpecialsMessageData = (props) => {
  const { message, setMessage, error, setError } = props;
  const changeMessage = setOnChange(message, setMessage);

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.Messages.channel" />
        </label>
        <div className="col-8">
          <FloatChannels
            _id={message.channelId}
            query={{ everything: false }}
            multi={false}
            showCount={false}
            showTitle={true}
            className="btn-sm mr-1"
            successHandler={([channelId]) => {
              setMessage({ ...message, channelId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.Messages.title" />
        </label>
        <div className="col">
          <input
            type="text"
            name="title"
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
            value={message.title || ""}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.Messages.text" />
        </label>
        <div className="col">
          <textarea
            rows={5}
            name="text"
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
            value={message.text || ""}
          />
        </div>

        <div className="col-1">
          <Text2Mp3Button
            value={message?.text}
            onSuccess={(file) =>
              setMessage({
                ...message,
                fileId: file._id,
                mp3: file.location,
                duration: file.duration,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.Messages.fileId" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) =>
              setMessage({
                ...message,
                fileId: file._id,
                mp3: file.location,
                duration: file.duration,
              })
            }
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col">
          {message.fileId && (
            <AudioPreview
              file={{ _id: message.fileId }}
              deleteHandler={() => {
                setMessage({ ...message, fileId: null, mp3: null, duration: null });
              }}
            />
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.Messages.ident" />
        </label>
        <div className="col">
          <input
            type="number"
            name="ident"
            onChange={changeMessage}
            className="form-control react-form-input"
            autoComplete="off"
            value={message.ident || ""}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  // checkChannel: api.post(apiUrls.channelsCheck),
})(SpecialsMessageData);

import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";

export const RTMPSDataValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-6">
              <input
                type="string"
                name="serverUrl"
                className="form-control"
                placeholder={intl.formatMessage({ id: "CastsTranslations.serverUrl" })}
                value={elem.serverUrl || ""}
                onChange={({ target }) => {
                  Object.assign(values[index], { [target.name]: target.value });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col-5">
              <input
                type="string"
                name="translationKey"
                className="form-control"
                placeholder={intl.formatMessage({ id: "CastsTranslations.translationKey" })}
                value={elem.translationKey || ""}
                onChange={({ target }) => {
                  Object.assign(values[index], { [target.name]: target.value });
                  changeValues(values);
                }}
              />
            </div>

            <div className="col-1 text-right">
              <RTMPSDataValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

RTMPSDataValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat({}));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

RTMPSDataValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default RTMPSDataValues;

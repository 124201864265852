import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import AdvertiserData from "./Data";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const AddAdvertiser = (props) => {
  const dispatch = useDispatch();

  const [advertiser, setAdvertiser] = useState({});

  const saveHandler = (req, res) => {
    res.onSuccess = (body) => {
      dispatch(push(formLink(body)));
    };
    props.addAdvertiser(req, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setAdvertiser({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus" onClick={toggleModal} />
      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Advertisers.Add"} />
          </ModalHeader>
          <ModalBody>
            <AdvertiserData {...{ advertiser, setAdvertiser, saveHandler }} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addAdvertiser: api.post(apiUrls.list),
})(AddAdvertiser);

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import Select from "react-select";

const CompletionsTypesSelector = (props) => {
  const { completionsTypes } = props.envStore;
  const { name = "type", setValue } = props;
  const intl = useIntl();

  const options = () =>
    _.map(completionsTypes, (value) => {
      return { value, label: intl.formatMessage({ id: `OpenAI.Completions.type.${value}` }) };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    setValue({ [name]: value });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      value={
        props.value
          ? {
              value: props.value,
              label: intl.formatMessage({ id: `OpenAI.Completions.type.${props.value}` }),
            }
          : null
      }
      options={options()}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(CompletionsTypesSelector);

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";

const ContentData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Assistants.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Assistants.instructions" />
        </label>
        <div className="col-8">
          <textarea
            name="instructions"
            rows="10"
            value={content.instructions || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Assistants.temperature" />
        </label>
        <div className="col-8">
          <input
            type="number"
            name="temperature"
            min={0.1}
            step={0.01}
            value={+content.temperature || 1}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="OpenAI.Assistants.top_p" />
        </label>
        <div className="col-8">
          <input
            type="number"
            name="topP"
            min={0.1}
            step={0.01}
            value={+content.topP || 1}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ContentData);

import _ from "lodash";
import React from "react";
import { fileUrl } from "redux/api";

const VideoPreview = (props) => {
  const { file = {} } = props;

  return file._id ? (
    <span
      id={file._id}
      className="btn btn-border"
      style={{ position: "relative" }}
      title={file.name}
    >
      <video src={fileUrl(file._id, "download")} width="320" controls {..._.omit(props, ["deleteHandler"])} />{" "}
      {props.deleteHandler && (
        <button
          className="btn btn-sm btn-danger"
          type="button"
          style={{ position: "absolute", right: "0px", top: "0px" }}
          onClick={props.deleteHandler}
        >
          <i className="fa fa-times"></i>
        </button>
      )}
    </span>
  ) : null;
};

export default VideoPreview;

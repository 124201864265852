import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import qs from "query-string";
import IntlMessages from "util/intlMessages";
import SpecialsMessageData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";
import { LoadingIcon } from "components/special";

const { api } = adminAPI.actions;

const AddMessage = (props) => {
  const params = useParams();

  const [message, setMessage] = useState({});

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  // const changeUser = setOnChange(newUser, setNewUser);

  const saveHandler = (req) => {
    const res = {
      onSuccess(body) {
        props.refreshHandler();
        toggleModal();
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
        setError(null);
      },
      onFailure({ message }) {
        setError(message);
        setLoading(false);
      },
    };
    const body = { ...message };
    props.addMessage({ body, params }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setMessage({});
    setError(null);
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>

      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Specials.Messages.Add"} />
          </ModalHeader>
          <ModalBody>

            <SpecialsMessageData {...{ message, setMessage, error, setError }} />

            <div className="my-2" />
            <button className="btn btn-success" onClick={saveHandler}>
              {loading && <LoadingIcon className="mr-2" />}
              <IntlMessages id={"add"} />
            </button>
            {/* <ChannelMessageData {...{ message, setMessage, saveHandler }} /> */}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addMessage: api.post(apiUrls.messages),
})(AddMessage);

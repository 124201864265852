import _ from "lodash";
import React, { useState } from "react";
import { setOnChange } from "../index";
import IntlMessages from "util/intlMessages";
import { Uploader, ImagePreview } from "components/standart";

const MetaData = (props) => {
  const { saveHandler, data, setData } = props;
  const changeMeta = setOnChange(data, setData);
  // const [metaFile, setMetaFile] = useState(_.get(data, "file_id"));

  const uploadFilesHandler = ([file]) => {
    const { meta = {} } = data;
    meta.fileId = file._id;
    setData({ ...data, meta });
  };

  return (
    <>
      <div className="form-group h4">
        <IntlMessages id="meta" />
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="meta.title" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="meta.title"
            value={_.toString(data.meta?.title)}
            onChange={changeMeta}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="meta.keywords" />
        </label>
        <div className="col-8">
          <textarea
            rows="3"
            name="meta.keywords"
            value={_.toString(data.meta?.keywords)}
            onChange={changeMeta}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="meta.description" />
        </label>
        <div className="col-8">
          <textarea
            rows="3"
            name="meta.description"
            value={_.toString(data.meta?.description)}
            onChange={changeMeta}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="meta.image" />

          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={uploadFilesHandler}
            imagesOnly="true"
            multiple={false}
          />
        </label>
        <div className="col-8">
          {data.meta?.fileId && (
            <ImagePreview
              file={{ _id: data.meta.fileId }}
              deleteHandler={() => {
                const meta = _.omit(data.meta, "fileId");
                setData({ ...data, meta });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MetaData;

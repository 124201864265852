/** управление пользователями
 */
import _ from "lodash";
import React from "react";

import PartnerUsersTable from "./Table";

const PartnerUsers = (props) => {
  return (
    <div>
      <PartnerUsersTable />
    </div>
  );
};

export default PartnerUsers;

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import arrayMove from "array-move";
import { arrayMoveImmutable } from "array-move";

import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { ImagePreview } from "components/standart";

import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const OrderChannels = (props) => {
  const dispatch = useDispatch();

  const [channels, setChannels] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const saveHandler = () => {
    const _id = [];
    _.map(channels, (channel) => _id.push(channel._id));

    const onSuccess = ({ message }) => {
      setSuccess(message);
    };
    const onFailure = ({ message }) => {
      setError(message);
    };

    props.orderChannels({ body: { _id } }, { onSuccess, onFailure });
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setChannels(arrayMoveImmutable(channels, oldIndex, newIndex));
  };

  useEffect(() => {
    if (openModal) {
      props.getChannels(
        { query: { limit: 999999, order: "-index" } },
        {
          onSuccess(body) {
            setChannels(body.data);
          },
        }
      );
    } else {
      setSuccess(null);
      setError(null);
    }
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-info mx-1" onClick={toggleModal}>
        <i className="fa fa-sort-alt" />
      </button>
      {openModal && (
        <Modal isOpen={openModal} centered size="xl" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Channels.Order"} />
          </ModalHeader>
          <ModalBody>
            {success && (
              <Alert className="alert-succes" isOpen="true" toggle={() => setSuccess(false)}>
                <IntlMessages id={success} />
              </Alert>
            )}
            {error && (
              <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                <IntlMessages id={error} />
              </Alert>
            )}

            <SortableList
              items={channels}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass="sortableHelper"
            />

            <div className="form-group">
              <button type="button" className="btn c-primary" onClick={saveHandler}>
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="row">
      {items.map((value, index) => (
        <SortableItem key={`item-${value._id}`} index={index} value={value} />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(({ value }) => (
  <div className="col-2 p-3 text-center">
    <div>
      <ImagePreview file={{ _id: value.fileId }} />
    </div>
    <div className="my-3">{value.name}</div>
  </div>
));

export default connect(null, {
  getChannels: api.get(apiUrls.list),
  orderChannels: api.post(apiUrls.setOrder),
})(OrderChannels);

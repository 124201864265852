import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { colors, AttrDataSet, AttrDataStorage, AttrDataKeys } from "./init";

const moment = extendMoment(Moment);

const AttrsValuesContainer = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const [values, setValues] = useState(props.values || []);
  const [attrs, setAttrs] = useState(props.attrs || []);
  const [query, setQuery] = useState({ ...props.query });
  // тут следует ввести возможность параметризации группировки по дате для формирования
  // data в datasets, в которых я опираюсь на список интервальных ключей
  // в этом смысле для меня большое значение играет, есть ли у меня значение id в списке attrs
  // и есть ли группировка по дате, которая создаст заполняемый интервал
  // при этом в зависимости от наличия id в атрибуте будет совершенно по разному извлекаться
  // значение из value в процедуре autoFill
  // таким образом формируем следующий ограничительный параметрический блок
  // в первую очередь введем ограничение на ренжирование и упор на дату
  // то есть в общем случае хранилищем будет null значение

  const [datasets, setDatasets] = useState([]);
  const [plainData, setPlainData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (props.queryHandler) {
      dispatch(
        props.queryHandler(
          { query },
          {
            onSuccess(body) {
              // setUpdated(Date.now());
              const _values = body[props.queryRelations?.values || "values"];
              const _attrs = body[props.queryRelations?.attrs || "attrs"];
              // console.log("on query success", _values, _attrs, body);
              setValues(_values);
              setAttrs(_attrs);
            },
            onRequest() {
              setDatasets([]);
              setLabels([]);
            },
          }
        )
      );
    }
  }, [query]);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  useEffect(() => {
    setAttrs(props.attrs);
  }, [props.attrs]);

  useEffect(() => {
    if (props.plainDataAttr) {
      const plainDataSets = [];
      const plainDataValues = [];
      const { attrNameOnly } = props;
      _.map(attrs, (attr, index) => {
        // если указанный аттрибут не совпадает с перебираемым, то выходим из цикла
        if (attrNameOnly && attr.name !== attrNameOnly) return;
        const color = colors[index % colors.length];
        const props = { color };
        plainDataSets.push({ ...attr, ...props, dataKey: attr.name });
      });

      setDatasets(plainDataSets);
      setPlainData(values);
    } else {
      // const { query } = props;
      // const now = props.toDate || new Date();
      const rangeFormat = "YYYY-MM-DD";
      const dateFormat = intl.formatMessage({ id: "dateFormat" });

      const keysStorage = new AttrDataKeys({ name: "baseTime", rangeFormat, dateFormat });

      if (query.baseTime) {
        const minDateTime = moment(query.minDateTime || "0").toDate();
        const maxDateTime = moment(query.maxDateTime || new Date()).toDate();
        // const fromDate = moment(now).subtract(1, query).toDate();
        const range = moment.range(minDateTime, maxDateTime);
        const isYear = query.baseTime === "toStartOfYear";
        if (isYear) {
          keysStorage.rangeFormat = "YYYY-01-01";
        }
        const isMonth = query.baseTime === "toStartOfMonth";
        if (isMonth) {
          keysStorage.rangeFormat = "YYYY-MM-01";
        }
        const isWeek = query.baseTime === "toStartOfWeek";
        const rangeScale = isYear ? "year" : isMonth ? "month" : isWeek ? "week" : "day";

        _.map(Array.from(range.by(rangeScale)), (momentDate) => {
          keysStorage.add(momentDate.toDate());
        });
      } else {
        keysStorage.add(null);
      }

      // ожидается типовой атрибут вида
      // {name, label, value, groupBy}
      // соответственно значениями будут точки на графике, которые являются значениями для этих лейблов в моменте
      // для этого создадим класс, который будет работать с входящими значениями
      const attrDatasets = new AttrDataStorage(keysStorage);
      attrDatasets.setDataAttr(props.dataAttr);
      // /*
      const { attrNameOnly } = props;
      _.map(attrs, (attr, index) => {
        // если указанный аттрибут не совпадает с перебираемым, то выходим из цикла
        if (attrNameOnly && attr.name !== attrNameOnly) return;
        const color = colors[index % colors.length];
        const props = {
          color,
          // borderColor: color,
          // pointBorderColor: color,
          // pointBackgroundColor: color,
          // pointRadius: 3,
          // pointHoverRadius: 6,
          // fill: true,
          // backgroundColor: color + "13",
        };
        const attrDataset = new AttrDataSet({ attr, props });
        attrDatasets.add(attrDataset);
      });

      _.map(values, (value) => {
        attrDatasets.autoFill(value);
      });
      // console.log("attrDatasets after all", attrDatasets.export());
      setLabels(keysStorage.labels);
      setDatasets(attrDatasets.export());
      setPlainData(attrDatasets.plainData());
    }
  }, [attrs, values]);

  // return <b>chart</b>
  // /*
  return <>{props.children({ query, setQuery, plainData, datasets, labels })}</>;
};

export default AttrsValuesContainer;

import apiClass from "./init";

const settings = new apiClass();

settings.actions = {
  getSettings: settings.get("/api/settings", {
    success: (state, payload) => ({ ...payload }),
  }),
  changePhoto: settings.post("/api/settings/photo", {
    success: (state, payload) => ({ ...payload }),
  }),
  deletePhoto: settings.delete("/api/settings/photo", {
    success: (state, payload) => ({ ...payload }),
  }),
};

export default settings;

import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { connect } from "react-redux";
import { Alert } from "reactstrap";

import IntlMessages from "util/intlMessages";
import { StringArrayValues, Text2Mp3Button, AudioPreview, Uploader } from "components/standart";
import { SwitchValue } from "components/special/forms";

import { PageTitle, TTSModelSelector } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;

const SoundsSettings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const [settings, setSettings] = useState({});

  const [test, setTest] = useState({});

  useEffect(() => {
    props.getSettings(
      {},
      {
        onRequest() {
          setSettings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setSettings(body);
        },
      }
    );
  }, []);

  breadCrumbs.push({
    title: <IntlMessages id="Sounds.Settings" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeSettings = setOnChange(settings, setSettings);
  const changeTest = setOnChange(test, setTest);

  // аттрибуты, которые необходимо отобразить
  const inputAttrs = [];
  const soundFileAttrs = ["jingleFileId"];

  const saveSettingsHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.saveSettings(
      {
        body: { ...settings },
      },
      {
        onSuccess(body) {
          setSettings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
        onRequest() {
          setDataSaved(false);
        },
      }
    );
  };

  const uploadFilesHandler =
    (attrName) =>
    ([file]) => {
      setSettings({ ...settings, [attrName]: file._id });
    };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      {settings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                {inputAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Sounds.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <input
                          type="number"
                          name={name}
                          value={+settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                {soundFileAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <div className="col-3 col-form-label">
                        <IntlMessages id={`Sounds.Settings.${name}`} />

                        <div className="pull-right">
                          {!settings[name] ? (
                            <Uploader
                              className="btn-success btn-sm pull-right"
                              successHandler={uploadFilesHandler(name)}
                              accept="*.mp3"
                              multiple={false}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-8 my-auto">
                        {settings[name] ? (
                          <AudioPreview
                            file={{ _id: settings[name] }}
                            deleteHandler={() => {
                              setSettings({ ...settings, [name]: null });
                            }}
                          />
                        ) : (
                          "Нет"
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id={`Sounds.Settings.textToSpeechModel`} />
                  </label>
                  <div className="col-8">
                    <TTSModelSelector
                      name="textToSpeechModel"
                      value={settings.textToSpeechModel}
                      setValue={(data) => setSettings({ ...settings, ...data })}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id={`Sounds.Settings.ttsHD`} />
                  </label>
                  <div className="col my-auto">
                    <SwitchValue value={settings.ttsHD} name="ttsHD" onChange={changeSettings} />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id={`Sounds.Settings.textSpeed`} />
                  </label>
                  <div className="col-8">
                    <input
                      type="number"
                      name={"textSpeed"}
                      value={+settings.textSpeed || ""}
                      onChange={changeSettings}
                      className="form-control react-form-input"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <span className="col-4 col-form-label">
                    <StringArrayValues.AddButton
                      className="btn-sm float-right"
                      values={settings.textInstructions}
                      changeValues={(textInstructions) =>
                        setSettings({ ...settings, textInstructions })
                      }
                    />
                    <IntlMessages id={`Sounds.Settings.textInstructions`} />
                  </span>
                  <div className="col-8">
                    <StringArrayValues
                      values={settings.textInstructions}
                      changeValues={(textInstructions) =>
                        setSettings({ ...settings, textInstructions })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row" style={{ height: 150 }}>
                  <label className="col-4 col-form-label">
                    <IntlMessages id={`Sounds.Settings.test`} />
                  </label>
                  <div className="col">
                    <textarea
                      rows={5}
                      name="text"
                      className="form-control react-form-input"
                      onChange={changeTest}
                    >
                      {test.text || ""}
                    </textarea>
                    {test.fileId ? (
                      <AudioPreview
                        file={{ _id: test.fileId }}
                        deleteHandler={() => {
                          setTest({ ...test, fileId: null });
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="col-1">
                    <Text2Mp3Button
                      value={test.text}
                      onSuccess={(file) => setTest({ ...test, fileId: file._id })}
                    />
                  </div>
                </div>

                {/* 

                     */}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

export default connect(null, {
  getSettings: api.get(apiUrls.settings),
  saveSettings: api.patch(apiUrls.settings),
})(SoundsSettings);

import _ from "lodash";
import React from "react";
import IntlMessages from "util/intlMessages";

const Assistant = (assistant) => {
  return (
    <div className="my-2">
      <div className="row">
        <div className="col-4">
          <IntlMessages id="OpenAI.Assistants.name" />
        </div>
        <div className="col">{assistant.name}</div>
      </div>

      <div className="row">
        <div className="col-4">
          <IntlMessages id="OpenAI.Assistants.openAIId" />
        </div>
        <div className="col">{assistant.openAIId}</div>
      </div>
    </div>
  );
};

export default Assistant;

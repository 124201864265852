import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { push } from "react-router-redux";

import { adminAPI } from "redux/api";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const AddSpecialsCompilation = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const saveHandler = () => {
    const confirmed = window.confirm("Создать подборку сообщений из последних выпусков?");

    if (confirmed) {
      const onSuccess = (body) => {
        setLoading(false);
        dispatch(push(formLink(body)));
      };
      const onRequest = () => {
        setLoading(true);
      };
      const onFailure = () => {
        setLoading(false);
      };
      props.addSpecialCompliation({}, { onSuccess, onRequest, onFailure });
    }
  };

  return (
    <>
      <button className="btn btn-sm -btn-secondary" onClick={saveHandler}>
        <i className={`fa ${loading ? "fa-spinner fa-spin" : "fa-layer-plus"}`} />
      </button>
    </>
  );
};

export default connect(null, {
  addSpecialCompliation: api.post(apiUrls.compilation),
})(AddSpecialsCompilation);

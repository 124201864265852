/** управление пользователями
 * в самом начале получает список пользователей на сервере
 * строит таблицу и позволяет открывать карточки пользователей
 * для просмотра и редактирования
 * в общем смысле возвращаемая структура данных также должна сообщить
 * о том, какие действия допустимы на странице
 * то есть текущему пользователю, выполняющему запрос, может быть недоступно
 * какое-либо из действий, что должно отразиться на интерфейсе
 * это будет добавлено чуть позже в виде структур access со связанными идентификаторами
 * модулей на бекенде
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import moment from "moment";
import { useIntl } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { statsAPI } from "redux/api";
import { history } from "redux/store";

import { Finder, Pager, Sorter, TableLoading } from "components/standart";
import { limit } from "variables";
import { apiUrls } from "./init";
import { infoLink as usersLink } from "../Users/init";

const { api } = statsAPI.actions;

const DataTable = (props) => {
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const [listeners, setListeners] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-lastDate", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setListeners(body);
    };
    const onRequest = () => {
      setListeners({ total: -1 });
    };

    query.limit && props.getData({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Listeners.firstDate" />
            <Sorter query={query} url={url} order="firstDate" />
          </th>
          <th>
            <IntlMessages id="Listeners.lastDate" />
            <Sorter query={query} url={url} order="lastDate" />
          </th>
          <th>
            <IntlMessages id="Listeners.sessionId" />
          </th>
          <th>
            <IntlMessages id="Listeners.dataCount" />
            <Sorter query={query} url={url} order="dataCount" />
          </th>
          <th>
            <IntlMessages id="Listeners.activityDaysCount" />
            <Sorter query={query} url={url} order="activityDaysCount" />
          </th>
          <th>
            <IntlMessages id="Listeners.progressListening" />
            <Sorter query={query} url={url} order="progressListening" />
          </th>
          <th>
            <IntlMessages id="Listeners.progressTime" />
            <Sorter query={query} url={url} order="progressTime" />
          </th>
          <th>
            <Finder.search {...{ query, url }} />
          </th>
        </tr>
      </thead>
      {listeners.total < 0 && <TableLoading />}
      <tbody>
        {_.map(listeners.data, (data, key) => {
          const hours = _.floor(data.progressTime / 3600);
          const minutes = _.floor((data.progressTime - hours * 3600) / 60);
          const seconds = data.progressTime - hours * 3600 - minutes * 60;
          return (
            <tr key={key}>
              <td>{moment(data.firstDate).format(dateTimeFormat)}</td>
              <td>{moment(data.lastDate).format(dateTimeFormat)}</td>
              <td>
                {data.sessionId}
                {data.userId ? (
                  <Link to={usersLink({ _id: data.userId })}>
                    <i className="fa fa-user mx-2" />
                  </Link>
                ) : null}
              </td>
              <td>{data.dataCount}</td>
              <td>{data.activityDaysCount}</td>
              <td>{data.progressListening}</td>
              <td>{`${hours}:${minutes < 10 ? `${0}${minutes}` : minutes}:${
                seconds < 10 ? `${0}${seconds}` : seconds
              }`}</td>

              <td></td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={listeners.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getData: api.get(apiUrls.list),
})(DataTable);

import _ from "lodash";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { FloatSpecialsCategories } from "components/special/contents";

import { Uploader, ImagePreview, AudioPreview, Text2Mp3Button } from "components/standart";
import { apiUrls } from "./init";

const { api } = adminAPI.actions;

const SpecialsData = (props) => {
  const { specials, setSpecials } = props;

  const [isTrailerRefresh, setTrailerRefresh] = useState(false);
  const refreshTrailerHandler = useCallback(() => {
    const confirm = window.confirm("Составить трейлер из заголовков?");
    if (confirm) {
      const params = { specialId: specials._id };
      props.getTrailer(
        { params },
        {
          onRequest() {
            setTrailerRefresh(true);
          },
          onFailure() {
            setTrailerRefresh(false);
          },
          onSuccess({ text }) {
            setTrailerRefresh(false);
            setSpecials({ ...specials, text });
          },
        }
      );
    }
  }, [specials]);

  const changeSpecials =
    specials.status === "draft" ? setOnChange(specials, setSpecials) : new Function();

  // const changeSpecials = setOnChange(specials, setSpecials);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.category" />
        </label>
        <div className="col">
          <FloatSpecialsCategories
            _id={specials.categoryId}
            query={{ everything: false }}
            showTitle={true}
            showCount={false}
            className="btn-sm"
            multi={false}
            successHandler={([categoryId]) => {
              setSpecials({ ...specials, categoryId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.name" />
        </label>
        <div className="col">
          <input
            type="text"
            name="name"
            value={specials.name || ""}
            onChange={changeSpecials}
            className="form-control react-form-input"
            autoComplete="off"
            // onFocus={() => setValidChannel(false)}
            // onBlur={checkValidChannel}
          />
        </div>

        <div className="col-1">
          <Text2Mp3Button
            value={specials?.name}
            onSuccess={(file) => setSpecials({ ...specials, soundNameFileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.soundNameFileId" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setSpecials({ ...specials, soundNameFileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col">
          {specials.soundNameFileId && (
            <AudioPreview
              file={{ _id: specials.soundNameFileId }}
              deleteHandler={() => {
                setSpecials({ ...specials, soundNameFileId: null });
              }}
            />
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.fileId" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setSpecials({ ...specials, fileId: file._id })}
            imagesOnly="true"
            multiple={false}
          />
        </label>
        <div className="col-8">
          {specials.fileId ? (
            <ImagePreview
              file={{ _id: specials.fileId }}
              deleteHandler={() => {
                setSpecials({ ...specials, fileId: null });
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4 col-form-label">
          <IntlMessages id="Specials.text" />
          <button className="btn btn-warning btn-sm float-right" onClick={refreshTrailerHandler}>
            <i className={`fa fa-sync ${isTrailerRefresh ? "fa-spin" : ""}`} />
          </button>
        </div>
        <div className="col">
          <textarea
            type="textarea"
            rows={5}
            name="text"
            onChange={changeSpecials}
            className="form-control react-form-input"
            autoComplete="off"
            value={specials.text || ""}
          />
        </div>

        <div className="col-1">
          <Text2Mp3Button
            value={[specials.name, specials.text].join("...")}
            onSuccess={(file) => setSpecials({ ...specials, soundTextFileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.soundTextFileId" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setSpecials({ ...specials, soundTextFileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col">
          {specials.soundTextFileId && (
            <AudioPreview
              file={{ _id: specials.soundTextFileId }}
              deleteHandler={() => {
                setSpecials({ ...specials, soundTextFileId: null });
              }}
            />
          )}
        </div>
      </div>

      {/* 
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Specials.status" />
        </label>
        <div className="col-8 my-auto">
          <SpecialsStatusesSelector
            value={specials.status}
            setValue={(data) => setSpecials({ ...specials, ...data })}
          />
        </div>
      </div>
      */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  // getImage: api.get(apiUrls.getImage),
  getTrailer: api.get(apiUrls.getTrailer),
})(SpecialsData);

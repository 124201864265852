/** список логинов */
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, AudioPreview, Pager, TableLoading } from "components/standart";
import { FloatCategories } from "components/special/contents";
import { useIntl } from "react-intl";

import { limit } from "variables";

import { apiUrls } from "../../init";
import AddChannel from "./Add";
import CompanyForm from "./Form";
import AdvertCompanyStats from "./Stats";
const { api } = adminAPI.actions;

const CompaniesTable = (props) => {
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const params = useParams();
  const dispatch = useDispatch();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const [companies, setCompanies] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setCompanies(body);
    };
    const onRequest = () => {
      setCompanies({ total: -1 });
    };

    query.limit && props.getCompanies({ params, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  const showCompaniesStats = (company) => () => {
    //
    history.push(`${url}?companyId=${company._id}`);
  };

  return (
    <Fragment>
      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr>
            <th>
              <IntlMessages id="date" />
            </th>
            <th>
              <IntlMessages id="Companies.name" />
            </th>
            <th className="text-nowrap">
              <FloatCategories
                _id={query.categoryId}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(categoryId) => {
                  setQuery({ ...query, categoryId });
                }}
              />
              <IntlMessages id="Companies.category" />
            </th>
            <th>
              <IntlMessages id="Companies.text" />
            </th>
            <th>
              <IntlMessages id="Companies.mp3" />
            </th>
            <th>
              <IntlMessages id="Companies.statsCount" />
            </th>
            <th>
              <IntlMessages id="Companies.coverage" />
            </th>
            <th>
              <IntlMessages id="Companies.totalLimit" />
            </th>
            <th>
              <IntlMessages id="Companies.dayLimit" />
            </th>
            <th>
              <IntlMessages id="Companies.remainsLimit" />
            </th>
            <th>
              <IntlMessages id="Companies.totalCost" />
            </th>
            <th>
              <IntlMessages id="Companies.income" />
            </th>
            <th>
              <IntlMessages id="Companies.status" />
            </th>
            <th className="text-right text-nowrap">
              <Finder.search {...{ query, url }} className="mr-1" />
              <AddChannel refreshHandler={refreshHandler} />
            </th>
          </tr>
        </thead>
        {companies.total < 0 && <TableLoading />}
        <tbody>
          {_.map(companies.data, (company, key) => {
            return (
              <tr key={key} className={!company.enabled ? "text-strike" : ""}>
                <td onClick={showCompaniesStats(company)}>
                  {moment(company.startDate).format(dateTimeFormat)}
                </td>
                <td onClick={showCompaniesStats(company)}>{company.advert?.name}</td>
                <td onClick={showCompaniesStats(company)}>{company.category?.name}</td>

                <td onClick={showCompaniesStats(company)}>
                  <div className="text-truncate" style={{ width: "200px" }}>
                    {company.advert?.content}
                  </div>
                </td>
                <td>
                  <AudioPreview file={{ _id: company.advert?.fileId }} />
                </td>

                <td onClick={showCompaniesStats(company)}>{company.listeningStats?.statsCount}</td>
                <td onClick={showCompaniesStats(company)}>
                  {company.listeningStats?.sessionsCount}
                </td>
                <td onClick={showCompaniesStats(company)}>{company.totalLimit}</td>
                <td onClick={showCompaniesStats(company)}>{company.dayLimit}</td>
                <td onClick={showCompaniesStats(company)}>{company.remainsTotalLimit}</td>
                <td onClick={showCompaniesStats(company)}>
                  {company.totalLimit * +_.get(company.prices, "0.value")}
                </td>
                <td onClick={showCompaniesStats(company)}>{company.listeningStats?.currencySum}</td>
                {/* <td>{_.map([company.category], (category) => category.name).join(", ")}</td> */}
                <td onClick={showCompaniesStats(company)}>
                  <IntlMessages id={`Companies.status.${company.status}`} />
                </td>
                <td className="text-right text-nowrap">
                  <CompanyForm {...{ company, refreshHandler }} />
                  {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={companies.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
      {query.companyId ? (
        <div>
          <AdvertCompanyStats query={{ advertCompanyId: query.companyId }} />
        </div>
      ) : null}
    </Fragment>
  );
};

export default connect(null, {
  getCompanies: api.get(apiUrls.companies),
})(CompaniesTable);

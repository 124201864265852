export const path = "/contents";

export const apiUrls = {
  countries: "/api/references/countries",
  countriesInfo: "/api/references/countries/country_{countryId}",
  contriesPositions: "/api/references/countries/positions",
  locales: "/api/references/locales",
  localesInfo: "/api/references/locales/locale_{localeId}",
  localesPositions: "/api/references/locales/positions",
  categories: "/api/references/categories",
  categoriesInfo: "/api/references/categories/category_{categoryId}",
  categoriesPositions: "/api/references/categories/positions",
  specials_categories: "/api/references/specials_categories",
  specials_categoriesInfo: "/api/references/specials_categories/category_{categoryId}",
  specials_categoriesPositions: "/api/references/specials_categories/positions",
  premiums: "/api/references/premiums",
  premiumsInfo: "/api/references/premiums/premium_{premiumId}",
  premiumsPositions: "/api/references/premiums/positions",
  intros: "/api/references/intros",
  introsInfo: "/api/references/intros/intro_{introId}",
  replacements: "/api/references/replacements",
  replacementsInfo: "/api/references/replacements/replacement_{replacementId}",
  stopwords: "/api/references/stopwords",
  stopwordsInfo: "/api/references/stopwords/word_{wordId}",
  filterwords: "/api/references/filterwords",
  filterwordsInfo: "/api/references/filterwords/word_{wordId}",
  imageStyles: "/api/references/styles",
  imageStylesInfo: "/api/references/styles/style_{styleId}",
  publishChannels: "/api/references/publishChannels",
  publishChannelsPositions: "/api/references/publishChannels/positions",
  publishChannelsInfo: "/api/references/publishChannels/channel_{channelId}",
};
